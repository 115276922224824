import React, { useCallback, useEffect, useRef, useState } from 'react';
import { focusManager } from '@accedo/vdkweb-navigation';
import { FocusButton, FocusDiv } from '@accedo/vdkweb-tv-ui';
import './privacy-policy.component.scss';
import BackArrow from '../../assets/images/back-arrow.svg';
import { getPageBackId } from '../../redux/selector/xdk.store';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getTvLocation, translate } from '../../utils/translate.util';

const subscriberAgreementTabNav = {
  id: 'subscriber-agreement-tab',
  nextdown: 'privacy-policy-tab',
};
const privacyPolicyTabNav = {
  id: 'privacy-policy-tab',
  nextup: 'subscriber-agreement-tab',
  nextdown: 'your-privacy-choices-tab',
  //nextright: 'content-pane', //uncomment once the full privacy policy can be fetched
};
const yourPrivacyChoicesTabNav = {
  id: 'your-privacy-choices-tab',
  nextup: 'privacy-policy-tab',
  nextdown: 'back-button',
};
const backButtonNav = {
  id: 'back-button',
  nextup: 'your-privacy-choices-tab',
};
const contentPaneNav = {
  id: 'content-pane',
  nextleft: 'privacy-policy-tab',
};
const tabTheme = {
  button: 'tab',
  buttonFocused: 'tab-focused',
  buttonActive: 'tab-active',
};
const backButtonTheme = {
  button: 'back-button',
  buttonFocused: 'back-button-focused',
  buttonActive: 'back-button-active',
};

export const PrivacyPolicyScreen = ({ kochava }) => {
  const [contentPaneData, setContentPaneData] = useState({
    title: '',
    body: '',
    style: { overflowY: 'scroll' as any },
  });
  const [contentPaneIsFocused, setContentPaneIsFocused] = useState(false);

  useEffect(() => {
    focusManager.changeFocus(subscriberAgreementTabNav.id);
  }, []);

  const contentPaneRef = useRef(null);
  const scrollContent = useCallback(event => {
    if (event.keyCode === 38) {
      contentPaneRef.current.scrollBy(0, -100);
    } else if (event.keyCode === 40) {
      contentPaneRef.current.scrollBy(0, 100);
    }
  }, []);

  useEffect(() => {
    return () => window.removeEventListener('keydown', scrollContent, true);
  }, []);

  useEffect(() => {
    contentPaneRef.current.scrollTo(0, 0);
  }, [contentPaneData.title]);

  /* Handles the back button press logic - START */
  const isMounted = useRef(false);
  const history = useHistory();
  const backId = useSelector(getPageBackId);
  useEffect(() => {
    if (isMounted.current) {
      if (contentPaneIsFocused) {
        focusManager.changeFocus(privacyPolicyTabNav.id);
      } else {
        history.goBack();
      }
    } else {
      isMounted.current = true;
    }
  }, [backId]);
  /* Handles the back button press logic - END */

  const onClickAnalytics = text => {
    kochava.sendEvent('Click', {
      name: text,
    });
  };

  return (
    <div className="privacy-policy-screen-container">
      <div className="privacy-policy-screen-title">
        {translate('policyAgreements.title')}
      </div>
      <div className="privacy-policy-screen-content-container">
        <div className="privacy-policy-screen-tabs">
          <FocusButton
            theme={tabTheme}
            nav={subscriberAgreementTabNav}
            onFocus={() => {
              onClickAnalytics('Customer Agreement Button');
              setContentPaneData({
                title: `${translate('policyAgreements.customerTitle')}`,
                body: `${translate('policyAgreements.customerLink')}`,
                style: { overflowY: 'hidden' as any },
              });
            }}
          >
            {translate('policyAgreements.customerTitle')}
          </FocusButton>
          <FocusButton
            theme={tabTheme}
            nav={
              getTvLocation() === 'CA'
                ? { ...privacyPolicyTabNav, nextdown: 'back-button' }
                : privacyPolicyTabNav
            }
            onClick={() => {
              //focusManager.changeFocus(contentPaneNav.id); //Uncomment once full privacy policy can be fetched again
            }}
            onFocus={() => {
              onClickAnalytics('Privacy Policy Button');
              setContentPaneData({
                title: `${translate('policyAgreements.privacyTitle')}`,
                body: `${translate('policyAgreements.privacylink')}`,
                style: { overflowY: 'hidden' as any }, //change to overflowY: 'scroll' when full privacy policy can be fetched
              });
            }}
          >
            {translate('policyAgreements.privacyTitle')}
          </FocusButton>
          {getTvLocation() !== 'CA' ? (
            <FocusButton
              theme={tabTheme}
              nav={yourPrivacyChoicesTabNav}
              onFocus={() => {
                onClickAnalytics('Your Privacy Choices Button');
                setContentPaneData({
                  title: `${translate('policyAgreements.privacyChoiceTitle')}`,
                  body: `${translate('policyAgreements.privacyChoiceLink')}`,
                  style: { overflowY: 'hidden' as any },
                });
              }}
            >
              {translate('policyAgreements.privacyChoiceTitle')}
            </FocusButton>
          ) : (
            ''
          )}
          <FocusButton
            theme={backButtonTheme}
            nav={
              getTvLocation() === 'CA'
                ? { ...backButtonNav, nextup: 'privacy-policy-tab' }
                : backButtonNav
            }
            onClick={() => {
              onClickAnalytics('Back Button');
              history.goBack();
            }}
          >
            <img src={BackArrow} />
            <span>{translate('misc.back')}</span>
          </FocusButton>
        </div>
        <div
          className={`privacy-policy-screen-content ${
            contentPaneIsFocused ? 'focused' : ''
          }`}
          style={contentPaneData.style}
          ref={contentPaneRef}
        >
          <FocusDiv
            nav={contentPaneNav}
            className="content-pane"
            onFocus={() => {
              setContentPaneIsFocused(true);
              window.addEventListener('keydown', scrollContent);
            }}
            onBlur={() => {
              setContentPaneIsFocused(false);
              window.removeEventListener('keydown', scrollContent);
            }}
          >
            <div className="content-pane-title-text">
              {contentPaneData.title}
            </div>
            <div className="content-pane-body">{contentPaneData.body}</div>
          </FocusDiv>
        </div>
      </div>
    </div>
  );
};
