import React, { useEffect, useState, useRef } from 'react';
import { interval } from 'rxjs';
import { concatMap, catchError, takeWhile, skipWhile } from 'rxjs/operators';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { FocusButton, Page } from '@accedo/vdkweb-tv-ui';
import { focusManager, navigationService } from '@accedo/vdkweb-navigation';
import { getPageBackId } from '../../redux/selector/xdk.store';
import { FakeTextInput } from '../../components/fake-text-input';
import { Keyboard } from '../../components/keyboard';
import { appRouteConstants } from '../../routing/app.route.constants';
import './login-page.component.scss';
import {
  ServiceFactory,
  AuthenticationService,
  ResumeService,
  IAuthenticationResponse,
  ApiCodes,
  StorageService,
  StorageKeyConstant,
  ApiDelegate,
} from '../../servicelib';
import language from '../../assets/i18n/en-ca.json';
import { translate } from '../../utils/translate.util';
import { terminateFreeTrial } from '../../utils/free-trial-expiration.util';
import {
  showPodcastsMenuOption,
  showVideosMenuOption,
} from '../../redux/action/xdk.action';
import filter from 'lodash/filter';
import { ReactComponent as Lucide } from '../../assets/images/lucide.svg';
import { ReactComponent as Eye } from '../../assets/images/eye-icon.svg';
import { ReactComponent as HideEye } from '../../assets/images/eye-hide-icon.svg';
import BlueButtonComponent from '../../components/blue-button/blue-button-component';

const pageNav = { id: 'welcome-page' };
const usernameNav = { id: 'username-input', nextdown: 'username-continue' };
const usernameContinueNav = {
  id: 'username-continue',
  nextup: 'username-input',
};
const passwordEyeNav = {
  id: 'password-eye',
  nextleft: 'password-input',
  nextdown: 'sign-in-button',
};
const passwordNav = {
  id: 'password-input',
  nextdown: 'sign-in-button',
  nextright: 'password-eye',
};
const signInButtonNav = {
  id: 'sign-in-button',
  skip: false,
  nextup: 'password-input',
};

const privacyPolicyButtonTheme = {
  button: 'general-privacy-policy-button',
  buttonFocused: 'general-privacy-policy-button-focused',
  buttonActive: 'general-privacy-policy-button-active',
};
const emailText = ['@gmail', '@hotmail', '@yahoo'];
const softKeyboardNav = { id: 'soft-keyboard' };

const getErrorMessageByCode = code => {
  let errorMessage = '';
  const errorMessageStyle: any = { textAlign: 'left' };

  //TODO: Fetch text and translated text from props or service
  switch (code) {
    case ApiCodes.ACCOUNT_LOCKED:
    case ApiCodes.SR_REQ_FORBIDDEN:
      errorMessageStyle.paddingTop = '0.75rem';
      errorMessage = language.login.errors.modals.errForbidden.description;
      break;
    case ApiCodes.OAC_PASSWORD:
      errorMessage = language.login.errors.modals.oacLoginAttempt.description;
      break;
    case ApiCodes.EXPIRED_SUBSCRIPTION:
      errorMessage =
        language.login.errors.modals.expiredSubscription.description;
      break;
    case ApiCodes.INVALID_CREDENTIALS:
    default:
      errorMessage = translate('signInPage.forgotText');
  }

  return errorMessage;
};

const getErrorMessage = code => {
  const errorMessageStyle: any = { textAlign: 'left' };
  let errorMessage = '';
  errorMessage = getErrorMessageByCode(code);
  if (code === ApiCodes.SR_REQ_FORBIDDEN) {
    errorMessageStyle.paddingTop = '0.75rem';
  }
  return (
    <>
      <span className="error-circle">
        <Lucide />
      </span>
      <span style={errorMessageStyle}>{errorMessage}</span>
    </>
  );
};

const demo = () => <></>;

//Needed to keep the latest value for isKeyboardOpen inside the observable's skipWhile closure
const shouldSkipPolling = {
  isKeyboardOpen: false,
};

const useLoginComponent = () => {
  const [tab, setTab] = useState(0);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isKeyboardOpen, openKeyboard] = useState(false);
  const [inputToEdit, setInputToEdit] = useState('');
  const [hasLoginFailed, setHasLoginFailed] = useState(false);
  const [hasErrorMessage, setHasErrorMessage] = useState(false);
  const [authMessage, setAuthMessage] = useState(demo);
  const [isLoginInProgress, setIsLoginInProgress] = useState(false);
  const [registrationCode, setRegistrationCode] = useState('');
  const [pollFrequency, setPollFrequency] = useState(20);
  const [codeExpirationTime, setCodeExpirationTime] = useState(moment());
  const [regCodeCounter, setRegCodeCounter] = useState(1);
  const [showPassword, setShowPassword] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const areCredentialsEntered = username && password && password.length >= 6;
  const isUsernameEntered = username;
  const authenticationService = ServiceFactory.getInstance(
    AuthenticationService,
  ) as AuthenticationService;
  const storageService = ServiceFactory.getInstance(
    StorageService,
  ) as StorageService;
  shouldSkipPolling.isKeyboardOpen = isKeyboardOpen;

  useEffect(() => {
    const subscription = authenticationService.createAlternateLogin().subscribe(
      ({ authenticationData }) => {
        const {
          registrationCode,
          pollFrequency,
          regExpiration,
        } = authenticationData;
        setPollFrequency(pollFrequency);
        setCodeExpirationTime(moment(regExpiration));
        setRegistrationCode(registrationCode);
      },
      error => {
        console.error(error);
      },
    );

    return () => subscription.unsubscribe();
  }, [regCodeCounter]);

  useEffect(() => {
    if (registrationCode) {
      const freeTrialStatus = { isExpired: undefined };
      const apiDelegate = ServiceFactory.getInstance(
        ApiDelegate,
      ) as ApiDelegate;
      const apiDelegateSubscription = apiDelegate.addApiCodeHandler(
        ApiCodes.FREE_TIER_EXPIRED,
        (codes, apiMessages, response) => {
          freeTrialStatus.isExpired = true;
        },
      );
      const subscription = interval(pollFrequency * 1000)
        .pipe(
          skipWhile(() => {
            return shouldSkipPolling.isKeyboardOpen;
          }),
          takeWhile(() => {
            if (codeExpirationTime.diff(moment()) <= 0) {
              setRegCodeCounter(counter => counter + 1);
            }

            return codeExpirationTime.diff(moment()) > 0;
          }),
          concatMap(() =>
            authenticationService.completeAlternateLogin(registrationCode),
          ),
          catchError((err, caught) => caught),
          concatMap(() => {
            const resumeService = ServiceFactory.getInstance(
              ResumeService,
            ) as ResumeService;
            return resumeService.resume();
          }),
        )
        .subscribe(() => {
          if (freeTrialStatus && freeTrialStatus.isExpired) {
            authenticationService.logout().subscribe({
              next: () => {
                dispatch(showPodcastsMenuOption(false));
                dispatch(showVideosMenuOption(false));
                terminateFreeTrial();
                history.replace(appRouteConstants.AUTH.OPEN_ACCESS_EXPIRED);
              },
            });
          } else {
            history.replace(appRouteConstants.AUTH.LOGIN_CONFIRMATION);
          }
        });

      return () => {
        apiDelegateSubscription.removeCodeHandler();
        subscription.unsubscribe();
      };
    }
  }, [registrationCode]);

  useEffect(() => {
    focusManager.changeFocus(usernameNav.id);

    return navigationService.listenToFocusEvent({
      id: signInButtonNav.id,
      fn: data => {
        if (data.currentFocus === signInButtonNav.id) {
          //Makes caret disappear from the password field, as it currently gives the illusion of both
          //the password input and Sign In button to be focused at the same timne.
          (document.activeElement as any).blur();
        }
      },
    });
  }, []);

  const onInputClick = inputId => {
    focusManager.changeFocus(softKeyboardNav.id);
    setInputToEdit(inputId);
    openKeyboard(true);
  };

  const handleSignIn = () => {
    if (!isLoginInProgress) {
      setIsLoginInProgress(true);
      (document.activeElement as any).blur();

      authenticationService.login(username, password).subscribe(
        (response: IAuthenticationResponse) => {
          const { globalSettingList, clientConfiguration } = response;
          const { freeTier: isFreeTier } = clientConfiguration;
          const previewEndTimeSetting = filter(globalSettingList, {
            settingName: 'iapPreviewEndTime',
          })[0];
          const previewCooldownTimeSetting = filter(globalSettingList, {
            settingName: 'iapCooldownTime',
          })[0];
          let proceedToSuccessScreen = true;
          //Free Trial accounts will have the freeTier flag as false, but the iapPreviewEndTime property will exist.
          //So in this case, these type of accounts will need to be granted access, regardless of the iapPreviewEndTime value
          //On the other hand, Free Tier accounts will have both properties and time restrictions must be enforced
          if (previewEndTimeSetting && isFreeTier) {
            const previewEndTime = moment(previewEndTimeSetting.settingValue);
            const previewCooldownTime = moment(
              previewCooldownTimeSetting.settingValue,
            );
            const now = moment();
            const timeRemainingForExpiration = previewEndTime
              .diff(now)
              .valueOf();
            const timeRemainingForReset = previewCooldownTime
              .diff(now)
              .valueOf();
            if (timeRemainingForExpiration <= 0 && timeRemainingForReset <= 0) {
              //Free tier account hasn't been reset for a while, so we proceed.
              proceedToSuccessScreen = true;
            } else {
              proceedToSuccessScreen = timeRemainingForExpiration > 0;
            }
          }
          if (proceedToSuccessScreen) {
            setHasLoginFailed(false);
            setIsLoginInProgress(false);
            storageService.setItem(StorageKeyConstant.USERNAME, username);
            history.replace(appRouteConstants.AUTH.LOGIN_CONFIRMATION);
          } else {
            authenticationService.logout().subscribe({
              next: () => {
                dispatch(showPodcastsMenuOption(false));
                dispatch(showVideosMenuOption(false));
                terminateFreeTrial();
                history.replace(appRouteConstants.AUTH.OPEN_ACCESS_EXPIRED);
              },
            });
          }
        },
        error => {
          setHasLoginFailed(true);
          setHasErrorMessage(true);
          setAuthMessage(getErrorMessage(error.code));
          setIsLoginInProgress(false);
          setTab(0);
          focusManager.changeFocus(usernameNav.id);
        },
      );
    }
  };

  const handleUsername = () => {
    if (!!isUsernameEntered) {
      setTab(1);
      focusManager.changeFocus(passwordNav.id);
    }
  };

  const handleEye = () => {
    setShowPassword(!showPassword);
  };

  const onVirtualKeyClick = text => {
    const setText = inputToEdit === usernameNav.id ? setUsername : setPassword;
    let inputText = inputToEdit === usernameNav.id ? username : password;
    const ignoreKeys = ['layout1', 'layout2', 'uppercase'];

    if (ignoreKeys.includes(text)) {
      return;
    } else if (text === 'Cancel') {
      if (inputToEdit === usernameNav.id) {
        openKeyboard(false);
        if (inputText !== '') {
          focusManager.changeFocus(usernameContinueNav.id);
        } else {
          focusManager.changeFocus(usernameNav.id);
        }
      } else {
        if (inputText !== '' && username !== '') {
          if (areCredentialsEntered) {
            focusManager.changeFocus(signInButtonNav.id);
          } else {
            focusManager.changeFocus(passwordNav.id);
          }
          openKeyboard(false);
          setInputToEdit('');
        } else {
          focusManager.changeFocus(inputToEdit);
          openKeyboard(false);
          setInputToEdit('');
        }
      }
      return;
    } else if (text === 'OK') {
      if (inputToEdit === usernameNav.id) {
        openKeyboard(false);
        if (inputText !== '') {
          setTab(1);
          focusManager.changeFocus(passwordNav.id);
        } else {
          focusManager.changeFocus(usernameNav.id);
        }
      } else {
        if (inputText !== '' && username !== '') {
          if (areCredentialsEntered) {
            handleSignIn();
          } else {
            focusManager.changeFocus(passwordNav.id);
          }
          openKeyboard(false);
          setInputToEdit('');
        } else {
          focusManager.changeFocus(inputToEdit);
          openKeyboard(false);
          setInputToEdit('');
        }
      }

      return;
    } else if (text === 'Clear') {
      inputText = '';
    } else if (text === 'space') {
      inputText += ' ';
    } else if (text === 'delete') {
      inputText = inputText.substring(0, inputText.length - 1);
    } else {
      if (emailText.includes(text)) {
        inputText += `${text}.com`;
      } else {
        inputText += text;
      }
    }

    setText(inputText);
    setHasLoginFailed(false);
    setHasErrorMessage(false);
  };

  return {
    tab,
    setTab,
    handleEye,
    showPassword,
    username,
    password,
    isKeyboardOpen,
    inputToEdit,
    areCredentialsEntered,
    isUsernameEntered,
    hasLoginFailed,
    hasErrorMessage,
    authMessage,
    isLoginInProgress,
    registrationCode,
    history,
    onInputClick,
    handleSignIn,
    handleUsername,
    onVirtualKeyClick,
  };
};

export const LoginPageComponent = props => {
  const {
    tab,
    setTab,
    handleEye,
    showPassword,
    username,
    password,
    isKeyboardOpen,
    inputToEdit,
    areCredentialsEntered,
    isUsernameEntered,
    hasLoginFailed,
    hasErrorMessage,
    authMessage,
    isLoginInProgress,
    history,
    onInputClick,
    handleSignIn,
    handleUsername,
    onVirtualKeyClick,
  } = useLoginComponent();

  /** Handles the BACK button navigation logic **/
  const isMounted = useRef(false);
  const backId = useSelector(getPageBackId);

  useEffect(() => {
    if (tab === 0) {
      onInputClick(usernameNav.id);
    } else if (tab === 1) {
      onInputClick(passwordNav.id);
    }
  }, [tab]);

  const handlePrivacyPolicy = () => {
    props.kochava.sendEvent('Click', {
      name: 'Privacy Policy Button',
    });
    history.push(appRouteConstants.PRIVACY_POLICY);
  };

  useEffect(() => {
    if (isMounted.current && !isKeyboardOpen) {
      if (tab === 0) {
        history.goBack();
      } else {
        setTab(0);
        focusManager.changeFocus(usernameNav.id);
      }
    } else if (isMounted.current && isKeyboardOpen) {
      if (focusManager.getCurrentFocus() === 'sign-in-button') {
        history.goBack();
      } else if (inputToEdit === usernameNav.id) {
        if (username === '') {
          onVirtualKeyClick('OK');
        }
      } else if (inputToEdit === passwordNav.id) {
        if (password === '') {
          onVirtualKeyClick('OK');
        }
      }
    } else {
      isMounted.current = true;
    }
  }, [backId]);

  return (
    <Page nav={pageNav} className="login-page">
      {tab === 0 ? (
        <>
          <div className="login-page__username">
            <div className="login-page__title">
              {translate('signInPage.title')}
            </div>
            <FakeTextInput
              nav={
                isUsernameEntered
                  ? usernameNav
                  : { ...usernameNav, nextdown: 'privacy-policy-button' }
              }
              value={username}
              className={
                isKeyboardOpen && inputToEdit === usernameNav.id
                  ? `username text-input-focused ${
                      hasErrorMessage ? 'has-error' : ''
                    }`
                  : `username ${hasErrorMessage ? 'has-error' : ''}`
              }
              placeholder={translate('login.placeholder.username')}
              isPlaceholder={false}
              onClick={() => {
                onInputClick(usernameNav.id);
              }}
              hasError={hasLoginFailed}
            />
            {hasErrorMessage ? (
              <div className="login-page__disclaimer has-error">
                {authMessage}
              </div>
            ) : (
              ''
            )}
            <BlueButtonComponent
              onClick={() => {
                props.kochava.sendEvent('Click', {
                  name: 'Continue Button',
                });
                handleUsername();
              }}
              className="login-page__username-button"
              nav={
                isUsernameEntered
                  ? {
                      ...usernameContinueNav,
                      nextdown: 'privacy-policy-button',
                    }
                  : { ...usernameContinueNav, skip: true }
              }
              isActive={!!isUsernameEntered}
              onFocus={() => {
                setTimeout(() => {
                  (document.activeElement as any).blur();
                }, 50);
              }}
            >
              {translate('misc.continue')}
            </BlueButtonComponent>
            {!hasErrorMessage ? (
              <div className={'login-page__disclaimer'}>
                {translate('signInPage.forgotLink')}
              </div>
            ) : (
              ''
            )}
            <FocusButton
              nav={
                isUsernameEntered
                  ? {
                      id: 'privacy-policy-button',
                      nextup: usernameContinueNav.id,
                    }
                  : { id: 'privacy-policy-button', nextup: usernameNav.id }
              }
              theme={privacyPolicyButtonTheme}
              onClick={handlePrivacyPolicy}
            >
              {translate('misc.privacyPolicies')}
            </FocusButton>
          </div>
          {isKeyboardOpen && (
            <div className="login-soft-keyboard-container">
              <Keyboard
                nav={softKeyboardNav}
                onVirtualKeyClick={onVirtualKeyClick}
                isEmail={true}
              />
            </div>
          )}
        </>
      ) : (
        ''
      )}

      {tab === 1 ? (
        <>
          <div className="login-page__passwords">
            <div className="login-page__password">
              <div className="login-page__password-title">
                {translate('signInPage.enterPassword')}
              </div>
              <div className="login-page__password-sub-title">
                {translate('signInPage.passwordDisclaimer')}
              </div>
              <div className="login-page__password-input-container">
                <FakeTextInput
                  id={'password-input'}
                  nav={
                    areCredentialsEntered
                      ? passwordNav
                      : {
                          ...passwordNav,
                          nextdown: 'privacy-policy-button-pwd',
                        }
                  }
                  value={password}
                  className={
                    isKeyboardOpen && inputToEdit === passwordNav.id
                      ? `password text-input-focused ${
                          hasErrorMessage ? 'has-error' : ''
                        }`
                      : `password ${hasErrorMessage ? 'has-error' : ''}`
                  }
                  isPassword={showPassword}
                  isPlaceholder={false}
                  placeholder={translate('login.placeholder.password')}
                  onClick={() => {
                    onInputClick(passwordNav.id);
                  }}
                  hasError={hasLoginFailed}
                />
                <FocusButton
                  nav={
                    areCredentialsEntered
                      ? passwordEyeNav
                      : {
                          ...passwordEyeNav,
                          nextdown: 'privacy-policy-button-pwd',
                        }
                  }
                  onClick={() => {
                    props.kochava.sendEvent('Click', {
                      name: 'Hide/Show Password Button',
                    });
                    handleEye();
                  }}
                  theme={{
                    button: `back-buttons ${showPassword ? 'eye' : 'no-eye'}`,
                    buttonFocused: `back-buttons-focused ${
                      showPassword ? 'blue-eye' : 'blue-no-eye'
                    }`,
                    buttonActive: `back-buttons-active ${
                      showPassword ? 'eye' : 'no-eye'
                    }`,
                  }}
                  isActive={!showPassword}
                  onFocus={() => {
                    setTimeout(() => {
                      (document.activeElement as any).blur();
                    }, 50);
                  }}
                >
                  {showPassword ? <Eye /> : <HideEye />}
                </FocusButton>
              </div>
              <div className="login-page__password-text">
                {translate('signInPage.passwordUsername')}
                <br />
                <span>{username}</span>
              </div>
              <BlueButtonComponent
                onClick={() => {
                  props.kochava.sendEvent('Click', {
                    name: 'Sign In Button',
                  });
                  handleSignIn();
                }}
                className="login-page__username-button"
                nav={
                  areCredentialsEntered
                    ? {
                        ...signInButtonNav,
                        nextdown: 'privacy-policy-button-pwd',
                      }
                    : { ...signInButtonNav, skip: true }
                }
                isLoading={isLoginInProgress}
                isActive={!!areCredentialsEntered}
                onFocus={() => {
                  setTimeout(() => {
                    (document.activeElement as any).blur();
                  }, 50);
                }}
              >
                {translate('signInPage.signInButton')}
              </BlueButtonComponent>
              {!hasErrorMessage ? (
                <div className={'login-page__disclaimer pwd'}>
                  {translate('signInPage.forgotLink')}
                </div>
              ) : (
                ''
              )}
              <FocusButton
                nav={
                  areCredentialsEntered
                    ? {
                        id: 'privacy-policy-button-pwd',
                        nextup: signInButtonNav.id,
                      }
                    : {
                        id: 'privacy-policy-button-pwd',
                        nextup: passwordNav.id,
                      }
                }
                theme={privacyPolicyButtonTheme}
                onClick={handlePrivacyPolicy}
              >
                {translate('misc.privacyPolicies')}
              </FocusButton>
            </div>
          </div>
          {isKeyboardOpen && (
            <div className="login-soft-keyboard-container">
              <Keyboard
                nav={softKeyboardNav}
                onVirtualKeyClick={onVirtualKeyClick}
                isEmail={true}
              />
            </div>
          )}
        </>
      ) : (
        ''
      )}
    </Page>
  );
};
