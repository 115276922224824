/* eslint-disable */
import { BrowserUtil } from '../utils/browser.util';
import {
  DeviceInfo,
  IAppConfig,
  IContextualInfo,
  DeepLinkTypes,
  MediaPlayerConstants,
  StorageKeyConstant,
  ApiLayerTypes,
} from '../servicelib';
import { UUIDUtil } from '../utils/uuid.util';
import { TranslateConstants } from '../translate/translate.constants';
import { storageManager } from '../config/storage-manager';
import { systemManager } from '../config/system-manager';

// NOTE: The following 3 properties come from the build. See `client/webpack.common.js` for details.
//TODO: Config create-react-app and webpack to populate these values
/*
//declare const sxmAppVersion: string;
//declare const sxmGitHashNumber: string;
//declare const sxmTeamCityBuildNumber: string;
*/

export function getServiceConfig(): IAppConfig {
  const serviceConfig: IAppConfig = {
    apiEndpoint: BrowserUtil.getApiEndpoint(),
    domainName: BrowserUtil.getDomainName(),
    allProfilesData: undefined, // gets filled in from http service
    appId: 'EverestWebClient',
    resultTemplate: 'HLSP',
    //resultTemplate: 'web',
    //resultTemplate: 'tenfoot', //TODO: change to 'tenfoot' for comcast devices,
    adsWizzSupported: true,
    clientConfiguration: undefined, // gets filled in from http service
    inPrivateBrowsingMode: null, // gets filled in later depending on type of config
    restart: BrowserUtil.reload,
    deviceInfo: getDeviceInfo(),
    contextualInfo: getContextualInfo(),
    defaultSuperCategory: null, // gets filled in when channel line up returns data.
    loginRequired: false,
    uniqueSessionId: UUIDUtil.generate(),
    //isFreeTierEnable: BrowserUtil.getAppRegion() !== ApiLayerTypes.REGION_CA, // Updating status again in config service.
    isFreeTierEnable: false, // Hardcoding to false for now since free tier will not be supported for the time being, just Open Access
    initialPathname: BrowserUtil.getBrowserPathname(),
  };

  return serviceConfig;
}

/**
 * Return an object that contains all the device specific information needed within the app
 *
 * @returns {DeviceInfo} object that has device information that is either needed or set by the SiriusXM Everest API
 **/
function getDeviceInfo(): DeviceInfo {
  // The full app version that is required by the API, especially for BI consumption reporting, is both the
  // app version number and the team city build number concatenated together for a "." separator.

  //TODO: REPLACE AT BUILD TIME
  const sxmAppVersion: string = process.env.REACT_APP_VERSION;
  const sxmGitHashNumber: string = process.env.REACT_APP_GIT_SHORT_HASH || '';
  const sxmTeamCityBuildNumber: string =
    process.env.REACT_APP_GIT_SHORT_HASH || '';

  const appVersionAndBuildNumber: string = `${sxmAppVersion}`;
  const appRegion: string = storageManager.cloudCookieManager.get(
    StorageKeyConstant.APP_REGION,
  );

  const urlParam = location.pathname.split('/')[1];
  const langPref =
    TranslateConstants.LANGUAGELIST.indexOf(urlParam) !== -1 ? urlParam : null;

  if (langPref && appRegion === 'CA') {
    storageManager.cloudCookieManager.set(
      StorageKeyConstant.LANGUAGE,
      langPref,
    );
  }

  const language = storageManager.cloudCookieManager.get(
    StorageKeyConstant.LANGUAGE,
  )
    ? storageManager.cloudCookieManager.get(StorageKeyConstant.LANGUAGE)
    : 'en';

  const apiEndPoint = BrowserUtil.getApiEndpoint();

  const clientCapabilities = [
    'enhancedEDP',
    'seededRadio',
    'tabSortOrder',
    'zones',
    'cpColorBackground',
    'additionalVideo',
    'podcast',
    'irisPodcast',
  ];

  return {
    appRegion: appRegion, //TODO: Hardcoding to CA for now to force Open Access from API
    language: language,
    browser: BrowserUtil.getBrowserName(),
    browserVersion: BrowserUtil.getBrowserVersion(),
    clientCapabilities: clientCapabilities,
    clientDeviceId: storageManager.cloudCookieManager.get(
      StorageKeyConstant.CLIENT_DEVICE_ID,
    ),
    clientDeviceType: 'tenfoot',
    deviceModel: 'LG ' + systemManager.system.getModel(),
    osVersion:
      'webOS ' +
      (systemManager.system.getwebosTVVersion &&
        systemManager.system.getwebosTVVersion()),
    platform: 'Home', //Used to be 'Web', but 'Home' is the only way to get the Pandora Stations artists background image
    player: getPlayerType(),
    sxmAppVersion: appVersionAndBuildNumber,
    sxmGitHashNumber: sxmGitHashNumber.trim(),
    sxmTeamCityBuildNumber: sxmTeamCityBuildNumber,
    isChromeBrowser: BrowserUtil.isChrome(),
    isMobile: BrowserUtil.isMobile(),
    isNative: BrowserUtil.isNative(),
    supportsAddlChannels: true,
    supportsVideoSdkAnalytics: true,
    deviceMake: 'lgeverest',
    supportsVideo: true,
  };
}

/**
 * @returns {string} the playback type, which is either flash (IE11) or html5 (everything else)
 */
function getPlayerType(): string {
  return MediaPlayerConstants.HTML5_PLAYER;
}

/**
 * Returns information about the execution context that is needed by the app and the sxm service layer code
 *
 * @returns {IContextualInfo} execution context information for the currently running instance of the app
 */
function getContextualInfo(): IContextualInfo {
  const type = BrowserUtil.getQueryParameterValue('type');
  const id = BrowserUtil.getQueryParameterValue('id');

  return {
    userAgent: navigator.userAgent || navigator.vendor,
    queryString: window.location.search.substring(1),
    host: window.location.host,
    hostName: window.location.hostname,
    protocol: 'https:',
    type: type,
    id: id.replace(/%20/g, ' '),
    deepLink:
      (type && id) ||
      type === DeepLinkTypes.VIDEOS ||
      type === DeepLinkTypes.PODCASTS
        ? true
        : false,
  };
}
