import React, { useEffect } from 'react';
import './welcome-video.component.scss';
import HLS from 'hls.js';
import { appRouteConstants } from '../../routing/app.route.constants';

export const WelcomeVideoComponent = ({ pathname }) => {
  useEffect(() => {
    if (HLS.isSupported()) {
      const oAvPlayer = document.getElementById(
        'welcome-background-video',
      ) as HTMLMediaElement;
      const oHlsApi = new HLS({
        startLevel: 4,
      });
      oHlsApi.subtitleDisplay = false;
      oHlsApi.loadLevel = 4;
      oHlsApi.attachMedia(oAvPlayer);
      const backgroundVideoUrl =
        'https://siriusxm-prod-vod.mountain.siriusxm.com/east/tokenbypass-SXM-Onboarding-Video/hls/tokenbypass-SXM-Onboarding-Video.m3u8';
      oHlsApi.on(HLS.Events.MEDIA_ATTACHED, () => {
        oHlsApi.loadSource(backgroundVideoUrl);
      });

      return () => {
        oHlsApi.destroy();
      };
    }
  }, []);

  useEffect(() => {
    if (pathname !== appRouteConstants.FT_WELCOME) {
      const oAvPlayer = document.getElementById(
        'welcome-background-video',
      ) as HTMLMediaElement;

      oAvPlayer.pause();
    }
  }, [pathname]);

  return (
    <video
      id="welcome-background-video"
      preload={'true'}
      style={{
        visibility:
          pathname !== appRouteConstants.FT_WELCOME ? 'hidden' : 'visible',
      }}
    />
  );
};
