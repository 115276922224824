import React from 'react';
import MusicBars from '../music-bars/music-bars.component';
import { getTime } from '../../pages/now-playing/now-playing.component';
import {
  MediaPlayerService,
  MediaTimestampService,
  ServiceFactory,
} from '../../servicelib';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { useSelector } from 'react-redux';
import { getEpisode } from '../../redux/selector/now-playing.store';
import { focusManager } from '@accedo/vdkweb-navigation';

const SegmentTile = props => {
  const timer = React.useRef<any>(null);
  const {
    clicked,
    data: { title, times },
    isFocused,
    customOnItemClick,
    itemIndex,
  } = props;
  const episode = useSelector(getEpisode);

  const {
    segments = [],
    originalSegments = [],
    times: { zuluStartTime },
  } = episode;

  const totalSegments = segments.length
    ? segments.length
    : originalSegments.length;

  const mediaPlayerService = ServiceFactory.getInstance(
    MediaPlayerService,
  ) as MediaPlayerService;

  const mediaTimestampService = ServiceFactory.getInstance(
    MediaTimestampService,
  ) as MediaTimestampService;

  const isPaused = mediaPlayerService.isPaused();
  const playheadTimestamp = mediaTimestampService.playheadTimestamp;

  useUpdateEffect(() => {
    if (customOnItemClick) {
      customOnItemClick(times.zuluStartTime);
    }
  }, [clicked]);

  const isFirstSegment = itemIndex === 0;
  const isLastSegment = itemIndex === totalSegments - 1;
  const timelineClassNames = `segment-timeline ${
    isFirstSegment ? 'is-first-segment' : ''
  } ${isLastSegment ? 'is-last-segment' : ''}`;

  const currentTime = (times.zuluStartTime - zuluStartTime) / 1000;
  const playhead = zuluStartTime + playheadTimestamp * 1000;
  const isCurrentlyPlaying =
    playhead >= times.zuluStartTime && playhead <= times.zuluEndTime;

  const handleMouseOver = () => {
    timer.current = setTimeout(
      () => focusManager.changeFocus(props.nav.id),
      200,
    );
  };
  const handleMouseOut = () => {
    clearTimeout(timer.current);
  };
  return (
    <div
      className={`segment-card-container ${isFocused ? 'is-focused' : ''}`}
      onClick={() => props.tileClick(times.zuluStartTime)}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <span className={timelineClassNames} />
      <div className="segment-card-timestamp">
        {isCurrentlyPlaying && <MusicBars isPlaying={!isPaused} />}
        {!isCurrentlyPlaying && getTime(currentTime, false)}
      </div>
      <div className="segment-viewer-segment-title">{title}</div>
    </div>
  );
};

export default SegmentTile;
