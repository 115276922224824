/**
 * PointerContext is used to manage pointer device.
 * usePointer hook is provided to pointer status to
 * a component through PointerContext.
 */

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import xdk, { environment, core } from '@accedo/xdk-core';

const PointerContext = React.createContext(null);

const usePointer = () => React.useContext(PointerContext);

/**
 * Subscribe to pointer status changes.
 * @param {Function} callback - Invoked when pointer status changes. Invoked once with initial status.
 * @return {Function} cleanup function to stop subscribing to pointer status changes
 */
const whenPointerStatusChange = callback => {
  if (xdk.system.isMouseSupported()) {
    const enabled = () => {
      callback(true);
    };
    const disabled = () => {
      callback(false);
      /**
       * react slick is used in different components in app.
       * when react slick is clicked with a pointer it will
       * gain browser focus. when slick component has browser
       * focus it will capture and react to key presses
       * regardless of app/vdk focus. result of this is that
       * a slick component can react to key presses and
       * scroll while app is moving focus somewhere else on
       * the screen. calling blur on the active html element
       * when pointer is disabled to prevent any slick
       * component from keeping browser focus while
       * navigating with direction buttons.
       */
      core.root.document?.activeElement?.blur?.();
    };
    environment.addEventListener(environment.SYSTEM.MOUSEON, enabled);
    environment.addEventListener(environment.SYSTEM.MOUSEOFF, disabled);

    xdk.system.getMouse().start();
    // initial status
    callback(xdk.system.getMouse().isMouseOn());

    return () => {
      environment.removeEventListener(environment.SYSTEM.MOUSEON, enabled);
      environment.removeEventListener(environment.SYSTEM.MOUSEOFF, disabled);
    };
  }
};

const PointerContextProvider = ({ children }) => {
  const [enabled, setEnabled] = React.useState(false);
  const supported = xdk.system.isMouseSupported();

  useEffect(() => whenPointerStatusChange(setEnabled), []);

  return (
    <PointerContext.Provider
      value={{
        enabled,
        pointerEnabled: enabled,
        supported,
        pointerSupported: supported,
      }}
    >
      {children}
    </PointerContext.Provider>
  );
};

PointerContextProvider.propTypes = {
  children: PropTypes.node,
};

export default PointerContextProvider;

export {
  usePointer,
  whenPointerStatusChange,
  PointerContextProvider as PointerContext,
};
