import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectEpisodeCarousel } from '../../redux/selector/carousel.store';
import { ServiceFactory } from '../../servicelib';
import { CarouselStoreService } from '../../sxmservicelayer/carousel-store/carousel.store.service';
import { focusManager } from '@accedo/vdkweb-navigation';
import { Page } from '@accedo/vdkweb-tv-ui';
import {
  getLastFocusedTileId,
  getPageBackId,
} from '../../redux/selector/xdk.store';
import { useHistory } from 'react-router';
import { EPISODELISTING } from '../../utils/navigationMap';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import EpisodeTile from '../../components/tiles/EpisodeTile';
import Grid from '../../components/grid/Grid';
import { saveLastFocusedTileId } from '../../redux/action/xdk.action';
import { verticalScroll } from '../../utils/pageUtils';
import './episode-listing.component.scss';

const { PAGE } = EPISODELISTING;

const pageNav = {
  id: PAGE,
};

export const EpisodeListingPage = props => {
  const { setIsCoreMenuOpen } = props;

  const episodeListingCarousel = useSelector(selectEpisodeCarousel);
  const carouselStoreService = ServiceFactory.getInstance(
    CarouselStoreService,
  ) as CarouselStoreService;

  const [navIds, setNavIds] = useState({});
  const [focusedTile, setFocusedTile] = useState(null);
  const [animation, setAnimation] = useState(true);
  const dispatch = useDispatch();
  const forwardFocus = useRef(null);

  useEffect(() => {
    setIsCoreMenuOpen(false);
    const pathArray = location.pathname.split('/');
    const queryParameters = pathArray[pathArray.length - 1];
    carouselStoreService.selectEpisode(queryParameters);
  }, []);

  /** Handles the BACK button navigation logic **/
  const history = useHistory();
  const backId = useSelector(getPageBackId);

  useUpdateEffect(() => {
    history.goBack();
  }, [backId]);

  /* Reestablished the focused to the last focused tile before the page was exited */
  const lastFocusedTile = useSelector(getLastFocusedTileId);

  useEffect(() => {
    if (history.action === 'POP') {
      setAnimation(false);
    }

    if (lastFocusedTile[pageNav.id]) {
      forwardFocus.current = lastFocusedTile[pageNav.id];
      focusManager.changeFocus(pageNav.id);
      dispatch(
        saveLastFocusedTileId({ ...lastFocusedTile, [pageNav.id]: null }),
      );
    }

    setTimeout(() => {
      setAnimation(true);
    }, 1000);
  }, [history.location]);

  useEffect(() => {
    if (episodeListingCarousel && episodeListingCarousel.zone) {
      const ids = [];
      episodeListingCarousel.zone.forEach(zone => {
        zone.content.forEach(contentCarousel => {
          ids.push(contentCarousel.guid);
        });
      });

      const navs = {};
      ids.forEach((id, index) => {
        navs[id] = {
          id: id,
          nextup: ids[index - 1] || undefined,
          nextdown: ids[index + 1] || undefined,
        };
      });

      navs['page'] = {
        ...pageNav,
        forwardFocus: ids[0],
      };

      setNavIds(navs);
    }
  }, [episodeListingCarousel]);

  const onItemFocus = (data: {}) => {
    setFocusedTile(data);
  };

  useEffect(() => {
    focusManager.changeFocus(PAGE);
    window.scrollTo(0, 0);
  }, [navIds['page']]);

  return (
    <Page
      className="episode-listing-page"
      nav={navIds['page']}
      style={{
        backgroundImage:
          episodeListingCarousel &&
          episodeListingCarousel.pageBackground &&
          episodeListingCarousel.pageBackground.imageLink
            ? `url("${episodeListingCarousel.pageBackground.imageLink}?width=768&height=432&preserveAspect=true")`
            : '',
        backgroundSize:
          episodeListingCarousel &&
          episodeListingCarousel.pageBackground &&
          episodeListingCarousel.pageBackground.imageLink
            ? 'cover'
            : '',
        backgroundPosition:
          episodeListingCarousel &&
          episodeListingCarousel.pageBackground &&
          episodeListingCarousel.pageBackground.imageLink
            ? 'center'
            : '',
      }}
    >
      <div className="episode-listing-navbar">
        {episodeListingCarousel &&
          episodeListingCarousel.pageLogo &&
          episodeListingCarousel.pageLogo.imageLink && (
            <img
              className="episode-listing-navbar-logo"
              src={`${episodeListingCarousel.pageLogo.imageLink}?width=240&height=192&preserveAspect=true`}
              alt={episodeListingCarousel.pageLogo.imageAltText}
            />
          )}
        <div className="episode-listing-navbar-name">
          {episodeListingCarousel && episodeListingCarousel.pageTextLine1
            ? episodeListingCarousel.pageTextLine1
            : ''}
        </div>
      </div>
      <div className="episode-listing-content-container">
        {episodeListingCarousel &&
          episodeListingCarousel.zone &&
          episodeListingCarousel.zone.map(zone => {
            return (
              <Fragment key={zone.zoneId}>
                {zone.content &&
                  zone.content.map(contentCarousel => {
                    return (
                      <div>
                        <Grid
                          pageId={pageNav.id}
                          key={contentCarousel.guid}
                          nav={navIds[contentCarousel.guid]}
                          classNameItemWrapper={
                            'episode-listing-carousel episode-listing-general-style'
                          }
                          data={contentCarousel.tiles}
                          component={EpisodeTile}
                          maxItemsRow={contentCarousel.tiles.length}
                          customOnItemFocus={onItemFocus}
                          forwardFocus={forwardFocus.current}
                          animation={animation}
                          onFocus={id => {
                            if (!animation) {
                              verticalScroll(contentCarousel.guid);
                            }
                          }}
                          isVertical
                          isSpecialVertical
                        />
                        {focusedTile && (
                          <div className="episode-listing-right-container">
                            {focusedTile.line2 && (
                              <div className="episode-listing-right-container-title">
                                {focusedTile.line2}
                              </div>
                            )}
                            <span className="episode-listing-right-container-details transparent">
                              {focusedTile.iconImageUrl && (
                                <img
                                  className="episode-listing-right-container-icon"
                                  src={focusedTile.iconImageUrl}
                                />
                              )}
                              {focusedTile.line1 && (
                                <h3>{focusedTile.line1}</h3>
                              )}
                              {focusedTile.line3 && (
                                <h3>{focusedTile.line3}</h3>
                              )}
                            </span>
                            {(focusedTile.longDescription ||
                              focusedTile.line2) && (
                              <div className="episode-listing-right-container-description">
                                {focusedTile.longDescription
                                  ? focusedTile.longDescription
                                  : focusedTile.line2}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
              </Fragment>
            );
          })}
      </div>
    </Page>
  );
};
