import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Page } from '@accedo/vdkweb-tv-ui';
import { focusManager } from '@accedo/vdkweb-navigation';
import { appRouteConstants } from '../../routing/app.route.constants';
import './welcome-page.component.scss';
import { StorageKeyConstant } from '../../servicelib';
import { translate } from '../../utils/translate.util';
import TryFreeModalComponent from './try-free-modal.component';
import { useSelector } from 'react-redux';
import { getPageBackId } from '../../redux/selector/xdk.store';
import { storageManager } from '../../config/storage-manager';
import BlueButton from '../../components/blue-button/blue-button-component';
import { FocusButton } from '@accedo/vdkweb-tv-ui';

const pageNav = { id: 'welcome-page' };
const freeTrialButtonNav = {
  id: 'free-trial-button',
  nextleft: 'welcome-sign-in-button',
  nextdown: 'privacy-policy-button',
};
const signInButtonNav = {
  id: 'welcome-sign-in-button',
  nextright: 'free-trial-button',
};
const privacyPolicyButtonNav = {
  id: 'privacy-policy-button',
  nextup: 'free-trial-button',
};
const privacyPolicyButtonTheme = {
  button: 'privacy-policy-button',
  buttonFocused: 'privacy-policy-button-focused',
  buttonActive: 'privacy-policy-button-active',
};

export const WelcomePageComponent = props => {
  const history = useHistory();
  const [hasTryFree, setHasTryFree] = useState(false);
  const hasTryFreeContext = useRef(freeTrialButtonNav.id);
  const [hasVideoEnded, setHasVideoEnded] = useState(false);
  let welcomeBackgroundVideo = document.getElementById(
    'welcome-background-video',
  ) as HTMLMediaElement;

  const handleSignIn = () => {
    props.kochava.sendEvent('Click', {
      name: 'Sign In Button',
    });
    history.push(appRouteConstants.ACTIVATE_DEVICE);
  };

  useEffect(() => {
    focusManager.changeFocus(freeTrialButtonNav.id);
  }, []);

  const handleTryFree = () => {
    props?.kochava.sendEvent('Click', {
      name: `${freeTrialDurationText} Button`,
    });
    setHasTryFree(true);
    welcomeBackgroundVideo?.pause();
  };

  const handlePrivacyPolicy = () => {
    props.kochava.sendEvent('Click', {
      name: 'Privacy Policy Button',
    });
    history.push(appRouteConstants.PRIVACY_POLICY);
  };

  const isMounted = useRef(false);
  const backId = useSelector(getPageBackId);

  useEffect(() => {
    if (isMounted.current && hasTryFree) {
      if (hasTryFree) {
        focusManager.changeFocus(focusManager.getCurrentFocus());

        if (!hasVideoEnded) {
          welcomeBackgroundVideo?.play();
        }

        setHasTryFree(false);
      }
    } else {
      isMounted.current = true;
    }
  }, [backId]);

  const handleBackButton = () => {
    hasTryFreeContext.current = freeTrialButtonNav.id;
    setHasTryFree(false);
    props.kochava.sendEvent('Click', {
      name: 'Back Button',
    });
  };

  const freeTrialDurationText = storageManager.cloudCookieManager.get(
    StorageKeyConstant.FREE_TRIAL_DURATION_TEXT,
  );
  const platinumPlanText = JSON.parse(
    storageManager.cloudCookieManager.get(
      StorageKeyConstant.PLATINUM_PLAN_TEXT,
    ),
  );
  const subscriptionBenefits = [
    platinumPlanText.planLine1,
    platinumPlanText.planLine2,
    platinumPlanText.planLine3,
  ];

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes(appRouteConstants.FT_WELCOME)) {
      welcomeBackgroundVideo = document.getElementById(
        'welcome-background-video',
      ) as HTMLMediaElement;
      welcomeBackgroundVideo.ontimeupdate = onTimeUpdate;
      welcomeBackgroundVideo.currentTime = 0;
      welcomeBackgroundVideo.play();
      welcomeBackgroundVideo.onerror = () => {
        setHasVideoEnded(true);
      };
      return () => {
        welcomeBackgroundVideo.onerror = undefined;
        welcomeBackgroundVideo.ontimeupdate = undefined;
      };
    }
  }, [location.pathname]);

  const onTimeUpdate = e => {
    const { currentTime, duration } = e.target;

    if (currentTime >= duration - 2) {
      e.target.pause();
    }
    if (currentTime >= duration - 4) {
      setHasVideoEnded(true);
    }
  };

  return (
    <>
      <div
        id="welcome-gradient"
        style={{ opacity: hasVideoEnded ? 1 : 0 }}
      ></div>
      <Page nav={pageNav} className="welcome-page">
        <TryFreeModalComponent
          hasTryFree={hasTryFree}
          handleBackButton={handleBackButton}
          handleSignInButton={handleSignIn}
          restoreFocus={hasTryFreeContext.current}
          freeTrialDurationText={freeTrialDurationText}
          handlePrivacyPolicy={handlePrivacyPolicy}
        />
        <div className="welcome-wrapper">
          <div className="welcome-content-container">
            <div className="welcome-content">
              <div
                className="welcome-subscription-container"
                style={{ opacity: hasVideoEnded ? 1 : 0 }}
              >
                <div className="welcome-logo">
                  <div />
                </div>
                <div className="welcome-subscription-title">
                  <span>SiriusXM {platinumPlanText.planTitle}</span>
                  <span>{translate('welcome.welcomeSubTitle')}</span>
                </div>
                <div className="welcome-subscription-details">
                  {subscriptionBenefits.map((benefitText, benefitIndex) => {
                    return (
                      <div
                        className="welcome-subscription-benefit"
                        key={`benefit-${benefitIndex}`}
                      >
                        <div className="subscription-benefit-check">
                          <img src="/blue-check.svg" />
                        </div>
                        <div className="subscription-benefit-text">
                          {benefitText}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="welcome-buttons-container">
                <div className="welcome-sign-in-button-container">
                  <BlueButton
                    className={'sign-in-button'}
                    nav={signInButtonNav}
                    type={'primary'}
                    onClick={handleSignIn}
                  >
                    {translate('login.signInButton')}
                  </BlueButton>
                </div>
                <div className="welcome-free-trial-button-container">
                  <BlueButton
                    className={'sign-in-button'}
                    nav={freeTrialButtonNav}
                    type={'primary'}
                    isHeight={false}
                    onClick={handleTryFree}
                  >
                    {freeTrialDurationText}
                  </BlueButton>
                  <div className="welcome-privacy-policy-container">
                    {translate('welcome.welcomePolicy')}
                    <FocusButton
                      nav={privacyPolicyButtonNav}
                      theme={privacyPolicyButtonTheme}
                      onClick={handlePrivacyPolicy}
                    >
                      {translate('misc.privacyPolicies')}
                    </FocusButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Page>
    </>
  );
};
