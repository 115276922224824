import React from 'react';
import classnames from 'classnames';
import { withFocus } from '@accedo/vdkweb-navigation';
import { faChevronUp as iconUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faChevronDown as iconDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronLeft as iconLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight as iconRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { usePointer } from '../../context/PointerContext';
import { RoundButton } from './RoundButton';
import componentTheme from './NavigationArrow.module.scss';
import PropTypes from 'prop-types';

const NavigationArrow = ({
  up = false,
  down = false,
  left = false,
  right = false,
  onClick,
  rowUp = false,
  rowDown = false,
  rowVerticalDown = false,
  rowVerticalUp = false,
  nav,
  style = null,
}) => {
  const pointer = usePointer();

  const enabled = pointer?.pointerEnabled;

  if (!pointer?.pointerSupported || !enabled) {
    return null;
  }

  let icon;
  let dir;
  if (left === true) {
    icon = iconLeft;
    dir = 'left';
  } else if (right === true) {
    icon = iconRight;
    dir = 'right';
  } else if (up === true) {
    icon = iconUp;
    dir = 'up';
  } else if (down === true) {
    icon = iconDown;
    dir = 'down';
  }
  return (
    <div
      className={classnames(componentTheme.navigationArrow, {
        [componentTheme.enabled]: enabled,
        [componentTheme.left]: left,
        [componentTheme.right]: right,
        [componentTheme.up]: up,
        [componentTheme.down]: down,
        [componentTheme.rowUp]: rowUp,
        [componentTheme.rowDown]: rowDown,
        [componentTheme.rowVerticalDown]: rowVerticalDown,
        [componentTheme.rowVerticalUp]: rowVerticalUp,
      })}
      style={style}
      id={`button-${dir}`}
    >
      <RoundButton
        onClick={onClick}
        icon={icon}
        className={classnames(componentTheme.arrowButton)}
        id={`button-${dir}`}
      />
    </div>
  );
};

const FocusNavigationArrow = withFocus(NavigationArrow);

export { NavigationArrow, FocusNavigationArrow };

NavigationArrow.propTypes = {
  up: PropTypes.bool,
  down: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  onClick: PropTypes.func,
  rowUp: PropTypes.bool,
  rowDown: PropTypes.bool,
  rowVerticalDown: PropTypes.bool,
  rowVerticalUp: PropTypes.bool,
  nav: PropTypes.object,
  style: PropTypes.object,
};
