import React from 'react';
import styles from './input.module.scss';

type Props = {
  id?: string;
  placeholder?: string;
  value?: string;
  className?: string;
  disabled?: boolean;
};

const Input = ({ id, placeholder, value, className, disabled }: Props) => {
  return (
    <input
      id={id}
      defaultValue={value}
      placeholder={placeholder}
      className={`${styles['input']} ${className}`}
      disabled={disabled}
    />
  );
};

export default Input;
