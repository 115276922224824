import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { focusManager } from '@accedo/vdkweb-navigation';
import {
  getLastFocusedTileId,
  getPageBackId,
} from '../../redux/selector/xdk.store';
import './home-page.component.scss';
import { Page } from '@accedo/vdkweb-tv-ui';
import { selectSuperCategoryCarousel } from '../../redux/selector/carousel.store';
import { FOR_YOU, MENU } from '../../utils/navigationMap';
import { useHistory } from 'react-router';
import Swimlane from '../../components/swimlane/Swimlane';
import CategoryTile from '../../components/tiles/CategoryTile';
import HeroTile from '../../components/tiles/HeroTile';
import ChannelTile from '../../components/tiles/ChannelTile';
import { verticalScroll } from '../../utils/pageUtils';
import { saveLastFocusedTileId } from '../../redux/action/xdk.action';
import { zoneCategory } from '../../components/navigation-menu/navigation-menu-utils';
import VerticalScroll from '../../vertical-scroll/verticalScroll';

const { PAGE } = FOR_YOU;
const { ZONE_MENU } = MENU;

const pageNav = {
  id: PAGE,
  nextup: ZONE_MENU,
};

let lastInternalFocusedTile = null;

//TODO: Check if pressing back while on this screen on the X1 returns to the previous page
export const HomePageComponent = () => {
  const superCategoryCarousel = useSelector(selectSuperCategoryCarousel);
  const [navIds, setNavIds] = useState({});
  const [carouselItems, setCarouselItems] = useState([]);
  const [animation, setAnimation] = useState(true);
  const [categoryCarouselData, setCategoryCarouselData] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  const pathQuery = new URLSearchParams(location.search);
  const comingFromInternal = pathQuery.get('comingFromInternal');
  const comingFromInternalRef = useRef(comingFromInternal === 'true');

  /* Reestablished the focused to the last focused tile before the page was exited */
  const lastFocusedTile = useSelector(getLastFocusedTileId);

  useEffect(() => {
    if (history.action === 'POP') {
      setAnimation(false);
    }

    if (lastFocusedTile[pageNav.id]) {
      focusManager.changeFocus(lastFocusedTile[pageNav.id]);
      dispatch(
        saveLastFocusedTileId({ ...lastFocusedTile, [pageNav.id]: null }),
      );
    }

    setTimeout(() => {
      setAnimation(true);
    }, 1000);
  }, [history.location]);

  /* Determines if the Mini Player was opened, so that the focus does not go back to a tile if the Player was not opened through it */
  // useEffect(() => {
  //   return () => {
  //     if (focusManager.isChildFocused(pageNav.id)) {
  //       dispatch(
  //         saveLastFocusedTileId({
  //           ...lastFocusedTile,
  //           [pageNav.id]: lastInternalFocusedTile,
  //         }),
  //       );
  //     } else {
  //       dispatch(
  //         saveLastFocusedTileId({ ...lastFocusedTile, [pageNav.id]: null }),
  //       );
  //     }
  //   };
  // }, []);

  /* Handles the scenario whenever a user navigates to a different supercategory to reset the last focused tile data and scroll position
   *  Does not apply to Podcasts and Videos, as these are considered totally different screens from Home
   */
  useEffect(() => {
    lastInternalFocusedTile = null;
    window.scroll(0, 0);
    if (isMounted.current && history.action !== 'POP') {
      if (focusManager.isChildFocused(pageNav.id)) {
        const superCategoryToFocus = location.pathname.split('/')[2];
        const superCategoryButton = zoneCategory.discoverZone.buttons.find(
          button => button.superCategory === superCategoryToFocus,
        );
        focusManager.changeFocus(superCategoryButton.id);
      }
    }
  }, [location.pathname]);

  /* Handles the press of the BACK button only when the carousels are focused, */
  const isMounted = useRef(false);
  const backId = useSelector(getPageBackId);

  useEffect(() => {
    if (
      isMounted.current &&
      Object.keys(navIds).some(key =>
        focusManager.getCurrentFocus().includes(key),
      )
    ) {
      focusManager.changeFocus(ZONE_MENU);
    } else {
      isMounted.current = true;
    }
  }, [backId]);

  /* Generates the nav objects for the carousels */
  useEffect(() => {
    if (superCategoryCarousel && superCategoryCarousel.zone) {
      const ids = [];
      superCategoryCarousel.zone.forEach(zone => {
        zone.hero.forEach(heroCarousel => {
          ids.push(heroCarousel.guid);
        });

        if (zone.subCategories.length) {
          ids.push(zone.zoneGuid);
        }

        zone.content.forEach(contentCarousel => {
          ids.push(contentCarousel.guid);
        });
      });

      const navs = {};
      const carousels = [];
      ids.forEach((id, index) => {
        carousels.push(id);
        navs[id] = {
          id: id,
          nextup: ids[index - 1] || pageNav.nextup,
          nextdown: ids[index + 1],
          useLastFocus: true,
          parent: pageNav.id,
        };
      });

      if (comingFromInternalRef.current) {
        if (!focusManager.isChildFocused(pageNav.id)) {
          focusManager.changeFocus(`${ids[0]}-ITEM-0`);
        }
      }

      navs['page'] = {
        ...pageNav,
      };

      setNavIds(navs);
      setCarouselItems(carousels);

      const categoryTiles = superCategoryCarousel.category?.tiles;
      const zoneCategoryTiles = superCategoryCarousel.zone?.[0]?.subCategories;
      const categoryCarousel = [];

      if (
        superCategoryCarousel.category &&
        categoryTiles.length > 0 &&
        zoneCategoryTiles.length > 0
      ) {
        zoneCategoryTiles.forEach((el, i) => {
          let bgImageUrl = '';
          const getCategoryTilesIndex = categoryTiles.findIndex(
            tile => tile.ariaText === el.name,
          );

          if (categoryTiles?.[getCategoryTilesIndex]?.bgImageUrl) {
            bgImageUrl = categoryTiles?.[getCategoryTilesIndex]?.bgImageUrl;
          } else {
            const imageObject = el?.imageList?.find(imageObject => {
              return imageObject.name === 'everest tile category background';
            });

            if (imageObject) {
              //TODO: Obtain relative URL from config object

              bgImageUrl = (imageObject as any).relativeUrl.replace(
                '%Image%',
                'https://siriusxm-art-dd.akamaized.net',
              );
            }
          }

          categoryCarousel.push({
            ...el,
            ...{
              bgImageUrl: bgImageUrl,
              fgImageUrl: categoryTiles?.[getCategoryTilesIndex]?.fgImageUrl,
              line1: categoryTiles?.[getCategoryTilesIndex]?.line1,
            },
          });
        });

        setCategoryCarouselData(categoryCarousel);
      }
    }
  }, [superCategoryCarousel]);

  const isInitialHeroCarouselMissing = !(
    superCategoryCarousel &&
    superCategoryCarousel.zone &&
    superCategoryCarousel.zone[0] &&
    superCategoryCarousel.zone[0].hero &&
    superCategoryCarousel.zone[0].hero.length > 0
  );

  return (
    <VerticalScroll
      nav={navIds}
      items={carouselItems}
      parent={navIds['page']?.id}
      navBar={() => document.querySelector('.menu-wrapper')?.clientHeight}
    >
      <Page
        className="home-page"
        nav={{
          ...navIds['page'],
          skip: !(
            superCategoryCarousel && Object.keys(superCategoryCarousel).length
          ),
        }}
        onFocus={() => {
          if (lastInternalFocusedTile) {
            focusManager.changeFocus(lastInternalFocusedTile);
          } else if (!lastFocusedTile[pageNav.id]) {
            const [firstCarouselId] = Object.keys(navIds);
            focusManager.changeFocus(firstCarouselId);
          } else if (lastFocusedTile[pageNav.id]) {
            focusManager.changeFocus(lastFocusedTile[pageNav.id]);
          }
        }}
      >
        {superCategoryCarousel &&
          superCategoryCarousel.zone &&
          superCategoryCarousel.zone.map(zone => {
            return (
              <Fragment key={zone.zoneId}>
                {zone.hero &&
                  zone.hero.map(heroCarousel => {
                    return (
                      <Swimlane
                        pageId={pageNav.id}
                        key={heroCarousel.guid}
                        nav={navIds[heroCarousel.guid]}
                        className={'home-hero-carousel-wrapper'}
                        headerClassName={'carousel-categories-header-top'}
                        itemWrapperClassName={'hero-wrapper'}
                        data={heroCarousel.tiles}
                        component={HeroTile}
                        displayText={zone.zoneTitle?.toUpperCase()}
                        animation={animation}
                        onFocus={id => {
                          if (!animation) {
                            verticalScroll(heroCarousel.guid);
                          }
                          lastInternalFocusedTile = id;
                        }}
                      />
                    );
                  })}
                {zone.subCategories && zone.subCategories.length > 0 && (
                  <Swimlane
                    pageId={pageNav.id}
                    key={zone.zoneGuid}
                    nav={navIds[zone.zoneGuid]}
                    category={
                      superCategoryCarousel.pageTitle.textValue === 'Talk'
                        ? 'entertainment'
                        : superCategoryCarousel.pageTitle.textValue.toLowerCase()
                    }
                    className={`home-carousel-wrapper ${
                      isInitialHeroCarouselMissing ? 'is-hero-missing' : ''
                    }`}
                    headerClassName={'carousel-categories-header-top'}
                    itemWrapperClassName={'category-wrapper'}
                    displayText={`${superCategoryCarousel.pageTitle.textValue}  Categories`}
                    data={categoryCarouselData}
                    component={CategoryTile}
                    animation={animation}
                    onFocus={id => {
                      if (!animation) {
                        verticalScroll(zone.zoneGuid);
                      }
                      lastInternalFocusedTile = id;
                    }}
                    slideCount={6}
                  />
                )}
                {zone.content &&
                  zone.content.map(contentCarousel => {
                    return (
                      contentCarousel &&
                      zone.content && (
                        <Swimlane
                          pageId={pageNav.id}
                          key={contentCarousel.guid}
                          nav={navIds[contentCarousel.guid]}
                          className={'home-hero-carousel-wrapper'}
                          headerClassName={'carousel-categories-header-top'}
                          itemWrapperClassName={'channel-wrapper'}
                          data={contentCarousel.tiles}
                          component={ChannelTile}
                          displayText={contentCarousel.title?.textValue}
                          animation={animation}
                          slideCount={4}
                          onFocus={id => {
                            if (!animation) {
                              verticalScroll(contentCarousel.guid);
                            }
                            lastInternalFocusedTile = id;
                          }}
                        />
                      )
                    );
                  })}
              </Fragment>
            );
          })}
      </Page>
    </VerticalScroll>
  );
};
