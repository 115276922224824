import React, { useEffect, useState, useRef } from 'react';
import { FocusButton, Page } from '@accedo/vdkweb-tv-ui';
import {
  ServiceFactory,
  AuthenticationService,
  ApiDelegate,
  ApiCodes,
  ResumeService,
} from '../../servicelib';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { concatMap, catchError, takeWhile } from 'rxjs/operators';
import { interval } from 'rxjs';
import { appRouteConstants } from '../../routing/app.route.constants';
import {
  showPodcastsMenuOption,
  showVideosMenuOption,
} from '../../redux/action/xdk.action';
import { terminateFreeTrial } from '../../utils/free-trial-expiration.util';
import { useHistory } from 'react-router-dom';
import './activate-device.component.scss';
import BlueButtonComponent from '../../components/blue-button/blue-button-component';
import { getPageBackId } from '../../redux/selector/xdk.store';
import { focusManager } from '@accedo/vdkweb-navigation';
import { translate } from '../../utils/translate.util';

const pageNav = { id: 'activate-page' };
const activatePageNav = {
  id: 'activate-page-sign-in-button',
  nextdown: 'privacy-policy-button',
};
const privacyPolicyButtonTheme = {
  button: 'general-privacy-policy-button',
  buttonFocused: 'general-privacy-policy-button-focused',
  buttonActive: 'general-privacy-policy-button-active',
};

const privacyPolicyButtonNav = {
  id: 'privacy-policy-button',
  nextup: 'activate-page-sign-in-button',
};

export const ActivateDeviceComponent = ({ kochava }) => {
  const [registrationCode, setRegistrationCode] = useState('');
  const [regCodeCounter, setRegCodeCounter] = useState(1);
  const [pollFrequency, setPollFrequency] = useState(20);
  const [codeExpirationTime, setCodeExpirationTime] = useState(moment());
  const history = useHistory();
  const dispatch = useDispatch();
  const authenticationService = ServiceFactory.getInstance(
    AuthenticationService,
  ) as AuthenticationService;

  useEffect(() => {
    const subscription = authenticationService.createAlternateLogin().subscribe(
      ({ authenticationData }) => {
        const {
          registrationCode,
          pollFrequency,
          regExpiration,
        } = authenticationData;
        setPollFrequency(pollFrequency);
        setCodeExpirationTime(moment(regExpiration));
        setRegistrationCode(registrationCode);
      },
      error => {
        console.error(error);
      },
    );

    return () => subscription.unsubscribe();
  }, [regCodeCounter]);

  useEffect(() => {
    if (registrationCode) {
      const freeTrialStatus = { isExpired: undefined };
      const apiDelegate = ServiceFactory.getInstance(
        ApiDelegate,
      ) as ApiDelegate;
      const apiDelegateSubscription = apiDelegate.addApiCodeHandler(
        ApiCodes.FREE_TIER_EXPIRED,
        (codes, apiMessages, response) => {
          freeTrialStatus.isExpired = true;
        },
      );

      const subscription = interval(pollFrequency * 1000)
        .pipe(
          takeWhile(() => {
            if (codeExpirationTime.diff(moment()) <= 0) {
              setRegCodeCounter(counter => counter + 1);
            }

            return codeExpirationTime.diff(moment()) > 0;
          }),
          concatMap(() =>
            authenticationService.completeAlternateLogin(registrationCode),
          ),
          catchError((err, caught) => caught),
          concatMap(() => {
            const resumeService = ServiceFactory.getInstance(
              ResumeService,
            ) as ResumeService;
            return resumeService.resume();
          }),
        )
        .subscribe(() => {
          if (freeTrialStatus && freeTrialStatus.isExpired) {
            authenticationService.logout().subscribe({
              next: () => {
                dispatch(showPodcastsMenuOption(false));
                dispatch(showVideosMenuOption(false));
                terminateFreeTrial();
                history.replace(appRouteConstants.AUTH.OPEN_ACCESS_EXPIRED);
              },
            });
          } else {
            history.replace(appRouteConstants.AUTH.LOGIN_CONFIRMATION);
          }
        });

      return () => {
        apiDelegateSubscription.removeCodeHandler();
        subscription.unsubscribe();
      };
    }
  }, [registrationCode]);
  const handleSignIn = () => {
    kochava.sendEvent('Click', {
      name: 'Sign In with Username or Email Button ',
    });
    history.push(appRouteConstants.AUTH.LOGIN);
  };
  const backId = useSelector(getPageBackId);
  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      history.goBack();
    } else {
      isMounted.current = true;
    }
  }, [backId]);
  useEffect(() => {
    focusManager.changeFocus(activatePageNav.id);
  }, []);
  const handlePrivacyPolicy = () => {
    kochava.sendEvent('Click', {
      name: 'Privacy Policy Button',
    });
    history.push(appRouteConstants.PRIVACY_POLICY);
  };
  return (
    <Page nav={pageNav} className="activate-page">
      <div>
        <div className="activate-page__title">
          {translate('activateDevice.title')}
        </div>
        <div className="activate-page__body">
          <div>
            <div className="activate-page__item">
              <div className="activate-page__small-text">
                {translate('activateDevice.first')}
              </div>
              <div className="activate-page__text">
                {translate('activateDevice.firstItem')}
              </div>
              <div className="activate-page__qrImg">
                <img src={translate('login.links.qrCode')} />
              </div>
            </div>
          </div>

          <div>
            <div className="activate-page__item activate-page__item2">
              <div className="activate-page__small-text">
                {translate('activateDevice.second')}
              </div>
              <div className="activate-page__text">
                {translate('activateDevice.secondItem')}
              </div>
              <div className="activate-page__code">{registrationCode}</div>
            </div>
          </div>
        </div>
        <div className="horizontal-divider">
          <span>{translate('misc.or')}</span>
        </div>
        <div className="activate-page__sub-title">
          {translate('activateDevice.buttonText')}
        </div>
        <BlueButtonComponent
          onClick={handleSignIn}
          nav={activatePageNav}
          className={'proceed-to-sign-in-button'}
        >
          {translate('activateDevice.button')}
        </BlueButtonComponent>
        <FocusButton
          nav={privacyPolicyButtonNav}
          theme={privacyPolicyButtonTheme}
          onClick={handlePrivacyPolicy}
        >
          {translate('misc.privacyPolicies')}
        </FocusButton>
      </div>
    </Page>
  );
};
