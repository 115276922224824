import { XdkActions } from '../action/xdk.action';
import { pageRedux } from '@accedo/vdkweb-tv-ui';

const { actions, reducer } = pageRedux;
const initialState = {
  backId: 0,
  exitId: 0,
  focusId: 0,
  rightId: 0,
  leftId: 0,
  keyPressedId: 0,
  ffwId: 0,
  rewindId: 0,
  playPauseId: 0,
  stopId: 0,
  lastFocusedTile: {},
  timeRemaining: '',
  doubleTapRightId: 0,
  doubleTapLeftId: 0,
};
const defaultAction = { type: '' };

export function xdkReducer(state, action) {
  state = state || initialState;
  action = action || defaultAction;

  let currentState = { ...state };

  switch (action.type) {
    case XdkActions.SAVE_LAST_FOCUSED_TILE_ID:
      currentState.lastFocusedTile = action.lastFocusedTile;
      break;

    case XdkActions.SHOW_PODCASTS_MENU_OPTION:
      currentState.shouldShowPodcastsMenuOption =
        action.shouldShowPodcastsMenuOption;
      break;

    case XdkActions.SHOW_VIDEOS_MENU_OPTION:
      currentState.shouldShowVideosMenuOption =
        action.shouldShowVideosMenuOption;
      break;

    case XdkActions.PAGE_RIGHT:
      currentState.rightId += 1;
      break;

    case XdkActions.PAGE_LEFT:
      currentState.leftId += 1;
      break;

    case XdkActions.KEY_PRESSED:
      currentState.keyPressedId += 1;
      break;

    case XdkActions.FFW_KEY_PRESSED:
      currentState.ffwId += 1;
      break;

    case XdkActions.REWIND_KEY_PRESSED:
      currentState.rewindId += 1;
      break;

    case XdkActions.STOP_KEY_PRESSED:
      currentState.stopId += 1;
      break;

    case XdkActions.PLAY_PAUSE_KEY_PRESSED:
      currentState.playPauseId += 1;
      break;

    case XdkActions.UPDATE_FREE_TRIAL_COUNTER:
      currentState.timeRemaining = action.timeRemaining;
      break;

    case XdkActions.DOUBLE_TAP_RIGHT:
      currentState.doubleTapRightId += 1;
      break;

    case XdkActions.DOUBLE_TAP_LEFT:
      currentState.doubleTapLeftId += 1;
      break;

    case actions.PAGE_BACK:
    case actions.PAGE_EXIT:
    case actions.PAGE_FOCUS_CURRENT:
      currentState = reducer(currentState, action);
      break;
  }

  return currentState;
}
