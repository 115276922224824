import React from 'react';
import { useSelector } from 'react-redux';
import { getFreeTrialCounter } from '../../redux/selector/xdk.store';
import { translate } from '../../utils/translate.util';
import './free-trial-counter.component.scss';

const FreeTrialCounterComponent = props => {
  const timeRemaining = useSelector(getFreeTrialCounter);

  return (
    <div className="free-trial-counter-container">
      <div>Your preview expires in</div>
      <div className="free-trial-expiration-time">
        &nbsp;{timeRemaining}.&nbsp;
      </div>
      <div className="free-trial-subscription-url">
        Subscribe to SiriusXM, go to: {translate('freeTier.expirationLink')}
      </div>
    </div>
  );
};

export default FreeTrialCounterComponent;
