import React, { useRef, useEffect, useState } from 'react';
import { withFocus } from '@accedo/vdkweb-navigation';
import './fake-text-input.component.scss';

const textSpanOffset = 16;

const FakeTextInput = props => {
  const {
    className,
    isPassword,
    nav,
    value,
    isFocused,
    placeholder,
    hasError,
    onClick,
    isPlaceholder = true,
    ...rest
  } = props;
  const theme = {
    input: `fake-text-input ${className} ${hasError ? 'has-error' : ''}`,
    inputFocused: 'fake-text-input-focused',
  };

  const inputEl = useRef(null);
  const textSpanRef = useRef(null);
  const [textSpanLeftMargin, setTextSpanLeftMargin] = useState(0);

  useEffect(() => {
    if (
      className &&
      className.includes('fake-text-input-focused') &&
      textSpanRef.current
    ) {
      const overflowQuantity =
        textSpanRef.current.scrollWidth - textSpanRef.current.clientWidth;
      setTextSpanLeftMargin(
        overflowQuantity > 0 ? overflowQuantity + textSpanOffset : 0,
      );
    }
  }, [value]);

  useEffect(() => {
    if (className) {
      if (className.includes('fake-text-input-focused') || isFocused) {
        const overflowQuantity =
          textSpanRef.current.scrollWidth - textSpanRef.current.clientWidth;
        setTextSpanLeftMargin(
          overflowQuantity > 0 ? overflowQuantity + textSpanOffset : 0,
        );
      } else {
        setTextSpanLeftMargin(0);
      }
    }
  }, [className, isFocused]);

  const { input: themeInput, inputFocused } = {
    ...theme,
  };

  const klassName = `${themeInput} ${nav.isFocused && inputFocused}`.trim();

  return (
    <div className={klassName} style={props.style}>
      <div className="input" ref={inputEl} onClick={onClick} {...rest}>
        <span
          ref={textSpanRef}
          style={{ marginLeft: `${textSpanLeftMargin * -1}px` }}
        >
          {isPassword ? new Array((value || '').length).fill('•') : value}
        </span>
        <span
          className="caret"
          style={{
            marginLeft: `${
              textSpanLeftMargin ? textSpanLeftMargin - (textSpanOffset - 1) : 0
            }px`,
          }}
        />
      </div>
      {isPlaceholder ? (
        <div
          className="placeholder"
          style={value ? { top: '-0.5rem', fontSize: '1.25rem' } : null}
          onClick={onClick}
        >
          <label>{placeholder}</label>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default withFocus(FakeTextInput);
