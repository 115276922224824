import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { appRouteConstants } from '../../routing/app.route.constants';
import { ReactComponent as DiscoverIcon } from '../../assets/images/discover-on-white.svg';
import { ReactComponent as FavoritesIcon } from '../../assets/images/favorite-on-white.svg';
import { ReactComponent as RecentIcon } from '../../assets/images/recent-on-blue.svg';
import { RoundedButton } from '../rounded-button';
import MenuButton from './menu-button';
import { focusManager } from '@accedo/vdkweb-navigation';
import './navigation-menu.component.scss';
import FocusDiv from '../focus-div/FocusDiv';
import { getHorizontalNav, INavMap } from '../../utils/navigationHelper';
import { zoneCategory } from './navigation-menu-utils';
import {
  FAVORITES,
  FOR_YOU,
  PODCASTSVIDEO,
  MENU,
} from '../../utils/navigationMap';
import { useSelector, useDispatch } from 'react-redux';
import { getPageBackId } from '../../redux/selector/xdk.store';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { selectSuperCategoryCarousel as selectSuperCategoryCarouselAction } from '../../redux/action/carousel.action';

const { ZONE_MENU, CORE_MENU } = MENU;
const { PAGE: FOR_YOU_PAGE } = FOR_YOU;
const { PAGE: FAVORITES_PAGE } = FAVORITES;

export const ZoneNavigationMenu = ({
  nav,
  coreCategory,
  isCoreMenuOpen,
  setIsCoreMenuOpen,
}) => {
  const history = useHistory();
  const location = useLocation();

  const getContainerNav = (containersList: any[], nextdown: string = '') => {
    const containerNavIds = getHorizontalNav(
      containersList.map(container => container.id),
      {
        nextup: nav.nextup,
        nextdown:
          location.pathname.includes(appRouteConstants.ALL_PODCASTS) ||
          location.pathname.includes(appRouteConstants.ALL_VIDEO)
            ? PODCASTSVIDEO.PAGE
            : nextdown,
        parent: ZONE_MENU,
      },
    );

    return containerNavIds;
  };

  const [zoneButtons, setZoneButtons] = useState(
    zoneCategory.discoverZone as any,
  );

  const discoveryZoneNav: INavMap<string, any> = getContainerNav(
    [...zoneCategory.discoverZone.buttons, { id: 'all-channels-button' }],
    FOR_YOU_PAGE,
  );
  const favoriteZoneNav: INavMap<string, any> = getContainerNav(
    [...zoneCategory.favoritesZone.buttons],
    FAVORITES_PAGE,
  );

  const backId = useSelector(getPageBackId);

  useUpdateEffect(() => {
    if (
      focusManager.isChildFocused(ZONE_MENU) ||
      focusManager.isFocused(discoveryZoneNav['all-channels-button']?.id)
    ) {
      setIsCoreMenuOpen(true);
    }
  }, [backId]);

  useUpdateEffect(() => {
    if (isCoreMenuOpen) {
      focusManager.changeFocus(CORE_MENU);
    }
  }, [isCoreMenuOpen]);

  const internalNextUp = {
    nextup: () => {
      setIsCoreMenuOpen(true);
    },
  };

  useEffect(() => {
    if (coreCategory.includes(zoneCategory.discoverZone.path)) {
      setZoneButtons(zoneCategory.discoverZone);
    }
    if (coreCategory.includes(zoneCategory.favoritesZone.path)) {
      setZoneButtons(zoneCategory.favoritesZone);
    }
    if (coreCategory.includes(zoneCategory.categoryZone.path)) {
      setZoneButtons(zoneCategory.categoryZone);
    }
    if (
      coreCategory.includes(zoneCategory.recentZone.path) ||
      coreCategory.includes(zoneCategory.searchZone.path) ||
      coreCategory.includes(zoneCategory.settingsZone.path)
    ) {
      setZoneButtons({});
    }
  }, [coreCategory]);

  const dispatch = useDispatch();
  function navigateToPage(path, navigateInstruction) {
    return () => {
      if (history.location.pathname.includes(navigateInstruction)) {
        //Prevents swimlanes to be cleared if for any reason user selects the same supercategory they're currently on
        return;
      }

      if (
        '/' + navigateInstruction === appRouteConstants.ALL_PODCASTS ||
        '/' + navigateInstruction === appRouteConstants.ALL_VIDEO
      ) {
        dispatch(selectSuperCategoryCarouselAction({}));
        history.replace(
          `${appRouteConstants.CATEGORY_PAGE}/${navigateInstruction}`,
        );
      } else {
        dispatch(selectSuperCategoryCarouselAction({}));
        history.replace(`${path}/${navigateInstruction}`);
      }
    };
  }

  const onZoneMenuFocus = () => {
    focusManager.changeFocus(zoneButtons?.buttons?.[0]?.id);
    setIsCoreMenuOpen(false);
  };

  let zoneNav = {};

  if (zoneButtons?.path === '/home') {
    zoneNav = discoveryZoneNav;
  } else if (zoneButtons?.path === '/favorites') {
    zoneNav = favoriteZoneNav;
  }

  return (
    <FocusDiv
      nav={{ ...nav }}
      onFocus={() => onZoneMenuFocus()}
      className={`zone-menu ${!isCoreMenuOpen ? 'core-menu-closed' : ''}`}
      dataName={'menu'}
    >
      <div className="zone-menu-icon-container">
        {(coreCategory.includes(zoneCategory.discoverZone.path) ||
          coreCategory.includes(appRouteConstants.ALL_PODCASTS) ||
          coreCategory.includes(appRouteConstants.ALL_VIDEO)) && (
          <DiscoverIcon className="zone-menu-icon" />
        )}

        {coreCategory.includes(zoneCategory.favoritesZone.path) && (
          <>
            <FavoritesIcon className="zone-menu-icon" />
            <div className="zone-menu-label">Favorites</div>
          </>
        )}
        {coreCategory.includes(zoneCategory.recentZone.path) && (
          <>
            <RecentIcon className="zone-menu-icon" />
            <div className="zone-menu-label">Recent</div>
          </>
        )}
      </div>
      <div className="zone-menu-names">
        {zoneButtons?.buttons?.map((button, i) => {
          return (
            <MenuButton
              type={
                location.pathname.includes(button.superCategory)
                  ? 'zone'
                  : button.type
              }
              nav={{ ...zoneNav[button.id], internal: internalNextUp }}
              onClick={navigateToPage(zoneButtons.path, button.superCategory)}
              className="zone-menu-button-container"
              key={button.title}
              onFocus={() => {
                (document.activeElement as any).blur();
              }}
              style={{ marginRight: button.marginRight }}
            >
              <div
                className={
                  location.pathname.includes(button.superCategory)
                    ? 'zone-menu-name-focused'
                    : button.className
                }
                id={button.id}
                style={{ width: button.itemWidth }}
              >
                {button.title}
                {location.pathname.includes(button.superCategory) && (
                  <span className="underline"></span>
                )}
              </div>
            </MenuButton>
          );
        })}
        {(coreCategory.includes(zoneCategory.discoverZone.path) ||
          coreCategory.includes(appRouteConstants.ALL_PODCASTS) ||
          coreCategory.includes(appRouteConstants.ALL_VIDEO)) && (
          <RoundedButton
            className="zone-menu-button-container"
            type={'tertiary'}
            nav={{
              ...discoveryZoneNav['all-channels-button'],
              internal: internalNextUp,
            }}
            onClick={() => {
              focusManager.changeFocus(PODCASTSVIDEO.PAGE);
              // Use push so that users can go back
              history.push(appRouteConstants.ALL_CHANNELS);
            }}
          >
            <div className="zone-menu-channels" id="all-channels-button">
              All Channels
            </div>
          </RoundedButton>
        )}
      </div>
    </FocusDiv>
  );
};
