import React from 'react';
import { FocusButton } from '@accedo/vdkweb-tv-ui';

const RelatedTab = props => {
  const { selectedTab, data, ...rest } = props;
  const theme = {
    button: 'related-tab',
    buttonFocused: 'related-tab-focused',
  };

  if (selectedTab === data.__index) {
    theme.button += ' related-tab-selected';
  }

  return (
    <FocusButton theme={theme} {...rest}>
      {data.text}
      <span
        className="underline"
        style={{
          visibility: selectedTab === data.__index ? 'visible' : 'hidden',
        }}
      ></span>
    </FocusButton>
  );
};

export default RelatedTab;
