import React from 'react';
import FocusDiv from '../focus-div/FocusDiv';
import { useEffect, useState, memo, useCallback } from 'react';

import { focusManager } from '@accedo/vdkweb-navigation';

import {
  createColumns,
  createColumnId,
  createVerticalGridNavigation,
  createRows,
  createRowId,
  createGridNavigation,
} from './gridUtils';
import Row from './Row';
import styles from './grid.module.scss';
import useLatest from '../../hooks/useLatest';
import { getRelativeVWPixel } from '../../utils/relativePxValues';
import { verticalScroll, verticalSmoothScroll } from '../../utils/pageUtils';
import GridVerticalScrollWrapper from './GridVerticalScrollWrapper';
import xdk from '@accedo/xdk-core';

type props = {
  nav: XDKNav;
  pageId?: string;
  isVertical?: boolean;
  data: any[];
  component: React.ComponentType<any>;
  className?: string;
  classNameItemWrapper: string;
  maxItemsRow?: number;
  isSpecialVertical?: boolean;
  animation?: boolean;
  forwardFocus?: string | undefined;
  onFocus?: (id: string) => void;
  onWrapperClick?: (id: string) => void;
  customOnItemClick?: (id: string) => void;
  customOnItemFocus?: (id: string) => void;
  showArrows?: boolean;
  showDefault?: boolean;
  isSegment?: boolean;
  allowAnimation?: boolean;
  showUpToIndexRef?: any;
  setShowUpToIndex?: (showUpToIndex: number) => void;
};

const getNewXAxis = (elementId: string) => {
  if (!elementId) {
    return 0;
  }

  const curElement = document.getElementById(elementId);

  return -(curElement?.offsetLeft - getRelativeVWPixel(350) || 0);
};

const getNewYAxis = (elementId: string) => {
  if (!elementId) {
    return 0;
  }

  const curElement = document.getElementById(elementId);

  return -(curElement?.offsetTop - getRelativeVWPixel(350) || 0);
};

const Grid = ({
  nav,
  pageId,
  isVertical = false,
  data,
  component,
  className = '',
  classNameItemWrapper,
  maxItemsRow,
  isSpecialVertical = false,
  forwardFocus = undefined,
  animation = true,
  onFocus,
  onWrapperClick = null,
  customOnItemClick,
  customOnItemFocus,
  showArrows,
  showDefault = false,
  isSegment = false,
  allowAnimation = true,
  showUpToIndexRef,
  setShowUpToIndex,
}: props) => {
  if (!data || data?.length === 0 || !nav) {
    return null;
  }

  const [rows, setRows] = useState<any[] | null>(null);
  const [gridNavigation, setGridNavigation] = useState<any>(null);
  const [axis, setNewAxis] = useState(0);
  const latestAxisRef = useLatest(axis);
  const latestOnFocusRef = useLatest(onFocus);
  const [focusTrail, setFocusTrail] = useState<any>({
    lastFocusedEl: nav.id,
    curFocusedEl: '',
  });

  const onItemBlur = useCallback(
    currentId => {
      if (focusTrail.lastFocusedEl !== nav.id) {
        return;
      }

      setFocusTrail({
        ...focusTrail,
        lastFocusedEl: currentId,
        curFocusedEl: focusManager.getCurrentFocus(),
      });
    },
    [focusTrail],
  );

  const onCustomPointerItemFocus = useCallback(() => {
    (document.activeElement as any).blur();
    const currentItem = focusManager.getCurrentFocus();

    const currentAxis = latestAxisRef.current;
    if (latestOnFocusRef.current) {
      latestOnFocusRef.current(currentItem);
    }

    if (!currentItem) {
      return;
    }

    let newAxis = currentAxis;

    if (isVertical) {
      newAxis = !isSpecialVertical
        ? getNewXAxis(currentItem)
        : getNewYAxis(currentItem);
      if (currentAxis !== newAxis) {
        setNewAxis(Math.min(newAxis, 0));
      }
    } else {
      if (animation) {
        verticalSmoothScroll(currentItem);
      } else {
        verticalScroll(currentItem);
      }
    }
  }, []);

  const onItemFocus = useCallback(() => {
    (document.activeElement as any).blur();
    const currentItem = focusManager.getCurrentFocus();

    const currentAxis = latestAxisRef.current;
    if (latestOnFocusRef.current) {
      latestOnFocusRef.current(currentItem);
    }

    if (!currentItem) {
      return;
    }

    if (setShowUpToIndex && !isNaN(showUpToIndexRef.current)) {
      const [, , tileIndex] = currentItem.split('-');
      const tileRow = Number(tileIndex.substring(0, tileIndex.length - 1));

      if (tileRow === showUpToIndexRef.current / 5 - 1) {
        setShowUpToIndex(showUpToIndexRef.current + 25);
      }
    }

    if (allowAnimation && xdk.system.getMouse().isMouseOn()) {
      return;
    }

    let newAxis = currentAxis;

    if (isVertical) {
      newAxis = !isSpecialVertical
        ? getNewXAxis(currentItem)
        : getNewYAxis(currentItem);
      if (currentAxis !== newAxis) {
        setNewAxis(Math.min(newAxis, 0));
      }
    } else {
      if (animation) {
        verticalSmoothScroll(currentItem);
      } else {
        verticalScroll(currentItem);
      }
    }
  }, []);

  const restoreFocusTrail = useCallback(() => {
    setFocusTrail({
      ...focusTrail,
      lastFocusedEl: nav.id,
      curFocusedEl: '',
    });
  }, []);

  useEffect(() => {
    if (isVertical) {
      //setNewAxis(0);
    }

    const rowsArray = isVertical
      ? createColumns(data, maxItemsRow)
      : createRows(data, maxItemsRow);

    setRows(rowsArray);
    setGridNavigation(
      isVertical
        ? createVerticalGridNavigation(rowsArray, nav, restoreFocusTrail)
        : createGridNavigation(rowsArray, nav, restoreFocusTrail),
    );
  }, [data]);

  useEffect(() => {
    if (gridNavigation && forwardFocus) {
      if (
        !gridNavigation.some(row => {
          return row.some(el => {
            return el.id === forwardFocus;
          });
        })
      ) {
        if (gridNavigation[0][0].id) {
          focusManager.changeFocus(gridNavigation[0][0].id);
        }
      }
    }
  }, [gridNavigation]);
  return (
    <>
      {gridNavigation && rows && (
        <FocusDiv
          nav={{
            ...nav,
            forwardFocus: forwardFocus || gridNavigation[0][0].id,
          }}
          className={`${
            isSpecialVertical
              ? styles['special-vertical-wrapper']
              : styles.wrapper
          } ${className}`}
        >
          {showDefault ? (
            <Rows
              isVertical={isVertical}
              isSpecialVertical={isSpecialVertical}
              axis={axis}
              rows={rows}
              pageId={pageId}
              nav={nav}
              gridNavigation={gridNavigation}
              component={component}
              classNameItemWrapper={classNameItemWrapper}
              onWrapperClick={onWrapperClick}
              onItemBlur={onItemBlur}
              onItemFocus={onItemFocus}
              animation={animation}
              customOnItemClick={customOnItemClick}
              customOnItemFocus={customOnItemFocus}
              isSegment={isSegment}
            />
          ) : (
            <GridVerticalScrollWrapper
              nav={nav}
              isSpecialVertical={isSpecialVertical}
              rows={rows}
              isVertical={isVertical}
              showArrows={showArrows}
              isSegment={isSegment}
              onCustomPointerItemFocus={onCustomPointerItemFocus}
            >
              <Rows
                isVertical={isVertical}
                isSpecialVertical={isSpecialVertical}
                axis={axis}
                rows={rows}
                pageId={pageId}
                nav={nav}
                gridNavigation={gridNavigation}
                component={component}
                classNameItemWrapper={classNameItemWrapper}
                onWrapperClick={onWrapperClick}
                onItemBlur={onItemBlur}
                onItemFocus={onItemFocus}
                animation={animation}
                customOnItemClick={customOnItemClick}
                customOnItemFocus={customOnItemFocus}
                isSegment={isSegment}
              />
            </GridVerticalScrollWrapper>
          )}
        </FocusDiv>
      )}
    </>
  );
};

export default memo(Grid);

const Rows = ({
  isVertical,
  isSpecialVertical,
  axis,
  rows,
  pageId,
  nav,
  gridNavigation,
  component,
  classNameItemWrapper,
  onWrapperClick,
  onItemBlur,
  onItemFocus,
  animation,
  customOnItemClick,
  customOnItemFocus,
  isSegment,
}) => {
  return (
    <div
      className={isVertical ? styles['vertical-wrapper'] : ''}
      style={{
        transform: `${
          isSpecialVertical ? `translateY(${axis}px)` : `translateX(${axis}px)`
        }`,
        transition: `${animation ? 'transform 280ms' : 'none'}`,
      }}
    >
      {rows.map((_, idx) => {
        return (
          <Row
            pageId={pageId}
            key={isVertical ? createColumnId(idx) : createRowId(idx)}
            nav={{
              id: isVertical ? createColumnId(idx) : createRowId(idx),
              parent: nav.id,
            }}
            isVertical={isVertical}
            itemsNav={gridNavigation[idx]}
            data={rows[idx]}
            component={component}
            classNameItemWrapper={classNameItemWrapper}
            onWrapperClick={onWrapperClick}
            onItemBlur={onItemBlur}
            onItemFocus={onItemFocus}
            customOnItemClick={customOnItemClick}
            customOnItemFocus={customOnItemFocus}
            rowIndex={idx}
            isSegment={isSegment}
          />
        );
      })}
    </div>
  );
};
