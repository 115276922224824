import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  ServiceFactory,
  DmcaService,
  LiveTimeService,
  MediaPlayerService,
  PlayheadTimestampService,
  MediaTimestampService,
  SeekService,
} from '../../servicelib';
import { SkipButtonService } from '../../sxmservicelayer/skip-button/skip-button.service';
import {
  getFFWKeyPressedId,
  getRewindKeyPressedId,
  getPlayPauseKeyPressedId,
  getStopKeyPressedId,
} from '../../redux/selector/xdk.store';
import {
  selectNowPlayingState,
  getIsLive,
  getIsVideo,
} from '../../redux/selector/now-playing.store';
import { useObservable } from '../../hooks';

const SECONDS_BEHIND_LIVEPOINT_CONSIDERED_LIVE = 15;
const skip15Interval = { id: undefined };

const SkippingTracker = ({ showUpNext }) => {
  /***** Handles the FFW key press logic for skipping - START *****/
  const isMounted = useRef(false);
  const isVideo = useSelector(getIsVideo);
  const mediaPlayerService = ServiceFactory.getInstance(
    MediaPlayerService,
  ) as MediaPlayerService;
  const dmcaService = ServiceFactory.getInstance(DmcaService) as DmcaService;
  const mediaTimestampService = ServiceFactory.getInstance(
    MediaTimestampService,
  ) as MediaTimestampService;
  const skipButtonService = ServiceFactory.getInstance(
    SkipButtonService,
  ) as SkipButtonService;
  const seekService = ServiceFactory.getInstance(SeekService) as SeekService;
  const liveTimeService = ServiceFactory.getInstance(
    LiveTimeService,
  ) as LiveTimeService;
  const playheadTimestampService = ServiceFactory.getInstance(
    PlayheadTimestampService,
  ) as PlayheadTimestampService;

  const liveTime = useObservable(liveTimeService.liveTime$) as any;
  const playheadTimestampForLive = useObservable(
    playheadTimestampService.playhead,
  );
  const nowInMs = moment().valueOf();
  const liveDeltaInSeconds =
    (nowInMs - (liveTime ? liveTime.zuluMilliseconds : 0)) / 1000;
  const livePositionInSeconds =
    mediaPlayerService?.mediaPlayer?.videoPlayer?.videoElement?.duration ||
    18450;
  const livePointToSeekTo = livePositionInSeconds - liveDeltaInSeconds;

  const nowPlayingStore = useSelector(selectNowPlayingState);
  skipButtonService.nowPlayingStore = nowPlayingStore;
  const isLive = useSelector(getIsLive);
  const isDisallowed = dmcaService.isDisallowed(nowPlayingStore);
  const isRestricted = dmcaService.isRestricted(nowPlayingStore);

  useEffect(() => {
    if (mediaPlayerService?.mediaPlayer) {
      const subscription = mediaPlayerService.mediaPlayer.playbackComplete$.subscribe(
        isPlaybackComplete => {
          if (isPlaybackComplete) {
            clearInterval(skip15Interval.id);
            skip15Interval.id = undefined;
          }
        },
      );
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [mediaPlayerService && mediaPlayerService.mediaPlayer]);

  let isSkipForward15SecondsDisabled = true;

  if (isLive) {
    isSkipForward15SecondsDisabled =
      isDisallowed ||
      isRestricted ||
      livePointToSeekTo -
        ((playheadTimestampForLive as any)?.currentTime.seconds || 0) <=
        SECONDS_BEHIND_LIVEPOINT_CONSIDERED_LIVE;
  } else {
    //For AOD, mediaTimestampService.livePointTimestamp will be the actual length of the media.
    //mediaTimestampService.playheadTimestamp will represent the actual playhead position
    isSkipForward15SecondsDisabled =
      isDisallowed ||
      isRestricted ||
      mediaTimestampService.livePointTimestamp -
        mediaTimestampService.playheadTimestamp <=
        SECONDS_BEHIND_LIVEPOINT_CONSIDERED_LIVE;
  }

  const onSkipForward15 = () => {
    let seconds = 0;

    if (isLive) {
      seconds =
        ((playheadTimestampForLive as any)?.currentTime.seconds || 0) + 15 <
        livePointToSeekTo
          ? ((playheadTimestampForLive as any)?.currentTime.seconds || 0) + 15
          : livePointToSeekTo;
    } else {
      seconds =
        mediaTimestampService.playheadTimestamp + 15 <
        mediaTimestampService.durationTimestamp
          ? mediaTimestampService.playheadTimestamp + 15
          : mediaTimestampService.durationTimestamp;

      if (seconds === mediaTimestampService.durationTimestamp) {
        clearInterval(skip15Interval.id);
        skip15Interval.id = undefined;
      }
    }
    seekService.seekThenPlay(seconds, false);
  };

  const ffwId = useSelector(getFFWKeyPressedId);
  useEffect(() => {
    if (isMounted.current && !showUpNext && !isVideo) {
      if (!isSkipForward15SecondsDisabled && !skip15Interval.id) {
        skip15Interval.id = setInterval(() => {
          onSkipForward15();
        }, 1000);
      }
    }

    return () => {
      clearInterval(skip15Interval.id);
      skip15Interval.id = undefined;
    };
  }, [ffwId]);

  /***** Handles the FFW key press logic for skipping - END *****/

  /***** Handles the REWIND key press logic for skipping - START *****/
  const isSkipBackward15SecondsDisabled = isDisallowed || isRestricted;

  const onSkipBackward15 = () => {
    const seconds =
      mediaTimestampService.playheadTimestamp > 15
        ? mediaTimestampService.playheadTimestamp - 15
        : 0;

    if (seconds === 0) {
      clearInterval(skip15Interval.id);
      skip15Interval.id = undefined;
    }

    seekService.seekThenPlay(seconds, false);
  };

  const rewindId = useSelector(getRewindKeyPressedId);
  useEffect(() => {
    if (isMounted.current && !showUpNext && !isVideo) {
      if (!isSkipBackward15SecondsDisabled && !skip15Interval.id) {
        skip15Interval.id = setInterval(() => {
          onSkipBackward15();
        }, 1000);
      }
    }

    return () => {
      clearInterval(skip15Interval.id);
      skip15Interval.id = undefined;
    };
  }, [rewindId]);

  /***** Handles the REWIND key press logic for skipping - END *****/

  /***** Handles the PLAY/PAUSE key press logic - START *****/

  const playPauseId = useSelector(getPlayPauseKeyPressedId);
  useEffect(() => {
    if (isMounted.current && !showUpNext && !isVideo) {
      mediaPlayerService?.mediaPlayer?.togglePausePlay().subscribe();
    }
  }, [playPauseId]);

  /***** Handles the PLAY/PAUSE key press logic - END *****/

  /***** Handles the STOP key press logic - START *****/

  const stopId = useSelector(getStopKeyPressedId);
  useEffect(() => {
    if (isMounted.current && !showUpNext && !isVideo) {
      mediaPlayerService?.mediaPlayer?.pause().subscribe();
    } else {
      isMounted.current = true;
    }
  }, [stopId]);

  /***** Handles the STOP key press logic - END *****/

  /* Handles the logic to immediately pause the video once playback is ready if the user moves outside the Now Playing screen
   * while the video was still initially loading or skipping (spinner still has not been dismissed)
   */

  useEffect(() => {
    if (isVideo) {
      const previousPlaybackState = { state: undefined };

      const playbackReadySubscription = mediaPlayerService.mediaPlayer.playbackReady$.subscribe(
        isPlaybackReady => {
          if (isPlaybackReady) {
            mediaPlayerService.mediaPlayer.pause().subscribe();
          }
        },
      );

      const seekedSubscription = mediaPlayerService.mediaPlayer.seeked$.subscribe(
        isSeeking => {
          if (isSeeking) {
            previousPlaybackState.state = isSeeking;
          }
        },
      );

      const playbackStateSubscription = mediaPlayerService.mediaPlayer.playbackStateSubject.subscribe(
        playbackState => {
          if (playbackState) {
            if (
              previousPlaybackState.state === 'SEEKED' &&
              playbackState === 'PLAYING'
            ) {
              mediaPlayerService.mediaPlayer.pause().subscribe();
            }
          }
        },
      );

      return () => {
        playbackReadySubscription && playbackReadySubscription.unsubscribe();
        seekedSubscription && seekedSubscription.unsubscribe();
        playbackStateSubscription && playbackStateSubscription.unsubscribe();
        previousPlaybackState.state = undefined;
      };
    }
  }, []);

  return <div></div>;
};

export default SkippingTracker;
