import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { focusManager } from '@accedo/vdkweb-navigation';
import { RoundedButton } from '../../components/rounded-button';
import { appRouteConstants } from '../../routing/app.route.constants';
import './open-access.component.scss';
import { useSelector } from 'react-redux';
import { getPageBackId } from '../../redux/selector/xdk.store';
import { translate } from '../../utils/translate.util';

const okButtonNav = {
  id: 'ok-button',
};

export const OpenAccessExpiredPageComponent = () => {
  const history = useHistory();

  const isMounted = useRef(false);
  const backId = useSelector(getPageBackId);
  useEffect(() => {
    if (isMounted.current) {
      history.replace(appRouteConstants.AUTH.LOGIN);
    } else {
      isMounted.current = true;
    }
  }, [backId]);

  useEffect(() => {
    focusManager.changeFocus(okButtonNav.id);
  }, []);

  const okOnClick = () => {
    history.replace(appRouteConstants.AUTH.LOGIN);
  };

  return (
    <div className="open-access-expired-page">
      <div className="open-access-expired-content-container">
        <div className="open-access-expired-text-container">
          <span className="free-trial-expired-title">
            Subscribe to the SXM app <br />
            to keep listening
          </span>
          <span className="free-trial-ended">Your free access has ended.</span>
          <span className="subtitle-line-1">
            Visit{' '}
            <span className="subscribe-url">
              {translate('freeTier.expirationLink')}
            </span>
          </span>
          <span className="subtitle-line-2">to subscribe to the SXM App.</span>
          <span className="free-trial-already-a-subscriber-line">
            Already a subscriber or have a trial in your car?
          </span>
        </div>
      </div>
      <div className="open-access-expired-button-container">
        <RoundedButton
          type="primary"
          className="ok-button"
          nav={okButtonNav}
          onClick={okOnClick}
          text="OK Button"
        >
          Sign In
        </RoundedButton>
      </div>
    </div>
  );
};
