import {ReactComponent as SearchIconOn} from '../../assets/images/srch-on.svg';
import {ReactComponent as SearchResultPlay} from '../../assets/images/search-result-play.svg';

export const itemType = {
  SEARCH_ITEM: 'SEARCH_ITEM',
  CLEAR_ITEM: 'CLEAR_ITEM',
  VIDEO_ITEM: 'VIDEO_ITEM',
};

type SearchItem = {
  src?: any;
  text: string;
  type?: string;
  onClick?: () => void;
  payload?: any;
};

export const parseSearchResults = (data: any[]): SearchItem[] => {
  return data.map(item => {
    return {
      src: SearchIconOn,
      text: item.searchString || item.suggestion,
      type: itemType.SEARCH_ITEM,
    };
  });
};

export const parseTuneResults = (data: any[]): SearchItem[] => {
  return data.map(item => {
    return {
      src: SearchResultPlay,
      text: item.name,
      type: itemType.VIDEO_ITEM,
      payload: item,
    };
  });
};
