import React, { useEffect } from 'react';
import { FocusButton } from '@accedo/vdkweb-tv-ui';
import { ReactComponent as PlayIcon } from '../../assets/images/play.svg';
import { ReactComponent as SkipForward } from '../../assets/images/skip-forward-white.svg';
import { ReactComponent as SkipBackward } from '../../assets/images/skip-back-white.svg';
import { ReactComponent as SkipForward15Seconds } from '../../assets/images/forward-15.svg';
import { ReactComponent as SkipBackward15Seconds } from '../../assets/images/back-15.svg';
const theme = {
  button: 'play-button',
  buttonFocused: 'play-button-focused',
};
const PlayerButton = props => {
  const {
    isPaused,
    showSkipIcon,
    skipLeft15ButtonNav,
    isSkipBackward15SecondsDisabled,
    handleLeftBtn,
    SKIP_BACKWARD_15_SECONDS_ICON,
    isSkipBackwardDisabled,
    SKIP_BACKWARD_ICON,
    skipButtonService,
    backgroundDuration,
    playButtonNav,
    skipLeftButtonNav,
    isSkipForwardDisabled,
    isSkipForward15SecondsDisabled,
    skipRightButtonNav,
    skipRight15ButtonNav,
    edpInformationDrawerNav,
    relatedContentNav,
    handlePlay,
    handleRightBtn,
    SKIP_FORWARD_15_SECONDS_ICON,
    SKIP_FORWARD_ICON,
    // isSkipText,
    // isPausePlayClicked,
    isPlayerButtonActive,
  } = props;
  useEffect(() => {
    if (isPaused || showSkipIcon.icon) {
      isPlayerButtonActive.current = true;
    } else {
      isPlayerButtonActive.current = false;
    }
  }, [isPaused, showSkipIcon.icon]);
  return (
    <>
      {isPaused || showSkipIcon.icon ? (
        <div className="playback-icon-container loaded">
          <div className="hoverCenter">
            <div>
              <div
                className="play-button-div"
                style={{
                  display:
                    isPaused || showSkipIcon.icon === SKIP_BACKWARD_ICON
                      ? 'block'
                      : 'none',
                  width: !isPaused ? '100%' : null,
                }}
              >
                <FocusButton
                  nav={{
                    ...skipLeft15ButtonNav,
                    skip: isSkipBackward15SecondsDisabled,
                  }}
                  theme={theme}
                  onClick={() => handleLeftBtn(true)}
                  style={{
                    display:
                      showSkipIcon.icon === SKIP_BACKWARD_15_SECONDS_ICON ||
                      !isSkipBackward15SecondsDisabled
                        ? 'block'
                        : 'none',
                  }}
                >
                  <SkipBackward15Seconds />
                </FocusButton>
                <FocusButton
                  nav={{
                    ...skipLeftButtonNav,
                    skip:
                      !isSkipBackward15SecondsDisabled &&
                      isSkipBackwardDisabled,
                  }}
                  theme={theme}
                  onClick={() => handleLeftBtn(false)}
                  style={{
                    visibility:
                      showSkipIcon.icon === SKIP_BACKWARD_ICON ||
                      (!isSkipBackwardDisabled &&
                        isSkipBackward15SecondsDisabled)
                        ? 'visible'
                        : 'hidden',
                    display: !isPaused ? 'flex' : 'block',
                    justifyContent: !isPaused ? 'center' : null,
                  }}
                >
                  <SkipBackward />
                </FocusButton>
                {skipButtonService.displayMessage &&
                  backgroundDuration.current === 'left' &&
                  showSkipIcon.icon && (
                    <div
                      className="now-playing-skips-remaining"
                      style={{ maxWidth: !isPaused ? 'none' : '7.75rem' }}
                    >
                      {skipButtonService.getSkipMessageText()}
                    </div>
                  )}
              </div>
              {isPaused && (
                <FocusButton
                  nav={{
                    id: playButtonNav.id,
                    nextleft:
                      !isSkipBackwardDisabled && isSkipBackward15SecondsDisabled
                        ? skipLeftButtonNav.id
                        : !isSkipBackward15SecondsDisabled
                        ? skipLeft15ButtonNav.id
                        : '',
                    nextright:
                      !isSkipForwardDisabled && isSkipForward15SecondsDisabled
                        ? skipRightButtonNav.id
                        : !isSkipForward15SecondsDisabled
                        ? skipRight15ButtonNav.id
                        : '',
                    nextdown: edpInformationDrawerNav.id,
                    nextup: relatedContentNav.id,
                  }}
                  theme={theme}
                  onClick={handlePlay}
                >
                  <PlayIcon />
                </FocusButton>
              )}
              <div
                className="play-button-div"
                style={{
                  display:
                    isPaused || showSkipIcon.icon === SKIP_FORWARD_ICON
                      ? 'block'
                      : 'none',
                  width: !isPaused ? '100%' : null,
                }}
              >
                <FocusButton
                  nav={{
                    ...skipRight15ButtonNav,
                    skip: isSkipForward15SecondsDisabled,
                  }}
                  theme={theme}
                  onClick={() => handleRightBtn(true)}
                  style={{
                    display:
                      showSkipIcon.icon === SKIP_FORWARD_15_SECONDS_ICON ||
                      !isSkipForward15SecondsDisabled
                        ? 'block'
                        : 'none',
                  }}
                >
                  <SkipForward15Seconds />
                </FocusButton>
                <FocusButton
                  nav={{
                    ...skipRightButtonNav,
                    skip:
                      !isSkipForward15SecondsDisabled && isSkipForwardDisabled,
                  }}
                  theme={theme}
                  onClick={() => handleRightBtn(false)}
                  style={{
                    visibility:
                      showSkipIcon.icon === SKIP_FORWARD_ICON ||
                      (!isSkipForwardDisabled && isSkipForward15SecondsDisabled)
                        ? 'visible'
                        : 'hidden',
                    display: !isPaused ? 'flex' : 'block',
                    justifyContent: !isPaused ? 'center' : null,
                  }}
                >
                  <SkipForward />
                </FocusButton>
                {skipButtonService.displayMessage &&
                  backgroundDuration.current === 'right' &&
                  showSkipIcon.icon && (
                    <div
                      className="now-playing-skips-remaining"
                      style={{ maxWidth: !isPaused ? 'none' : '7.75rem' }}
                    >
                      {skipButtonService.getSkipMessageText()}
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default PlayerButton;
