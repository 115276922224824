import VerticalScroll from '../../vertical-scroll/verticalScroll';
import React from 'react';

const GridVerticalScrollWrapper = ({
  children,
  nav,
  isSpecialVertical,
  rows,
  isVertical,
  showArrows,
  isSegment,
  onCustomPointerItemFocus,
}) => {
  return (
    <VerticalScroll
      nav={nav}
      items={
        isSpecialVertical
          ? isSegment
            ? rows[0].map((_, idx) => `GRID-ITEM-${idx < 10 ? '0' + idx : idx}`)
            : rows[0].map((_, idx) => `GRID_ITEM-${idx < 10 ? '0' + idx : idx}`)
          : isVertical
          ? rows.map((_, idx) => `COLUMN-${idx}`)
          : rows.map((_, idx) => `ROW-${idx}`)
      }
      showArrows={showArrows}
      isVertical={isVertical}
      isSpecialVertical={isSpecialVertical}
      onCustomPointerItemFocus={onCustomPointerItemFocus}
    >
      {children}
    </VerticalScroll>
  );
};
export default GridVerticalScrollWrapper;
