import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  CarouselPageParameter,
  SearchService,
  ServiceFactory,
} from '../../servicelib';
import { focusManager } from '@accedo/vdkweb-navigation';
import {
  getLastFocusedTileId,
  getPageBackId,
} from '../../redux/selector/xdk.store';
import { SEARCH } from '../../utils/navigationMap';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import styles from './search-page.module.scss';
import { CarouselStoreService } from '../../sxmservicelayer/carousel-store/carousel.store.service';
import { ReactComponent as SearchIcon } from '../../assets/images/srch-on-blue.svg';
import Input from '../../components/input/input';
import Swimlane from '../../components/swimlane/Swimlane';
import ChannelTile from '../../components/tiles/ChannelTile';
import Modal from '../../components/Modal/Modal';
import { selectSearchCarousel as selectSeachStore } from '../../redux/selector/carousel.store';
import { saveLastFocusedTileId } from '../../redux/action/xdk.action';
import { verticalScroll } from '../../utils/pageUtils';
import VerticalScroll from '../../vertical-scroll/verticalScroll';

const { RESULT_PAGE, CONTAINERS } = SEARCH;

const navMap = {
  PAGE: {
    id: RESULT_PAGE,
  },
  CONTAINERS: {
    id: CONTAINERS,
    parent: RESULT_PAGE,
  },
};

type Params = {
  keyword: string;
};

export const SearchResultComponent = () => {
  const { keyword } = useParams<Params>();
  const parsedKeyword = decodeURIComponent(keyword);
  const [navIds, setNavIds] = useState({});
  const [searchData, setSearchData] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [animation, setAnimation] = useState(true);
  const [carouselItems, setCarouselItems] = useState([]);
  const dispatch = useDispatch();

  const searchService = ServiceFactory.getInstance(
    SearchService,
  ) as SearchService;

  const carouselStoreService = ServiceFactory.getInstance(
    CarouselStoreService,
  ) as CarouselStoreService;

  /** Handles the BACK button navigation logic **/
  const history = useHistory();
  const backId = useSelector(getPageBackId);

  useUpdateEffect(() => {
    history.goBack();
  }, [backId]);

  /* Reestablished the focused to the last focused tile before the page was exited */
  const lastFocusedTile = useSelector(getLastFocusedTileId);

  useEffect(() => {
    if (history.action === 'POP') {
      setAnimation(false);
    }

    if (lastFocusedTile[navMap.PAGE.id]) {
      focusManager.changeFocus(lastFocusedTile[navMap.PAGE.id]);
      dispatch(
        saveLastFocusedTileId({ ...lastFocusedTile, [navMap.PAGE.id]: null }),
      );
    }

    setTimeout(() => {
      setAnimation(true);
    }, 1000);
  }, [history.location]);

  const searchCarousel = useSelector(selectSeachStore);

  useEffect(() => {
    if (keyword && keyword !== '') {
      searchService.getSearchResults(decodeURIComponent(keyword));

      const params: Array<CarouselPageParameter> = [];
      params.push({
        paramName: 'search-term',
        paramValue: decodeURIComponent(keyword),
      });

      carouselStoreService.selectSearch(params);
    }
  }, [keyword]);

  useEffect(() => {
    if (searchCarousel) {
      if (
        searchCarousel.zone?.length > 0 &&
        searchCarousel.zone?.[0]?.content &&
        searchCarousel.zone?.[0]?.content.length > 0
      ) {
        const ids = [];
        searchCarousel.zone?.[0]?.content.forEach(zone => {
          ids.push(zone.guid);
        });

        const navs = {};
        const carousels = [];
        ids.forEach((id, index) => {
          carousels.push(id);
          navs[id] = {
            id: id,
            nextup: ids[index - 1] || undefined,
            nextdown: ids[index + 1],
          };
        });

        setNavIds(navs);
        setSearchData(searchCarousel);
        setCarouselItems(carousels);

        if (
          history.action === 'PUSH' &&
          ids[0] &&
          !lastFocusedTile[navMap.PAGE.id]
        ) {
          focusManager.changeFocus(`${ids[0]}-ITEM-0`);
        }
      } else {
        setHasError(true);
      }
    }
  }, [searchCarousel]);

  return (
    <div id={navMap.PAGE.id} className={'home-page'}>
      <Modal
        title={`No results found for "${parsedKeyword}"`}
        text="Try searching again by channels, shows, topics, hosts, or guests."
        show={hasError}
        actions={[
          {
            label: 'Dismiss',
            action: () => history.goBack(),
          },
        ]}
      />
      <VerticalScroll
        nav={navIds}
        items={carouselItems}
        parent={navIds['page']?.id}
        navBar={() => document.querySelector('.menu-wrapper')?.clientHeight}
      >
        <div
          className={`${styles['zone-menu']} ${styles['zone-menu-bottom-space']}`}
        >
          <>
            <div className={styles['zone-menu-icon-container']}>
              <SearchIcon className={styles['zone-menu-icon']} />
            </div>
            <Input
              className={styles['zone-menu-label']}
              value={parsedKeyword}
              disabled
            />
          </>
        </div>
        <div>
          {searchData &&
            searchData?.zone?.[0]?.content &&
            searchData?.zone?.[0]?.content.map(contentCarousel => {
              return (
                <Swimlane
                  pageId={navMap.PAGE.id}
                  key={contentCarousel.guid}
                  nav={navIds[contentCarousel.guid]}
                  className={'home-hero-carousel-wrapper'}
                  headerClassName={'carousel-categories-header-top'}
                  itemWrapperClassName={'channel-wrapper'}
                  data={contentCarousel.tiles}
                  component={ChannelTile}
                  displayText={contentCarousel.title?.textValue}
                  animation={animation}
                  slideCount={4}
                  onFocus={() => {
                    if (!animation) {
                      verticalScroll(contentCarousel.guid);
                    }
                  }}
                />
              );
            })}
        </div>
      </VerticalScroll>
    </div>
  );
};
