import config from './@accedo/xdk-device-x1-rdk/entry';
const COOKIE_ONLY = {
  cookieName: 'sirius-xm-x1-cloud-cookie',
  storages: [
    {
      id: 'device/storage/Cookie',
      'type:': 'cookie',
    },
  ],
};
const CONFIG = {
  devices: {
    packages: [
      {
        id: 'x1',
        detection: config.detection,
        defaultConfig: () =>
          import('@accedo/xdk-device-workstation/esm/defaultConfig.js'),
        DevicePackage: () =>
          import('@accedo/xdk-device-workstation/esm/DevicePackage.js'),
      },
      {
        id: 'workstation',
        detection: () =>
          import('@accedo/xdk-device-workstation/esm/detection.js'),
        defaultConfig: () =>
          import('@accedo/xdk-device-workstation/esm/defaultConfig.js'),
        DevicePackage: () =>
          import('@accedo/xdk-device-workstation/esm/DevicePackage.js'),
      },
      {
        id: 'lg-webos',
        detection: () => import('@accedo/xdk-device-lg-webos/esm/detection.js'),
        defaultConfig: () =>
          import('@accedo/xdk-device-lg-webos/esm/defaultConfig.js'),
        DevicePackage: () =>
          import('@accedo/xdk-device-lg-webos/esm/DevicePackage.js'),
      },
      {
        id: 'tizen',
        detection: () =>
          import('@accedo/xdk-device-samsung-tizen/esm/detection.js'),
        defaultConfig: () =>
          import('@accedo/xdk-device-samsung-tizen/esm/defaultConfig.js'),
        DevicePackage: () =>
          import('@accedo/xdk-device-samsung-tizen/esm/DevicePackage.js'),
      },
      {
        id: 'vizio-smartcast',
        detection: () =>
          import('@accedo/xdk-device-vizio-smartcast-contrib/esm/detection.js'),
        defaultConfig: () =>
          import(
            '@accedo/xdk-device-vizio-smartcast-contrib/esm/defaultConfig.js'
          ),
        DevicePackage: () =>
          import(
            '@accedo/xdk-device-vizio-smartcast-contrib/esm/DevicePackage.js'
          ),
      },
    ],
    details: {
      workstation: COOKIE_ONLY,
      x1: COOKIE_ONLY,
      webos: COOKIE_ONLY,
      'samsung-tizen': COOKIE_ONLY,
      'lg-webos': {
        type: 'local',
      },
    },
  },
  logging: {
    level: -1,
  },
};

export default CONFIG;
