import React, { useEffect, useState, useRef } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { distinctUntilChanged } from 'rxjs/operators';
import xdk from '@accedo/xdk-core';
import { focusManager } from '@accedo/vdkweb-navigation';
import { pageRedux, setPageDefaultActions } from '@accedo/vdkweb-tv-ui';

import { ProtectedRoute } from './routing/protected-route.component';
import { SplashPageComponent } from './pages/splash/splash-page.component';
import { WelcomePageComponent } from './pages/welcome/welcome-page.component';
import { LoginPageComponent } from './pages/login/login-page.component';
import { LoginConfirmationPageComponent } from './pages/login/login-confirmation.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { OpenAccessConfirmationPageComponent } from './pages/open-access/open-access-confirmation.component';
import { OpenAccessExpiredPageComponent } from './pages/open-access/open-access-expired.component';
import { AllChannelsPage } from './pages/all-channels-page/all-channels.component';
import { NowPlayingPageComponent } from './pages/now-playing/now-playing.component';
import { PrivacyPolicyScreen } from './pages/privacy-policy/privacy-policy.component';
import { ActivateDeviceComponent } from './pages/activate-device/activate-device.component';
import { appRouteConstants } from './routing/app.route.constants';

import { NavigationMenu } from './components/navigation-menu/navigation-menu.component';

import './preflight';
import './App.scss';
import { SearchPageComponent } from './pages/search-page/search-page.component';
import { SearchResultComponent } from './pages/search-page/search-result-page.component';
import { SettingsPageComponent } from './pages/settings-page/settings-page.component';
import { CategoryPage } from './pages/category-page/category.component';
import { useAuth } from './auth/provide-auth';

import {
  ServiceFactory,
  StorageService,
  StorageKeyConstant,
  SettingsConstants,
  VideoPlayerService,
  SessionMonitorService,
  APP_ERROR_STATE_CONST,
  AuthenticationService,
  BypassMonitorService,
  MediaPlayerService,
  SettingsService,
} from './servicelib';
import { NavigationService } from './sxmservicelayer/navigation/navigation.service';
import { ViewAllPage } from './pages/view-all-page.tsx/view-all.component';
import { CollectionPage } from './pages/collection-page/collection.component';
import { EpisodeListingPage } from './pages/episode-listing-page/episode-listing.component';
import {
  pageRight,
  pageLeft,
  keyPressed,
  ffwKeyPressed,
  rewindKeyPressed,
  playPauseKeyPressed,
  stopKeyPressed,
  doubleTapRight,
  doubleTapLeft,
} from './redux/action/xdk.action';
import { PodcastsVideosPageComponent } from './pages/podcasts-videos/podcasts-videos-page.component';
import { FavoritesPageComponent } from './pages/favorites-page/favorites-page.component';
import { RecentPageComponent } from './pages/recent-page/recent-page.component';
import { EnhancedEdpPage } from './pages/enhanced-edp-page/enhanced-edp-page.component';
import { HandleAutoplayOnStartup } from './components/handle-autoplay-on-startup';
import { InactivityTracker } from './components/inactivity-tracker';
import Modal from './components/Modal/Modal';
import UpNext from './components/up-next/up-next.component';
import { SkippingTracker } from './components/skipping-tracker';
import { MENU, NOW_PLAYING, SEARCH } from './utils/navigationMap';
import FreeTrialTracker from './components/free-trial-counter/free-trial-tracker.component';
import { WelcomeVideoComponent } from './components/welcome-video/welcome-video.component';

const VIDEO_WRAPPER_ID = 'video-wrapper';
const AUTOPLAY_STATES = {
  INITIALIZATION: 'INITIALIZATION',
  AUTOPLAY: 'AUTOPLAY',
  COMPLETE: 'COMPLETE',
};
const unauhtenticatedPaths = [
  appRouteConstants.FT_WELCOME,
  appRouteConstants.AUTH.LOGIN,
  appRouteConstants.AUTH.LOGIN_CONFIRMATION,
  appRouteConstants.AUTH.OPEN_ACCESS_CONFIRMATION,
  appRouteConstants.AUTH.OPEN_ACCESS_EXPIRED,
  appRouteConstants.ACTIVATE_DEVICE,
  appRouteConstants.PRIVACY_POLICY,
];
const exitPathBackButton = [
  appRouteConstants.AUTH.LOGIN_CONFIRMATION,
  appRouteConstants.AUTH.OPEN_ACCESS_CONFIRMATION,
  appRouteConstants.FT_UPSELL,
  appRouteConstants.SUBSCRIBE_SCREEN,
  appRouteConstants.EXPLORE,
  appRouteConstants.ACCESS_NOW,
  appRouteConstants.AUTH.FRICTIONLESS,
];

const welcomeNav = [
  'CORE_MENU',
  'qr-free-button-nav',
  'welcome-sign-in-button',
  'free-trial-button',
  'privacy-policy-button',
];

let hasSXMPlayerInitialized = false;

const initXdk = async (
  callback: (message: string) => void,
  history: any,
  dispatch: any,
  setNumberPressed: any,
  retrieveExitAppPopup: any,
  switchShowExitAppPopup: any,
  setModalData: any,
  EXIT_MODAL_DATA: any,
) => {
  await xdk.load();
  const platform = xdk.system.getDeviceType();

  console.log(`XDK is loaded in the platform: ${platform}`);
  callback(`XDK is loaded in the platform: ${platform}`);

  const { actions: pageActions } = pageRedux;
  const {
    UP,
    DOWN,
    LEFT,
    RIGHT,
    OK,
    BACK,
    EXIT,
    FF,
    RW,
    PLAY,
    PAUSE,
    PLAY_PAUSE,
    STOP,
    KEY_0,
    KEY_1,
    KEY_2,
    KEY_3,
    KEY_4,
    KEY_5,
    KEY_6,
    KEY_7,
    KEY_8,
    KEY_9,
  } = xdk.CONSTANT.KEY;
  const KEY_EVENT_PREFIX = 'device:vkey:';
  focusManager.setPersistTrail(true);
  let doubleTapTimeoutId = null;

  setPageDefaultActions({
    backAction: () => {
      history.goBack();
      const exitAppPopupValue = retrieveExitAppPopup();
      if (
        !exitAppPopupValue &&
        focusManager.getCurrentFocus() !== NOW_PLAYING.ID &&
        (exitPathBackButton.includes(history.location.pathname) ||
          welcomeNav.includes(focusManager.getCurrentFocus()) ||
          focusManager.isChildFocused(MENU.CORE_MENU))
      ) {
        switchShowExitAppPopup(true);
        setModalData(EXIT_MODAL_DATA);
      } else {
        switchShowExitAppPopup(false);
        history.goBack();
      }
    },
    exitAction: () => {
      try {
        window.close();
      } catch (e) {
        console.warn(e);
      }
    },
  });

  xdk.environment.addEventListener(
    xdk.environment.SYSTEM.KEYDOWN,
    ({ id, text }) => {
      switch (id) {
        case UP.id:
        case DOWN.id:
        case LEFT.id:
        case RIGHT.id: {
          const direction = `next${id.substring(KEY_EVENT_PREFIX.length)}`;
          focusManager.navigateFocus(direction);
          dispatch(keyPressed());

          if (history.location.pathname === appRouteConstants.NOW_PLAYING) {
            //Dispatches actions so the right and left key double tap presses for skipping
            //can be detected in the Now Playing screen
            if (id === LEFT.id) {
              if (doubleTapTimeoutId === null) {
                doubleTapTimeoutId = setTimeout(() => {
                  dispatch(pageLeft());
                  doubleTapTimeoutId = null;
                }, 350);
              } else {
                clearTimeout(doubleTapTimeoutId);
                doubleTapTimeoutId = null;
                dispatch(doubleTapLeft());
              }
            } else if (id === RIGHT.id) {
              if (doubleTapTimeoutId === null) {
                doubleTapTimeoutId = setTimeout(() => {
                  dispatch(pageRight());
                  doubleTapTimeoutId = null;
                }, 350);
              } else {
                clearTimeout(doubleTapTimeoutId);
                doubleTapTimeoutId = null;
                dispatch(doubleTapRight());
              }
            }
          }

          break;
        }

        case OK.id:
          focusManager.click();
          dispatch(keyPressed());
          break;
        case BACK.id: {
          // Connect BACK KeyDown to dispatch pageAction
          const exitAppPopupValue = retrieveExitAppPopup();
          if (
            !exitAppPopupValue &&
            focusManager.getCurrentFocus() !== NOW_PLAYING.ID &&
            (exitPathBackButton.includes(history.location.pathname) ||
              welcomeNav.includes(focusManager.getCurrentFocus()) ||
              focusManager.isChildFocused(MENU.CORE_MENU))
          ) {
            switchShowExitAppPopup(true);
            setModalData(EXIT_MODAL_DATA);
          } else {
            if (
              !(
                exitAppPopupValue &&
                history.location.pathname.includes(appRouteConstants.AUTH.LOGIN)
              )
            ) {
              dispatch(pageActions.pageBack());
            }

            switchShowExitAppPopup(false);
            dispatch(keyPressed());
          }
          break;
        }
        case EXIT.id:
          // Connect EXIT KeyDown to dispatch pageAction
          dispatch(pageActions.pageExit());
          setModalData(EXIT_MODAL_DATA);
          break;
        case FF.id:
          dispatch(keyPressed());
          dispatch(ffwKeyPressed());
          break;
        case RW.id:
          dispatch(keyPressed());
          dispatch(rewindKeyPressed());
          break;
        case PLAY.id:
        case PAUSE.id:
        case PLAY_PAUSE.id:
          dispatch(keyPressed());
          dispatch(playPauseKeyPressed());
          break;
        case STOP.id:
          dispatch(keyPressed());
          dispatch(stopKeyPressed());
          break;

        case KEY_0.id:
        case KEY_1.id:
        case KEY_2.id:
        case KEY_3.id:
        case KEY_4.id:
        case KEY_5.id:
        case KEY_6.id:
        case KEY_7.id:
        case KEY_8.id:
        case KEY_9.id:
          if (
            focusManager.getCurrentFocus() === SEARCH.KEYBOARD ||
            focusManager.isChildFocused(SEARCH.KEYBOARD)
          ) {
            setNumberPressed({ text });
          }
          break;
        default:
          break;
      }
    },
  );
};

const App = ({ kochava }) => {
  const [, setMessage] = useState('Loading XDK...');
  const [dismissSplashScreen, shouldDismissSplashScreen] = useState(false);
  const [isCoreMenuOpen, setIsCoreMenuOpen] = useState(true);
  const [autoplayOnStartup, setAutoplayOnStartup] = useState(
    AUTOPLAY_STATES.INITIALIZATION,
  );
  const [hasError, setHasError] = useState(false);
  const [isOnFreeTrial, setIsOnFreeTrial] = useState(false);
  const [, setRand] = useState(0);
  const [numberPressed, setNumberPressed] = useState({ text: '' });
  const showExitAppPopup = useRef(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const SIMULTANEOUS_LISTENING_MODAL_DATA = {
    title:
      'Only one device can be listening at a time and another is logged in.',
    text: 'Would you like to continue on this device?',
    actions: [
      {
        label: 'Continue',
        action: () => {
          const authenticationService = ServiceFactory.getInstance(
            AuthenticationService,
          ) as AuthenticationService;
          const mediaPlayerService = ServiceFactory.getInstance(
            MediaPlayerService,
          ) as MediaPlayerService;
          mediaPlayerService?.mediaPlayer?.resume().subscribe();
          authenticationService.reclaimSession();
          setHasError(false);
        },
      },
      {
        label: 'Cancel',
        action: () => {
          window.close();
          setHasError(false);
        },
      },
    ],
  };
  const [modalData, setModalData] = useState(SIMULTANEOUS_LISTENING_MODAL_DATA);
  useEffect(() => {
    if (dismissSplashScreen) {
      const sessionMonitorService = ServiceFactory.getInstance(
        SessionMonitorService,
      ) as SessionMonitorService;
      const mediaPlayerService = ServiceFactory.getInstance(
        MediaPlayerService,
      ) as MediaPlayerService;

      sessionMonitorService.appErrorState.subscribe({
        next: payload => {
          if (
            payload.IS_SIMULTANEOUS_LISTEN ===
            APP_ERROR_STATE_CONST.SIMULTANEOUS_LISTEN_ENABLED
          ) {
            mediaPlayerService?.mediaPlayer?.pause().subscribe();
            setModalData(SIMULTANEOUS_LISTENING_MODAL_DATA);
            setHasError(true);
          }
        },
      });
    }
  }, [dismissSplashScreen]);

  const switchShowExitAppPopup = (val: boolean) => {
    showExitAppPopup.current = val;
    setRand(Math.random());
  };

  const retrieveExitAppPopup = () => {
    return showExitAppPopup.current;
  };

  const EXIT_MODAL_DATA = {
    title: 'Are you sure you want to exit?',
    text: '',
    actions: [
      {
        label: 'No',
        action: () => handleCloseExitAppPopup(),
      },
      {
        label: 'Yes',
        action: () => handleExitApp(),
      },
    ],
  };

  useEffect(() => {
    initXdk(
      setMessage,
      history,
      dispatch,
      setNumberPressed,
      retrieveExitAppPopup,
      switchShowExitAppPopup,
      setModalData,
      EXIT_MODAL_DATA,
    );
  }, []);

  const handleExitApp = () => {
    window.close();
  };

  const handleCloseExitAppPopup = () => {
    switchShowExitAppPopup(false);
  };

  const auth: any = useAuth();
  useEffect(() => {
    if (dismissSplashScreen) {
      /*
      const mediaPlayerService = ServiceFactory.getInstance(
        MediaPlayerService,
      ) as MediaPlayerService;
    */

      const onGlobalKeyDown = event => {
        const { keyCode } = event;

        if (
          keyCode === 37 ||
          keyCode === 38 ||
          keyCode === 39 ||
          keyCode === 40 ||
          keyCode === 8
        ) {
          //Prevents undesired scrolling when up and down arrow keys are pressed or going back
          event.preventDefault();
        }
      };

      window.addEventListener('keydown', onGlobalKeyDown);

      return () => window.removeEventListener('keydown', onGlobalKeyDown);
    }
  }, [dismissSplashScreen]);

  useEffect(() => {
    const navigationService = ServiceFactory.getInstance(
      NavigationService,
    ) as NavigationService;
    navigationService.history = history;
  }, []);

  useEffect(() => {
    if (
      dismissSplashScreen &&
      autoplayOnStartup === AUTOPLAY_STATES.INITIALIZATION
    ) {
      const storageService = ServiceFactory.getInstance(
        StorageService,
      ) as StorageService;

      if (!storageService.getItem(StorageKeyConstant.AUTOPLAY_ON_STARTUP)) {
        storageService.setItem(
          StorageKeyConstant.AUTOPLAY_ON_STARTUP,
          SettingsConstants.ON,
        );
      }
      const isAutoplayOnStartupOn =
        storageService.getItem(StorageKeyConstant.AUTOPLAY_ON_STARTUP) ===
        SettingsConstants.ON;
      if (isAutoplayOnStartupOn) {
        setAutoplayOnStartup(AUTOPLAY_STATES.AUTOPLAY);
      }
    }
  }, [dismissSplashScreen, autoplayOnStartup]);

  const shouldLoadWelcomeVideo = () => {
    if (!auth) {
      return true;
    } else if (
      auth &&
      auth.userSession &&
      Object.keys(auth.userSession).length === 0
    ) {
      return true;
    } else if (
      auth &&
      auth.userSession &&
      auth.userSession.username === 'null'
    ) {
      return true;
    } else if (
      auth &&
      auth.userSession &&
      (!auth.userSession.username ||
        (auth.userSession.username && !auth.userSession.authenticated))
    ) {
      //Username could still be populated but resume API will mark the user as not authenticated, so they will still get redirected to the Welcome screen
      return true;
    } else {
      false;
    }
  };

  const videoWrapperRef = useRef(null);
  const isMounted = useRef(false);
  const loadSXMPlayer = !shouldLoadWelcomeVideo() && !hasSXMPlayerInitialized;

  useEffect(() => {
    if (
      isMounted &&
      isMounted.current &&
      dismissSplashScreen &&
      loadSXMPlayer
    ) {
      const videoPlayerService = ServiceFactory.getInstance(
        VideoPlayerService,
      ) as VideoPlayerService;

      if (videoWrapperRef && videoWrapperRef.current) {
        //The videoPlayerService must only be initialized ONCE per app execution
        hasSXMPlayerInitialized = true;
        videoPlayerService.init(videoWrapperRef.current, VIDEO_WRAPPER_ID);
        videoPlayerService.subscribeToStartVideo();
      }
    } else {
      isMounted.current = true;
    }
  }, [videoWrapperRef.current, dismissSplashScreen, loadSXMPlayer]);

  /**** GUP AND IT BYPASS MODAL LOGIC - START  ******/

  const bypassSubscriptionRef = useRef(null);
  const [scanForBypass, setScanForBypass] = useState(false);
  useEffect(() => {
    if (
      dismissSplashScreen &&
      auth &&
      auth.userSession &&
      auth.userSession.authenticated &&
      auth.userSession.username
    ) {
      const bypassMonitorService = ServiceFactory.getInstance(
        BypassMonitorService,
      ) as BypassMonitorService;

      if (scanForBypass) {
        bypassSubscriptionRef.current &&
          bypassSubscriptionRef.current.unsubscribe();
      }

      bypassSubscriptionRef.current = bypassMonitorService.bypassErrorState
        .pipe(
          distinctUntilChanged((prev, current) => {
            return (
              prev.GUP_BYPASS === current.GUP_BYPASS &&
              prev.GUP_BYPASS2 === current.GUP_BYPASS2 &&
              prev.IT_BYPASS === current.IT_BYPASS &&
              prev.SEARCH_BYPASS === current.SEARCH_BYPASS
            );
          }),
        )
        .subscribe(bypassErrorState => {
          if (bypassErrorState.GUP_BYPASS || bypassErrorState.GUP_BYPASS2) {
            const modalData = {
              title: "We're experiencing some technical difficulties.",
              text:
                'Some of your settings and preferences are temporarily unavailable.',
              actions: [
                {
                  label: 'OK',
                  action: () => {
                    setHasError(false);
                  },
                },
              ],
            };
            setModalData(modalData);
            setHasError(true);
            setTimeout(() => {
              //Focus is getting lost when the page loads, so we need to restore it.
              focusManager.changeFocus('CONTAINER');
            }, 50);
          } else if (bypassErrorState.IT_BYPASS) {
            const modalData = {
              title: "We're experiencing some technical difficulties.",
              text: 'Please try again later.',
              actions: [
                {
                  label: 'Dismiss',
                  action: () => {
                    const authenticationService = ServiceFactory.getInstance(
                      AuthenticationService,
                    ) as AuthenticationService;
                    authenticationService.reclaimSession();
                    setHasError(false);
                  },
                },
              ],
            };
            setModalData(modalData);
            setHasError(true);
            setTimeout(() => {
              //Focus is getting lost when the page loads, so we need to restore it.
              focusManager.changeFocus('CONTAINER');
            }, 50);
          } else if (bypassErrorState.SEARCH_BYPASS) {
            const modalData = {
              title: "Sorry, we're experiencing some technical difficulties.",
              text: 'Search is currently unavailable.',
              actions: [
                {
                  label: 'OK',
                  action: () => {
                    setHasError(false);
                  },
                },
              ],
            };
            setModalData(modalData);
            setHasError(true);
            setTimeout(() => {
              //Focus is getting lost when the page loads, so we need to restore it.
              focusManager.changeFocus('CONTAINER');
            }, 50);
          }
        });
    }
  }, [
    dismissSplashScreen,
    auth && auth.userSession && auth.userSession.authenticated,
    scanForBypass,
  ]);

  /**** GUP AND IT BYPASS MODAL LOGIC - END  ******/

  const [showUpNext, setShowUpNext] = useState(false);
  const [isAutoPlayOn, setIsAutoPlayOn] = useState(false);
  useEffect(() => {
    if (dismissSplashScreen) {
      const settingsService = ServiceFactory.getInstance(
        SettingsService,
      ) as SettingsService;

      const subscription = settingsService.settings.subscribe(() => {
        setIsAutoPlayOn(
          settingsService.getGlobalSettingValue(SettingsConstants.AUTO_PLAY) ===
            SettingsConstants.ON,
        );
      });

      return () => subscription.unsubscribe();
    }
  }, [dismissSplashScreen]);

  const location = useLocation();
  const [isSkippingTrackerEnabled, enableSkippingTracker] = useState(false);
  useEffect(() => {
    if (location.pathname.includes(appRouteConstants.NOW_PLAYING)) {
      enableSkippingTracker(false);
    } else if (!isSkippingTrackerEnabled) {
      enableSkippingTracker(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    kochava?.setLogLevel('Info');
    kochava?.sendPageEvent();
  }, [location.pathname]);

  return (
    <div
      className={`${
        unauhtenticatedPaths.includes(location.pathname) ? 'app darkBkg' : 'app'
      }`}
    >
      <div id={VIDEO_WRAPPER_ID} ref={videoWrapperRef} />
      <Modal
        title={modalData.title}
        text={modalData.text}
        show={hasError || showExitAppPopup.current}
        actions={modalData.actions}
        restoreFocus={focusManager.getCurrentFocus() || 'CORE_MENU'}
        style={{
          position:
            location.pathname === appRouteConstants.AUTH.LOGIN
              ? 'relative'
              : 'fixed',
        }}
      />
      {dismissSplashScreen &&
        auth &&
        auth.userSession &&
        auth.userSession.authenticated &&
        auth.userSession.username &&
        (autoplayOnStartup === AUTOPLAY_STATES.INITIALIZATION ||
          autoplayOnStartup === AUTOPLAY_STATES.AUTOPLAY) && (
          <HandleAutoplayOnStartup
            AUTOPLAY_STATES={AUTOPLAY_STATES}
            setAutoplayOnStartup={setAutoplayOnStartup}
            autoplayOnStartup={autoplayOnStartup}
          />
        )}
      {dismissSplashScreen && isAutoPlayOn && (
        <UpNext setShowUpNext={setShowUpNext} showUpNext={showUpNext} />
      )}
      {dismissSplashScreen && (
        <InactivityTracker
          setModalData={setModalData}
          auth={auth}
          setHasError={setHasError}
          setIsCoreMenuOpen={setIsCoreMenuOpen}
        />
      )}
      {dismissSplashScreen &&
        auth &&
        auth.userSession &&
        auth.userSession.authenticated &&
        auth.userSession.username &&
        isSkippingTrackerEnabled && <SkippingTracker showUpNext={showUpNext} />}
      {dismissSplashScreen &&
        !unauhtenticatedPaths.some(route =>
          location.pathname.includes(route),
        ) && (
          <FreeTrialTracker
            setIsOnFreeTrial={setIsOnFreeTrial}
            isOnFreeTrial={isOnFreeTrial}
            setIsCoreMenuOpen={setIsCoreMenuOpen}
            username={auth.userSession.username}
          />
        )}
      {(location.pathname === '/' ||
        location.pathname === appRouteConstants.FT_WELCOME ||
        location.pathname === appRouteConstants.ACTIVATE_DEVICE ||
        location.pathname === appRouteConstants.PRIVACY_POLICY) &&
        shouldLoadWelcomeVideo() && (
          <WelcomeVideoComponent pathname={location.pathname} />
        )}
      <Switch>
        <Route exact={dismissSplashScreen} path={'/'}>
          <SplashPageComponent
            shouldDismissSplashScreen={shouldDismissSplashScreen}
          />
        </Route>
        <ProtectedRoute kochava={kochava} path={appRouteConstants.FT_WELCOME}>
          <WelcomePageComponent kochava={kochava} />
        </ProtectedRoute>
        <ProtectedRoute
          path={appRouteConstants.ACTIVATE_DEVICE}
          kochava={kochava}
        >
          <ActivateDeviceComponent kochava={kochava} />
        </ProtectedRoute>
        <ProtectedRoute
          kochava={kochava}
          exact
          path={appRouteConstants.AUTH.LOGIN}
        >
          <LoginPageComponent kochava={kochava} />
        </ProtectedRoute>
        <ProtectedRoute
          kochava={kochava}
          path={appRouteConstants.AUTH.LOGIN_CONFIRMATION}
        >
          <LoginConfirmationPageComponent
            setScanForBypass={setScanForBypass}
            kochava={kochava}
          />
        </ProtectedRoute>
        <ProtectedRoute
          kochava={kochava}
          path={appRouteConstants.AUTH.OPEN_ACCESS_CONFIRMATION}
        >
          <OpenAccessConfirmationPageComponent />
        </ProtectedRoute>
        <ProtectedRoute
          kochava={kochava}
          path={appRouteConstants.AUTH.OPEN_ACCESS_EXPIRED}
        >
          <OpenAccessExpiredPageComponent />
        </ProtectedRoute>

        <ProtectedRoute
          path={appRouteConstants.PRIVACY_POLICY}
          kochava={kochava}
        >
          <PrivacyPolicyScreen kochava={kochava} />
        </ProtectedRoute>
        <ProtectedRoute kochava={kochava} path={appRouteConstants.NOW_PLAYING}>
          <NowPlayingPageComponent
            setShowUpNext={setShowUpNext}
            showUpNext={showUpNext}
            isOnFreeTrial={isOnFreeTrial}
            hasError={hasError}
          />
        </ProtectedRoute>

        <>
          <NavigationMenu
            isCoreMenuOpen={isCoreMenuOpen}
            setIsCoreMenuOpen={setIsCoreMenuOpen}
            isOnFreeTrial={isOnFreeTrial}
          />
          <ProtectedRoute
            kochava={kochava}
            path={`${appRouteConstants.HOME}/:superCategory`}
          >
            <HomePageComponent />
          </ProtectedRoute>
          <ProtectedRoute
            kochava={kochava}
            path={`${appRouteConstants.FAVORITES}/:superCategory`}
          >
            <FavoritesPageComponent />
          </ProtectedRoute>
          <ProtectedRoute
            kochava={kochava}
            path={appRouteConstants.RECENTLY_PLAYED}
          >
            <RecentPageComponent
              isCoreMenuOpen={isCoreMenuOpen}
              setIsCoreMenuOpen={setIsCoreMenuOpen}
            />
          </ProtectedRoute>
          <ProtectedRoute
            kochava={kochava}
            exact
            path={appRouteConstants.SEARCH}
          >
            <SearchPageComponent
              isCoreMenuOpen={isCoreMenuOpen}
              setIsCoreMenuOpen={setIsCoreMenuOpen}
              numberPressed={numberPressed}
              isOnFreeTrial={isOnFreeTrial}
              setNumberPressed={setNumberPressed}
            />
          </ProtectedRoute>
          <ProtectedRoute
            kochava={kochava}
            path={`${appRouteConstants.SEARCH}/:query`}
          >
            <SearchPageComponent
              isCoreMenuOpen={isCoreMenuOpen}
              setIsCoreMenuOpen={setIsCoreMenuOpen}
              numberPressed={numberPressed}
              setNumberPressed={setNumberPressed}
              isOnFreeTrial={isOnFreeTrial}
            />
          </ProtectedRoute>
          <ProtectedRoute
            kochava={kochava}
            path={appRouteConstants.SEARCH_RESULT}
          >
            <SearchResultComponent />
          </ProtectedRoute>
          <ProtectedRoute
            kochava={kochava}
            path={`${appRouteConstants.CATEGORY_PAGE}/:superCategory`}
          >
            <PodcastsVideosPageComponent
              setIsCoreMenuOpen={setIsCoreMenuOpen}
            />
          </ProtectedRoute>
          <ProtectedRoute
            kochava={kochava}
            path={`${appRouteConstants.ON_DEMAND.EPISODES_LIST}`}
          >
            <EpisodeListingPage setIsCoreMenuOpen={setIsCoreMenuOpen} />
          </ProtectedRoute>
          <ProtectedRoute
            kochava={kochava}
            path={`${appRouteConstants.ENHANCED_EDP}`}
          >
            <EnhancedEdpPage setIsCoreMenuOpen={setIsCoreMenuOpen} />
          </ProtectedRoute>
          <ProtectedRoute
            kochava={kochava}
            path={`${appRouteConstants.COLLECTION}`}
          >
            <CollectionPage
              setIsCoreMenuOpen={setIsCoreMenuOpen}
              isOnFreeTrial={isOnFreeTrial}
            />
          </ProtectedRoute>
          <ProtectedRoute
            kochava={kochava}
            path={`${appRouteConstants.CATEGORY}/:category/:type`}
          >
            <CategoryPage
              setIsCoreMenuOpen={setIsCoreMenuOpen}
              isOnFreeTrial={isOnFreeTrial}
            />
          </ProtectedRoute>
          <ProtectedRoute
            kochava={kochava}
            path={`${appRouteConstants.VIEW_ALL}/:carouselId`}
          >
            <ViewAllPage
              setIsCoreMenuOpen={setIsCoreMenuOpen}
              isOnFreeTrial={isOnFreeTrial}
            />
          </ProtectedRoute>
          <ProtectedRoute
            kochava={kochava}
            path={appRouteConstants.ALL_CHANNELS}
          >
            <AllChannelsPage
              setIsCoreMenuOpen={setIsCoreMenuOpen}
              isOnFreeTrial={isOnFreeTrial}
            />
          </ProtectedRoute>
          <ProtectedRoute
            kochava={kochava}
            path={appRouteConstants.APPLICATION_SETTINGS}
          >
            <SettingsPageComponent
              isCoreMenuOpen={isCoreMenuOpen}
              setIsCoreMenuOpen={setIsCoreMenuOpen}
              isOnFreeTrial={isOnFreeTrial}
              setAutoplayOnStartup={setAutoplayOnStartup}
              AUTOPLAY_STATES={AUTOPLAY_STATES}
            />
          </ProtectedRoute>
        </>
      </Switch>
    </div>
  );
};

export default App;
