import {
  from as observableFrom,
  BehaviorSubject,
  Subscription,
  Observable,
  combineLatest,
} from 'rxjs';
import { filter, catchError, mergeMap, first, share } from 'rxjs/operators';
import {
  IHttpRequestConfig,
  IHttpResponse,
  INetworkError,
} from './types/http.types';
import {
  AuthenticationService,
  IAppConfig,
  IProviderDescriptor,
} from '../index';
import { IHttpHeader } from './http.provider.response.interceptor';
import axios from 'axios';
import { addProvider } from '../service';
import { Logger } from '../logger';
import { StorageService } from '../storage';
import {
  ModuleArea,
  ModuleAreaDetailRequest,
  ModuleAreaRequest,
  ModuleList,
  ModuleListAreaRequest,
  ModuleListRequest,
  ModuleRequest,
} from './types/module.list.request';
import { HttpDelegate } from './http.delegate';
import { RequestInterceptor } from './http.provider.request.interceptor';
import { ResponseInterceptor } from './http.provider.response.interceptor';
import { ApiCodes, HttpCodes } from '../service';
import { ServiceEndpointConstants } from '../service/consts';
import { AuthenticationModel } from '../authentication/authentication.model';

class IHttpHeaderObservable {
  name: string;
  subject: BehaviorSubject<IHttpHeader>;
  observable: Observable<IHttpHeader>;
}

/**
 * Main class for this module.  This exposes an API to clients for making http calls
 */
export class HttpProvider {
  /**
   * Internal logger.
   * @type {Logger}
   */
  private static logger: Logger = Logger.getLogger('HttpProvider');

  /**
   * subject for delivering the user session data through the sessionValid observable
   * @type {BehaviorSubject<boolean>}
   */
  private sessionValidSubject: BehaviorSubject<boolean> = new BehaviorSubject(
    true,
  );

  /*
   * Observable that tracks whether the API session is valid or not.
   */
  public sessionValid: Observable<boolean> = this.sessionValidSubject;

  /**
   * private subject for delivering network errors through the public onNetworkError observable
   */
  private onNetworkErrorSubject: BehaviorSubject<
    INetworkError
  > = new BehaviorSubject(null);

  /*
   * Observable that will fire whenever a network error (HTTP error or timeout) is encountered
   */
  public onNetworkError: Observable<
    INetworkError
  > = this.onNetworkErrorSubject.pipe(filter(error => error !== null));

  /**
   * private subject for delivering network responses throygh the public onNetworkSuccess observable
   */
  private onNetworkSuccessSubject: BehaviorSubject<
    IHttpResponse
  > = new BehaviorSubject(null);

  /*
   * Observable that will fire whenever a network response (HTTP 200) is encountered
   */
  public onNetworkSuccess: Observable<
    IHttpResponse
  > = this.onNetworkSuccessSubject.pipe(filter(response => response !== null));

  /**
   * Array of IHttpHeaderObservable objects that lists the http headers that have observables to be triggered
   * when certain http headers are received.
   *
   * @type {Array}
   */
  private httpHeaderObservables: Array<IHttpHeaderObservable> = [];

  /**
   * Track the http header subscription to the response interceptor.  If we no longer have any http headers we
   * are observing then this can be unsubscribed.  When the first http header subscription is created on the
   * service than this subscription is created.
   */
  private httpHeaderSubscription: Subscription;

  /**
   * Maximum number of times that the http service will retry an API call for generic HTTP and API Failures.
   * @type {number}
   */
  public static MAX_RETRIES = 1;

  /**
   * Maximum number of times that the http service will retry an API call for carousel API Failures.
   * @type {number}
   */
  public static MAX_CAROUSEL_RETRIES = 5;

  /**
   * Number of milliseconds that http service will wait before retrying an API call that failued
   * @type {number}
   */
  public static RETRY_WAIT_TIME_MS = 2000;

  /**
   * the http service will check the list before retry the session recovery api call
   * @type {Array}
   */
  public static disabledRetrySessionRecoveryList: Array<string> = [];

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(HttpProvider, HttpProvider, [
      RequestInterceptor,
      ResponseInterceptor,
      StorageService,
      'IAppConfig',
      AuthenticationModel,
    ]);
  })();

  /**
   * Constructor function will initialize the private data members, and then register the request interceptor and
   * response interceptors with Axios.
   * @param requestInterceptor is a request interceptor object to use with all http requests
   * @param responseInterceptor is a response interceptor object to use with all http requests
   * @param storageService allows us to persist key/value pairs to local storage
   * @param SERVICE_CONFIG contains runtime configuration parameters for usage in http calls
   */
  constructor(
    private requestInterceptor: RequestInterceptor,
    private responseInterceptor: ResponseInterceptor,
    storageService: StorageService,
    private SERVICE_CONFIG: IAppConfig,
    private authenticationModel: AuthenticationModel,
  ) {
    axios.interceptors.request.use(requestInterceptor.onRequest);
    axios.interceptors.response.use(responseInterceptor.onResponse);
    // TODO: Jordan D. Nelson 070518
    // I discovered that the global axios response interceptor does not run
    // if the response is not a 200.
    // See issues: https://github.com/axios/axios/issues/993
    // https://github.com/axios/axios/issues/995

    const deviceId = storageService.getItem('clientDeviceId');

    if (deviceId) {
      SERVICE_CONFIG.deviceInfo.clientDeviceId = deviceId;
    }
  }

  /**
   * Perform an http get
   * @param url that being requested
   * @param data object (optional) that describes the body of the get request
   * @param config object (optional) with additional information about the get request
   * @returns {Observable<T>} subscribe to this to get the results from the get request
   */
  public get(
    url: string,
    data?: any,
    config?: IHttpRequestConfig,
  ): Observable<any> {
    // NOTE: Only uncomment for deep debugging as this is called quite a bit.
    HttpProvider.logger.debug(`get( ${url} )`);

    if (data) {
      const requestTemplate = new ModuleList();

      if (data instanceof Array) {
        requestTemplate.moduleList.modules = data;
      } else {
        requestTemplate.moduleList.modules[0] = data;
      }

      config.data = requestTemplate;
    }

    return this.http('get', url, config);
  }

  /**
   * Perform an basic http post
   * @param url that is being requested
   * @param data object (optional) with a body for the post request
   * @param config object (optional) with additional information about the post request
   * @returns {Observable<any>} subscribe to this to get the results from the post request
   */
  public post(
    url: string,
    data?: any,
    config?: IHttpRequestConfig,
  ): Observable<any> {
    // NOTE: Only uncomment for deep debugging as this is called quite a bit.
    // HttpProvider.logger.debug(`post( ${url} )`);

    return this.http('post', url, data, config);
  }

  /**
   * Perform a post using the ModuleRequest API format.  Automatically adds necessary service config parameters
   * to the outgoing request
   * @param url that is being requested
   * @param request is the ModuleRequest object that will be used to populate the request payload
   * @param config object (optional) with additional information about the post request
   * @returns {Observable<any>} subscribe to this to get the results from the post request
   */
  public postModuleRequest(
    url: string,
    request: ModuleRequest,
    config?: IHttpRequestConfig,
  ): Observable<any> {
    request.setConfig(this.SERVICE_CONFIG);
    const data = new ModuleListRequest();
    data.addModuleRequest(request);

    return this.post(url, data, config);
  }

  /**
   * Perform a post using the ModuleAreaRequest API format.
   * @param url that is being requested
   * @param request is the ModuleAreaRequest object that will be used to populate the request payload
   * @param config object (optional) with additional information about the post request
   * @returns {Observable<any>}
   */
  public postModuleAreaRequest(
    url: string,
    request: ModuleAreaRequest | ModuleAreaDetailRequest,
    config?: IHttpRequestConfig,
  ): Observable<any> {
    const data = new ModuleListAreaRequest();
    data.addRequest(request);

    return this.post(url, data, config);
  }

  /**
   * Perform a post using the ModuleAreaRequest API format where there are multiple requests being made for a single
   * post
   * @param url that is being requested
   * @param requests is an array ModuleAreaRequest object that will be used to populate the request payload
   * @param config object (optional) with additional information about the post request
   * @returns {Observable<any>}
   */
  public postModuleAreaRequests(
    url: string,
    requests: Array<ModuleArea>,
    config?: IHttpRequestConfig,
  ) {
    const data = new ModuleListAreaRequest();
    data.addRequests(requests);

    return this.post(url, data, config);
  }

  /**
   * Create an observable that will be triggered when a given http header name is received
   *
   * @param headerName is the name of the http header that will trigger the observable
   * @returns {Observable<IHttpHeader>} this can be subscribed to to be notified when the given header name is
   *          received
   */
  public addHttpHeaderObservable(headerName: string): Observable<IHttpHeader> {
    let observableRecord = this.httpHeaderObservables.find(
      obs => obs.name === headerName,
    );

    if (!observableRecord) {
      if (this.httpHeaderObservables.length === 0) {
        this.httpHeaderSubscription = this.responseInterceptor.httpHeaders.subscribe(
          this.onHttpHeader.bind(this),
        );
      }

      observableRecord = new IHttpHeaderObservable();
      observableRecord.name = headerName;
      observableRecord.subject = new BehaviorSubject(null);
      observableRecord.observable = observableRecord.subject.pipe(share());

      this.httpHeaderObservables.push(observableRecord);
    }

    return observableRecord.observable;
  }

  /**
   * Remove an observable that is be triggered when a given http header name is received
   *
   * @param headerName is the name of the http header that will trigger the observable
   * @returns {Observable<IHttpHeader>} this can be subscribed to to be notified when the given header name is
   *          received
   */
  public removeHttpHeaderObservable(headerName: string): void {
    let index = this.httpHeaderObservables.findIndex(
      obs => obs.name === headerName,
    );

    if (index >= 0) {
      this.httpHeaderObservables.splice(index, 1);
    }

    if (this.httpHeaderObservables.length === 0) {
      this.httpHeaderSubscription.unsubscribe();
    }
  }

  /**
   * Used to disable the retry call after the session recovery
   * @param {string} endPointName to be disabled for the retry
   */
  public static disableRetryForSessionRecovery(endPointName: string): void {
    if (
      HttpProvider.disabledRetrySessionRecoveryList.length > 0 &&
      HttpProvider.disabledRetrySessionRecoveryList.indexOf(endPointName) > -1
    ) {
      return;
    }
    HttpProvider.disabledRetrySessionRecoveryList.push(endPointName);
  }

  /**
   * Take an http header that has been encountered, and find an observable that has been created to track that
   * header.  If the observable exists, the trigger the observable with the header, otherwise do nothing
   *
   * @param header is the http header that was encountered
   */
  private onHttpHeader(header: IHttpHeader) {
    let observableRecord = this.httpHeaderObservables.find(
      obs => obs.name === header.name,
    );

    try {
      if (observableRecord) {
        observableRecord.subject.next(header);
      }
    } catch (e) {
      HttpProvider.logger.error(
        `Exception ${e} caught in http header observable for ${header.name}`,
      );
    }
  }

  /**
   * General purpose function for making http requests.  Will have axios make the request based on the request
   * type, and will convert the axois promise into an observable.  If there are certain HTTP or API failures, then
   * the function will perform one retry after a wait time.  If the retry fails, or the HTTP or API error code is
   * not subject to a retry, then the error will be passed onto the called.
   *
   * @param type of http request to make ("get", "post")
   * @param url of the http request
   * @param data optional additional payload data for the request
   * @param config optional axia config object to further customize the request
   * @returns {Observable<R|T>}
   */

  private http(
    type: string,
    url: string,
    data?: any,
    config?: IHttpRequestConfig,
  ): Observable<any> {
    const sessionValidSubject = this.sessionValidSubject;
    const sessionValid = this.sessionValid;
    const sessionWasValid = sessionValidSubject.getValue();
    const self = this;

    const obs = combineLatest(
      this.sessionValid,
      this.authenticationModel.authenticating$,
    ).pipe(
      filter(([sessionValid, isAuthenticating]): boolean => {
        if (HttpProvider.isAuthenticatedCall(url)) {
          return true;
        }

        return (
          ((isAuthenticating as any) as boolean) === false &&
          (((sessionValid as any) as boolean) === true ||
            HttpProvider.isUnAuthenticatedCall(url) === true)
        );
      }),
      first(),
      mergeMap(
        (): Observable<any> => {
          return observableFrom(axiosHttp(type, url, data, config));
        },
      ),
      catchError(error => {
        throw error;
      }),
      share(),
    );

    return obs;

    /**
     * Call the into axios library for the given http type.  Deal with success and failure of the call
     * @param type of the call, get, post, ect
     * @param url is the endpoint that is being called
     * @param data is (optional) data that gets sent with the request payload
     * @param config is an (optional) object for additional configuration options for the call
     * @param retries is the (optional, 0 is not present) number of times the call has been tried
     * @returns promise that will be rejected or resolved based on the success/failure of the http call
     */
    function axiosHttp(
      type: string,
      url: string,
      data?: any,
      config?: IHttpRequestConfig,
      retries?: number,
    ) {
      let retry = retries !== undefined ? retries : 0;

      return HttpProvider.makeHttpCall(type, url, data, config)
        .then(handleSuccess)
        .catch(handleError);

      /**
       * When an API call is successful, we know that the API session is valid, and trigger the
       * sessionValidSubject to inform the outside world of this
       * @param result is the result from the api call, which is just passed up the promise chain
       * @returns {any}
       */
      function handleSuccess(result: IHttpResponse) {
        if (
          sessionValidSubject.getValue() === false &&
          HttpProvider.isResumeCall(url) === true
        ) {
          sessionValidSubject.next(true);
        }

        const responseConfig = config ? config : { isRaw: false };
        responseConfig.url = url;

        self.onNetworkSuccessSubject.next({
          data: result,
          status: 200,
          statusText: 'OK',
          headers: [],
          config: responseConfig,
        });

        // TODO: Jordan D. Nelson 070518
        // result comes from response interceptor and is either modules or response.
        // It used to be modules or response.data.
        // If raw, it is response. if not raw, it is modules.
        // this is bad and eventually should be refactored.
        // the response interceptor should pass along the response and only the response.
        // Then this method can do what it chooses with the response, such as find modules and return them.
        if (result && result.config && result.config.isRaw) {
          result = result.data;
        }

        return result;
      }

      /**
       * If we have certain HTTP or API error codes, we can trigger a retry.  We will not trigger more than
       * MAX_RETRIES retries, and we will wait RETRY_WAIT_TIME_MS before triggering the retry.  These are both
       * designed to keep us from hammering the API when it has trouble, but also make the client somewhat
       * resilient to spurious API and load balancer failures.
       *
       * @param error is the error that was thrown, either from the ApiDelegate or the HttpDelegate
       * @returns {any}
       */
      function handleError(error) {
        let networkError = error;

        /*
         * If error.response is available then we have an http error
         * If error.response is not available and error.code is not available then we have an http timeout
         *
         * In both of these cases, we want to get the HTTP error object from the delegate, and we want
         * to trigger the error observable with that object.  We then continue to use that object for
         * error code handling, and will eventually notify the caller if we run out of retries or otherwise
         * determine there is not more error processing that can be done here.
         */
        if (error.response || !error.code) {
          /*
           * At this point, we know we are dealing with an HTTP error only.  We can use the HTTP delegate
           * to get a networkError object formatted the way we need, trigger the error observable with this
           * object, and then use that object for any further error processing.
           */
          const response = error.response
            ? error.response
            : { config: error.config };

          networkError = HttpDelegate.getHTTPError(response);
          self.onNetworkErrorSubject.next(networkError);
        } else {
          /*
           * The else covers API errors.  If we have received an API error, then we know the network is OK, so
           * we trigger the onNetworkSuccess observable.
           *
           * NOTE : this is a little counter-intuitive.  We are in an error handler, but the error is from the
           * API, not the network.  The mere fact that we *received* an API error means that the network is OK,
           * so we trigger the onNetworkSuccess observable so that the outside world knows that the network is
           * (still) OK.
           */
          self.onNetworkSuccessSubject.next(networkError);
        }

        const retried = handleErrorCode(networkError);

        // If handle error code returns a new promise, then a retry has been attempted, so we resolve the
        // promise chain with the promise from the retry;
        if (retried) {
          return retried;
        }

        // Otherwise, the failure is not eligible for a retry, or retries have been exhausted, so propagate
        // the error up the chain.
        throw networkError;

        /**
         * Once we have an error code (from API or HTTP), we can now handle by triggering a retry or waiting
         * for the session to be recovered, then triggering a retry
         * @param error
         * @returns null if a retry was not attempted, or a new Promise that will be resolved from the retry
         *          if a retry was attempted
         */
        function handleErrorCode(error): Promise<any> {
          const carouselEndPoint =
            ServiceEndpointConstants.endpoints.CAROUSEL.V4_CAROUSEL_BY_PAGE;
          const isModifyRequest = url.indexOf('/modify') !== -1;
          const maxRetries =
            error &&
            (error.code === ApiCodes.CAROUSEL_API_ERROR ||
              (error.code === ApiCodes.GUP_UPDATE_FAILED && isModifyRequest) ||
              (error.code === HttpCodes.INTERNAL_SERVER_ERROR &&
                error.url === carouselEndPoint))
              ? HttpProvider.MAX_CAROUSEL_RETRIES
              : HttpProvider.MAX_RETRIES;
          if (retry++ < maxRetries) {
            switch (error.code) {
              /**
               * The following error codes will perform 1 retry of the call after a cool off period of
               * HttpProvider.RETRY_WAIT_TIME_MS
               */
              case HttpCodes.BAD_GATEWAY:
              case HttpCodes.SERVICE_UNAVAILABLE:
              case HttpCodes.GATEWAY_TIMEOUT:
              case HttpCodes.NETWORK_TIMEOUT:
              case HttpCodes.INTERNAL_SERVER_ERROR:
              case ApiCodes.GUP_UPDATE_FAILED:
              case ApiCodes.INVALID_REQUEST:
              case ApiCodes.INTERNAL_API_ERROR:
              case ApiCodes.SESSION_RETRY:
              case ApiCodes.CAROUSEL_API_ERROR:
                /**
                 * If the geo=location call fails, we don't want to retry.  This normally happens on
                 * non production endpoints where geo-location is not available
                 */
                if (
                  url.indexOf(
                    ServiceEndpointConstants.endpoints.GEOLOCATION.V2_CHECK,
                  ) >= 0
                ) {
                  break;
                }

                return new Promise(resolve => {
                  setTimeout(() => {
                    resolve(axiosHttp(type, url, data, config, retry));
                  }, HttpProvider.RETRY_WAIT_TIME_MS);
                });

              /**
               * The following error codes will mark the API session as being invalid, and will retry the
               * API call once the session has become valid again.  This does not count as a retry for the
               * purposes of error handling like with the case statement above
               */
              case ApiCodes.SESSION_RESUME:
              case ApiCodes.IT_DOWN:
              case ApiCodes.SIMULTANEOUS_LISTEN:
              case ApiCodes.SIMULTANEOUS_LISTEN_SAME_DEVICE:
                /** If the session was valid when we made the request, and we got one of the above error
                 * codes, then we mark the session as being invalid and we trigger a retry once the
                 * session is recovered
                 *
                 * If the session was *not* valid when we made the request we return the error up the
                 * chain so that the caller knows that we are dealing with a failure.  We do not want
                 * to re-trigger these calls when the session is recovered because we could be making
                 * a lot of API calls at that point, which will put stress on the API
                 *
                 * If we were logging out and the above errors were received we again do not need to
                 * worry about recovering the session.
                 *
                 */

                if (
                  sessionWasValid === false ||
                  url.indexOf(
                    ServiceEndpointConstants.endpoints.AUTHENTICATION.V2_LOGOUT,
                  ) >= 0
                ) {
                  break;
                }

                sessionValidSubject.next(false);

                HttpProvider.logger.error(
                  `handleErrorCode( User session needs to be recovered because of ${error.code} )`,
                );

                // Only retry the request if it *was* not a resume call.  Recovering the session implies a
                // resume call was made successfully, so no need to retry a resume call since session recovery
                // implies we made a successful resume.

                return new Promise(resolve => {
                  sessionValid
                    .pipe(first(valid => valid === true))
                    .subscribe(() => {
                      HttpProvider.logger.debug(
                        `handleErrorCode( User session has been ` +
                          `recovered, retrying call to ${url} )`,
                      );

                      HttpProvider.isDisabledRetrySessionRecoveryCall(url)
                        ? resolve(true)
                        : resolve(axiosHttp(type, url, data, config, 0));
                    });
                });
            }

            throw error;
          }
        }
      }
    }
  }

  /**
   * This function exists as a public static member so that we can spy on it in unit testing and test the retry
   * capabilities of the service
   *
   * @param type of http request to make ("get", "post")
   * @param url of the http request
   * @param data optional additional payload data for the request
   * @param config optional axia config object to further customize the request
   * @param retries optional retry count for the call.  This is the number of retries that have been attempted so
   *        far, including the call we are about to make.
   *
   * @returns Promise from the axois library for the call to be made
   */
  public static makeHttpCall(
    type: string,
    url: string,
    data?: any,
    config?: IHttpRequestConfig,
    retries?: number,
  ) {
    return axios[type](url, data, config);
  }

  /**
   * Checks to see if the url provided represents a resume call
   *
   * @param {string} url of a request
   *
   * @returns {boolean} true if url is a resume call, false otherwise
   */
  private static isResumeCall(url: string) {
    return (
      url.indexOf(ServiceEndpointConstants.endpoints.RESUME.V4_GET_RESUME) >=
        0 ||
      url.indexOf(ServiceEndpointConstants.endpoints.RESUME.V2_GET_RESUME) >= 0
    );
  }

  /**
   * Checks to see if the url provided represents a call that can be made even if the client is in the unauthenticated
   * state
   *
   * @param {string} url of a request
   *
   * @returns {boolean} true if url can be made while unauthenticated or false if we need to wait for client to
   *                    become authenticated before calling the url
   */
  private static isUnAuthenticatedCall(url: string) {
    return (
      this.isResumeCall(url) ||
      url.indexOf(ServiceEndpointConstants.NETWORK_CONNECTIVITY) >= 0 ||
      url.indexOf(ServiceEndpointConstants.APP_VERSION) >= 0 ||
      url.indexOf(ServiceEndpointConstants.endpoints.CONFIG.V2_GET) >= 0 ||
      url.indexOf(ServiceEndpointConstants.endpoints.CONFIG_INFO.V4_GET) >= 0 ||
      url.indexOf(ServiceEndpointConstants.endpoints.CONFIG_INFO.V4_GET) >= 0 ||
      url.includes(
        ServiceEndpointConstants.endpoints.AUTHENTICATION
          .V4_CREATE_ALTERNATE_LOGIN,
      ) ||
      url.includes(
        ServiceEndpointConstants.endpoints.AUTHENTICATION
          .V4_COMPLETE_ALTERNATE_LOGIN,
      )
    );
  }

  private static isAuthenticatedCall(url: string) {
    return (
      url.indexOf(ServiceEndpointConstants.endpoints.AUTHENTICATION.V2_LOGIN) >=
      0
    );
  }

  /**
   * Checks to see if the url provided presents in the disabledRetrySessionRecoveryList
   * @param {string} url of a request
   * @returns {boolean} true if url is in the list, false otherwise
   */
  private static isDisabledRetrySessionRecoveryCall(url: string): boolean {
    const itemFound = HttpProvider.disabledRetrySessionRecoveryList.filter(
      item => item === url,
    );
    return itemFound && itemFound.length > 0;
  }
}
