import React, { useState } from 'react';
import FocusKeyboardSmart from './FocusKeyboardSmart';
import {
  lowerCaseLayoutWithEmail,
  upperCaseLayoutWithEmail,
  symbolsLayoutWithEmail,
  symbolsLayoutWithNoEmail,
  searchLayout,
  lowerCaseLayoutWithNoEmail,
  upperCaseLayoutWithNoEmail,
} from './defaultFocusKeyboardSmartSettings';

const layoutsMapping = {
  UPPERCASE: { id: 'UPPERCASE', layout: searchLayout },
};
const layoutMappingWithoutEmail = {
  UPPERCASE: { id: 'UPPERCASE', layout: upperCaseLayoutWithNoEmail },
  LAYOUT1: { id: 'LAYOUT1', layout: lowerCaseLayoutWithNoEmail },
  LAYOUT2: { id: 'LAYOUT2', layout: symbolsLayoutWithNoEmail },
};

const layoutsMappingWithEmail = {
  UPPERCASE: { id: 'UPPERCASE', layout: upperCaseLayoutWithEmail },
  LAYOUT1: { id: 'LAYOUT1', layout: lowerCaseLayoutWithEmail },
  LAYOUT2: { id: 'LAYOUT2', layout: symbolsLayoutWithEmail },
};

const Keyboard = props => {
  const {
    onVirtualKeyClick,
    isEmail = false,
    isNoEmail = false,
    ...rest
  } = props;
  const layoutMappingVariant =
    isEmail && !isNoEmail
      ? layoutsMappingWithEmail
      : isNoEmail
      ? layoutMappingWithoutEmail
      : layoutsMapping;
  const [activeLayout, setActiveLayout] = useState(
    isEmail ? layoutMappingVariant.LAYOUT1 : layoutMappingVariant.UPPERCASE,
  );

  const onVirtualKeyClickWrapper = (text, matchedFeature) => {
    const selectedLayout =
      matchedFeature && layoutMappingVariant[matchedFeature[0]];
    if (selectedLayout) {
      setActiveLayout(prevActiveLayout => {
        if (
          selectedLayout.id === 'LAYOUT1' &&
          prevActiveLayout.id === 'UPPERCASE'
        ) {
          return layoutMappingVariant.LAYOUT1;
        } else if (
          selectedLayout.id === 'LAYOUT1' &&
          prevActiveLayout.id === 'LAYOUT2'
        ) {
          return layoutMappingVariant.LAYOUT1;
        } else if (selectedLayout.id === 'LAYOUT1') {
          return layoutMappingVariant.UPPERCASE;
        } else {
          return selectedLayout;
        }
      });
    }
    onVirtualKeyClick(text);
  };

  return (
    <FocusKeyboardSmart
      isDefault={!isEmail ? true : false}
      layout={activeLayout.layout}
      activeLayoutId={activeLayout.id}
      onVirtualKeyClick={onVirtualKeyClickWrapper}
      col={13}
      {...rest}
    />
  );
};

export default Keyboard;
