import { take, filter, share } from 'rxjs/operators';
import * as _ from 'lodash';
import {
  Observable,
  SubscriptionLike as ISubscription,
  BehaviorSubject,
} from 'rxjs';

import {
  ChannelLineupService,
  ContentTypes,
  IChannel,
  INeriticLinkData,
  ITile,
  Logger,
  parseNeriticLink,
  ITileAssetInfo,
  CarouselTypeConst,
  ConfigService,
  AlertType,
  TunePayload,
  OptionalNeriticAction,
  AppMonitorService,
  ClientFault,
  IMediaShow,
  ClientCodes,
  CarouselConsts,
  CarouselUtil,
  AppErrorCodes,
  neriticActionConstants,
  IProviderDescriptor,
  addProvider,
} from '../../servicelib';
import { appRouteConstants } from '../../routing/app.route.constants';
import { displayViewType } from '../../types/display.view.type';
import { TuneClientService } from '../tune/tune.client.service';
import { AlertClientService } from '../alert/alert.client.service';
import { ChannelListStoreService } from '../channel-list/channel-list.store.service';
import { FavoriteListStoreService } from '../favorite-list/favorite-list.store.service';
import { NavigationService } from '../navigation/navigation.service';
import { RecentlyPlayedStoreService } from '../recently-played/recently-played.store.service';
import { CarouselStoreService } from '../carousel-store/carousel.store.service';
import { ToastService } from '../toast/toast.service';
import { NowPlayingStoreService } from '../now-playing/now-playing.store.service';
import { IModalData } from '../modal/modal.interface';
import { SeededStationsClientService } from '../seeded-stations/seeded-stations.client.service';
import {
  NeriticLinkOptions,
  ICallbacks,
  IModalOptions,
  IAutoPlayTune,
} from './neritic-link.interface';
import { focusManager } from '@accedo/vdkweb-navigation';
import { PODCASTSVIDEO } from '../../utils/navigationMap';

/**
 * A service for parsing neritic links returned from the API and taking the correct action.
 * There are two types of neritic links: App & Api
 *
 * The App type directs the client to open a specific screen within the app or could indicate to the app to start a
 * certain process. App neritic links have the following format: App:{screen}:{param} (param is optional)
 *
 * The Api type directs the client to make an API call to take some action.  Currently, the only action defined is
 * "tune". Api neritic links have the following format:
 * Api:{action}:{content_type}:{channel_id}:{episode_id}:{timestamp}
 *
 * @class NeriticLinkService
 */

export class NeriticLinkService {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('NeriticLinkService');

  /**
   * search focus BehaviorSubject
   */
  public searchFocusSubject: BehaviorSubject<boolean>;

  /**
   * search focus Observable
   */
  public searchFocus: Observable<boolean>;

  /**
   * Behavior Subject for sending INeriticLinkData with invalid action
   */
  private invalidNeriticLinkSubject: BehaviorSubject<INeriticLinkData>;

  /**
   * Observable of invalid INeriticLinkData for client to listen to
   */
  public invalidNeriticLink: Observable<INeriticLinkData>;

  public optionalNeriticActionFlags = {};

  public showSubscription: ISubscription;

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(NeriticLinkService, NeriticLinkService, [
      AlertClientService,
      ChannelLineupService,
      ChannelListStoreService,
      ConfigService,
      FavoriteListStoreService,
      NavigationService,
      RecentlyPlayedStoreService,
      TuneClientService,
      CarouselStoreService,
      ToastService,
      AppMonitorService,
      NowPlayingStoreService,
      SeededStationsClientService,
    ]);
  })();

  constructor(
    private alertClientService: AlertClientService,
    private channelLineupService: ChannelLineupService,
    private channelListStoreService: ChannelListStoreService,
    private configService: ConfigService,
    private favoriteListStoreService: FavoriteListStoreService,
    private navigationService: NavigationService,
    private recentlyPlayedStoreService: RecentlyPlayedStoreService,
    private tuneClientService: TuneClientService,
    private carouselStoreService: CarouselStoreService,
    private toastService: ToastService,
    private appMonitorService: AppMonitorService,
    private nowPlayingStoreService: NowPlayingStoreService,
    private seededStationsClientService: SeededStationsClientService,
  ) {
    this.searchFocusSubject = new BehaviorSubject(null);
    this.searchFocus = this.searchFocusSubject;

    this.invalidNeriticLinkSubject = new BehaviorSubject<INeriticLinkData>({});
    this.invalidNeriticLink = this.invalidNeriticLinkSubject.pipe(share());
    this.optionalNeriticActionFlags[
      OptionalNeriticAction.CHECK_SHOW_FOR_VIDEO_REMINDER
    ] = false;
  }

  public getNeriticData(data: string): INeriticLinkData {
    return parseNeriticLink(data);
  }

  /**
   * Starts the of taking the action in the Neritic Link.
   * Calls the class method that corresponds to the type of the Neritic Link. Either Api or App.
   *
   * @param {ITile} tile
   * @param {NeriticLinkOptions}
   * @memberof NeriticLinkService
   */
  public takePrimaryTileAction(tile: ITile, options?: NeriticLinkOptions) {
    NeriticLinkService.logger.debug(`takePrimaryTileAction()`);

    let neriticLinkData = tile.primaryNeriticLink
      ? tile.primaryNeriticLink
      : { linkType: '' };
    const showCreateStationModal = _.get(tile, 'tileAssetInfo.create', false);
    if (showCreateStationModal) {
      neriticLinkData.contentType = neriticActionConstants.CREATE_SEEDED_RADIO;
    }

    switch (neriticLinkData.linkType) {
      case neriticActionConstants.API:
        this.takeApiAction(neriticLinkData, tile, options);
        break;
      case neriticActionConstants.APP:
        this.takeTileAppAction(tile);
        break;
      default:
        this.invalidNeriticLinkSubject.next(neriticLinkData);
        NeriticLinkService.logger.error(
          `Encountered unsupported "${neriticLinkData.linkType}"`,
        );
    }
  }

  public takeAction(
    neriticLinkData: INeriticLinkData,
    options?: NeriticLinkOptions,
  ) {
    NeriticLinkService.logger.debug(`takeAction()`);

    if (neriticLinkData.optionalAction) {
      this.optionalNeriticActionFlags[neriticLinkData.optionalAction] = true;
    }

    switch (neriticLinkData.linkType) {
      case neriticActionConstants.API:
        this.takeApiAction(neriticLinkData, null, options);
        break;
      case neriticActionConstants.APP:
        this.takeAppAction(neriticLinkData);
        break;
      default:
        this.invalidNeriticLinkSubject.next(neriticLinkData);
        NeriticLinkService.logger.error(
          `Encountered unsupported "${neriticLinkData.linkType}"`,
        );
    }
  }

  /**
   * Determines the correct action to take for a given SECONDARY neritic link and performs that action.
   * Calls the class method that corresponds to the type of the Neritic Link.
   * SHOULD BE USED ONLY FOR SECONDARY NERITIC LINKS, does NOT support the primaryNeriticLink
   *
   * @param {ITile} tileData
   * @param {INeriticLinkData} neriticLinkData
   * @memberof NeriticLinkService
   * @returns {void}
   */
  public takeSecondaryTileAction(
    tileData: ITile,
    neriticLinkData: INeriticLinkData,
  ): void {
    NeriticLinkService.logger.debug(`takeSecondaryAction()`);

    switch (neriticLinkData.functionalGroup) {
      case neriticActionConstants.FUNCTIONAL_GROUPS.ON_DEMAND_EPISODES:
      // ON_DEMAND_EPISODES --- comes from a  __show tile_
      // MORE_EPISODES      --- comes from an __episode tile_
      // Navigates to on demand episode list page
      //neriticLinkData.assetGuid is holding pagename
      case neriticActionConstants.FUNCTIONAL_GROUPS.MORE_EPISODES:
        this.navigateToEpisodeListing(
          tileData.tileAssetInfo.channelId,
          tileData.tileAssetInfo.showGuid,
          CarouselTypeConst.EPISODE_EDP,
          appRouteConstants.ON_DEMAND.VIDEO_AND_AUDIO,
        );
        break;

      // EPISODE_DETAILS    --- comes from an __episode tile_
      // CHANNEL_DETAILS      --- comes from an __channel tile_
      // SHOW_DETAILS      --- comes from an __show tile_
      // ALL these navigate to the enhanced channel/show/episode page
      case neriticActionConstants.FUNCTIONAL_GROUPS.CHANNEL_DETAILS:
      case neriticActionConstants.FUNCTIONAL_GROUPS.SHOW_DETAILS:
      case neriticActionConstants.FUNCTIONAL_GROUPS.EPISODE_DETAILS:
      case neriticActionConstants.FUNCTIONAL_GROUPS.PODCAST_EPISODE_DETAILS:
        this.navigateToEnhancedEdpPage(neriticLinkData.actionType);
        break;

      case neriticActionConstants.FUNCTIONAL_GROUPS.ON_DEMAND_SHOWS:
        this.navigateToShowsList(tileData.channelInfo);
        break;
      case neriticActionConstants.FUNCTIONAL_GROUPS.SET_SHOW_REMINDER:
        this.updateShowReminder(
          tileData.reminders.showReminderSet,
          tileData.tileAssetInfo,
          tileData.tileContentType,
        );
        break;
      case neriticActionConstants.FUNCTIONAL_GROUPS
        .SET_SHOW_LIVE_VIDEO_REMINDER:
        this.updateLiveVideoReminder(
          tileData.reminders.liveVideoReminderSet,
          tileData.tileAssetInfo,
          tileData.tileContentType,
        );
        break;
      case neriticActionConstants.FUNCTIONAL_GROUPS.ADD_TO_FAVORITES:
        const contentName = this.getFavoriteAlertContentTitle(tileData);
        this.toggleFavorite(
          tileData.isFavorite,
          tileData.tileContentType,
          tileData.tileContentSubType,
          tileData.tileAssetInfo.channelId,
          FavoriteListStoreService.getAssetGuid(
            tileData.tileContentType,
            tileData.tileContentSubType,
            tileData.tileAssetInfo,
          ),
          contentName,
          tileData.tileAssetInfo.isPandoraPodcast,
        );
        break;
      case neriticActionConstants.FUNCTIONAL_GROUPS.ADD_SHOW_TO_FAVORITES:
        let favoriteLocal: boolean = tileData.isFavorite;
        if (CarouselUtil.isEpisodeTile(tileData)) {
          const favorite = this.favoriteListStoreService.getFavorite(
            tileData.tileAssetInfo.showGuid,
          );
          favoriteLocal = favorite ? true : false;
        }

        this.toggleFavorite(
          favoriteLocal,
          ContentTypes.SHOW,
          ContentTypes.SHOW,
          tileData.tileAssetInfo.channelId,
          tileData.tileAssetInfo.showGuid,
          tileData.tileAssetInfo.showName,
          tileData.tileAssetInfo.isPandoraPodcast,
        );

        break;
      case neriticActionConstants.FUNCTIONAL_GROUPS.REMOVE_SEEDED_STATION:
        this.seededStationsClientService.removeSeededStation(
          tileData.tileAssetInfo.stationId,
          tileData.tileAssetInfo.channelGuid,
        );
        break;
      default:
        this.invalidNeriticLinkSubject.next(neriticLinkData);
        NeriticLinkService.logger.error(
          `Encountered unsupported neriticLink functionalGroup: "${neriticLinkData.functionalGroup}"`,
        );
    }
  }

  private getFavoriteAlertContentTitle(tileData: ITile): string {
    if (this.isTileLiveAudio(tileData)) {
      return tileData.tileAssetInfo.channelName;
    } else if (tileData.tileContentType === ContentTypes.SHOW) {
      return tileData.tileAssetInfo.showName;
    } else {
      return tileData.line2;
    }
  }

  public isTileLiveAudio(tileData: ITile): boolean {
    return (
      (tileData.tileContentType === ContentTypes.SHOW &&
        tileData.tileContentSubType === CarouselTypeConst.LIVE_AUDIO) ||
      (tileData.tileContentType === ContentTypes.CHANNEL &&
        !(tileData.tileContentSubType === CarouselTypeConst.ADDITIONAL_CHANNEL))
    );
  }

  public takeApiAction(
    neriticLinkData: INeriticLinkData,
    tile?: ITile,
    options?: NeriticLinkOptions,
  ): void {
    NeriticLinkService.logger.debug(`takeApiAction()`);

    switch (neriticLinkData.actionType) {
      case neriticActionConstants.TUNE:
        this.tuneNeriticAction(neriticLinkData, tile, options);
        break;
      default:
        this.invalidNeriticLinkSubject.next(neriticLinkData);
        NeriticLinkService.logger.error(
          `Encountered unsupported Neritic action "${neriticLinkData.actionType}".`,
        );
    }
  }

  public takeTileAppAction(tile: ITile): void {
    NeriticLinkService.logger.debug(`takeAppAction()`);

    let neriticLinkData = tile.primaryNeriticLink;

    /**
     * For Iris Podcast show tile, will redirecting to Show Edp page instead of Episode Listing page.
     */
    if (
      tile.tileContentType === ContentTypes.SHOW &&
      tile.tileAssetInfo.isPandoraPodcast
    ) {
      const customUrl =
        '?page-name=edp_show_enhanced&showGuid=' +
        neriticLinkData?.showGuid +
        '&channelGuid=' +
        neriticLinkData?.channelId;
      return this.navigateToEnhancedEdpPage(customUrl);
    }

    if (
      neriticLinkData.actionType.includes(
        CarouselTypeConst.ENHANCED_SHOW_EDP,
      ) ||
      neriticLinkData.actionType.includes(
        CarouselTypeConst.ENHANCED_CHANNEL_EDP,
      ) ||
      neriticLinkData.actionType.includes(
        CarouselTypeConst.ENHANCED_EPISODE_EDP,
      )
    ) {
      this.navigateToEnhancedEdpPage(neriticLinkData.actionType);
    }
    if (
      neriticLinkData.actionType.includes(
        CarouselTypeConst.ENHANCED_VOD_EPISODE_EDP,
      )
    ) {
      this.navigateToEpisodeListingWithPageUrl(
        tile.tileAssetInfo.channelId,
        tile.tileAssetInfo.showGuid,
        neriticLinkData.actionType,
        appRouteConstants.ON_DEMAND.VIDEO,
      );
    }
    if (
      neriticLinkData.actionType.includes(
        neriticActionConstants.ALL_ADDITIONAL_CHANNELS,
      )
    ) {
      this.navigationService.go([
        appRouteConstants.COLLECTION,
        'page-name=channels_all&function=onlyAdditionalChannels',
      ]);
      return;
    } else {
      switch (neriticLinkData.actionType) {
        case neriticActionConstants.ARCHIVE_VIEW_EPISODES:
        case neriticActionConstants.VIEW_VOD_EPISODES:
        case neriticActionConstants.CAROUSEL:
        case neriticActionConstants.CAROUSEL_ALL:
          this.navigateTileNeriticAction(tile);
          break;
        case neriticActionConstants.MANAGE_STATIONS:
          this.navigateToManageStations();
          break;
        default:
          this.invalidNeriticLinkSubject.next(neriticLinkData);
          NeriticLinkService.logger.error(
            `Encountered unsupported Neritic action "${neriticLinkData.actionType}".`,
          );
      }
    }
  }

  public takeAppAction(neriticLinkData: INeriticLinkData): void {
    NeriticLinkService.logger.warn(
      `takeAppAction( ${neriticLinkData.actionType} )`,
    );

    switch (neriticLinkData.actionType) {
      /**
       * AJB
       * These 4 have been moved to Post MVP
       * When it comes time to actually implement them
       * I believe we can do so here
       */
      // case neriticActionConstants.ARCHIVE_VIEW_EPISODES:
      // case neriticActionConstants.VIEW_AOD_EPISODES:
      // case neriticActionConstants.VIEW_VOD_EPISODES:
      // case neriticActionConstants.ARCHIVE:
      case neriticActionConstants.CAROUSEL:
        this.navigateNeriticAction(neriticLinkData);
        break;
      case neriticActionConstants.CATEGORIES:
        this.navigateNeriticAction(neriticLinkData);
        break;
      case neriticActionConstants.SIGN_UP:
        this.appSignUp();
        break;
      case neriticActionConstants.LINEAR_TUNER:
        this.invalidNeriticLinkSubject.next(neriticLinkData);
        NeriticLinkService.logger.error(
          `Per the neritic link confluence page this is not supported by Everest`,
        );
        break;
      case neriticActionConstants.WEB_LINK:
        let weblinkURL: string = neriticLinkData.url;

        if (weblinkURL) {
          window.open(neriticLinkData.url);
        } else {
          this.invalidNeriticLinkSubject.next(neriticLinkData);
          NeriticLinkService.logger.error(
            `something wrong with weblink parsing`,
          );
        }
        break;

      case neriticActionConstants.HOME:
        this.goHome();
        this.searchFocusSubject.next(false);
        break;

      case neriticActionConstants.FAVORITES:
        this.navigationService.go(
          [
            appRouteConstants.FAVORITES,
            appRouteConstants.CATEGORY_ROUTE_PARAMS.LIST_VIEW.CHANNELS,
          ],
          '',
        );
        break;

      case neriticActionConstants.LOG_IN:
        this.navigationService.go([appRouteConstants.AUTH.LOGIN]);
        break;

      case neriticActionConstants.SEARCH:
        NeriticLinkService.logger
          .warn(`Everest Web doesn't have a "Search Page" it only has a "Search Results Page".
                        Defaulting to the home page where there is a search bar`);
        this.goHome();
        this.searchFocusSubject.next(true);
        break;

      case neriticActionConstants.SETTINGS_HELP:
        /**
         * Can I use the ConfigService to get this URL?
         */
        window.open('https://listenercare.siriusxm.com/app/answers/list/p/908');
        break;

      case neriticActionConstants.SETTINGS:
      case neriticActionConstants.SETTINGS_SYSTEM_SETTINGS:
        NeriticLinkService.logger
          .warn(`Everest Web doesn't have a single "Settings" page, there are multiple kinds.
                    Defaulting to "Application Settings"`);

        this.navigationService.go([appRouteConstants.APPLICATION_SETTINGS]);

        break;

      case neriticActionConstants.NOW_PLAYING:
        this.navigationService.go([appRouteConstants.NOW_PLAYING]);
        break;

      case neriticActionConstants.ARCHIVE_VIEW_EPISODES:
      case neriticActionConstants.VIEW_AOD_EPISODES:
      case neriticActionConstants.VIEW_VOD_EPISODES:
      case neriticActionConstants.SETTINGS_LISTENER_SETTINGS:
      case neriticActionConstants.SETTINGS_LISTENER_SETTINGS_EDIT_AVATAR:
      case neriticActionConstants.SETTINGS_LISTENER_SETTINGS_EDIT_NAME:
      case neriticActionConstants.ARCHIVE:
        this.invalidNeriticLinkSubject.next(neriticLinkData);
        NeriticLinkService.logger.error(
          `Moved To Post MVP "${neriticLinkData.actionType}"`,
        );
        break;

      case neriticActionConstants.SETTINGS_LISTENING_HISTORY:
        this.navigationService.go([appRouteConstants.RECENTLY_PLAYED]);
        break;

      case neriticActionConstants.MANAGE_STATIONS:
        this.navigateToManageStations();
        break;
      case neriticActionConstants.CAROUSEL_ALL:
        this.carouselStoreService.selectViewAll(neriticLinkData.contentType);
        this.navigationService.go(
          [appRouteConstants.VIEW_ALL, neriticLinkData.contentType],
          { queryParamsHandling: 'merge' },
        );
        break;

      default:
        this.invalidNeriticLinkSubject.next(neriticLinkData);
        NeriticLinkService.logger.error(
          `Encountered unsupported Neritic action "${neriticLinkData.actionType}".`,
        );
    }
  }

  private goHome(): void {
    this.navigationService.go([appRouteConstants.HOME]);
  }

  /**
   * Takes the user to the signup page
   */
  private appSignUp(): void {
    /**
     * Relying on API configService response, but still need to hard-code the query string
     */
    let queryString: string = '?src=everestplayer&hideheader=true';
    let url: string = this.configService.getUrlConfiguration(
      'OnBoarding',
      'FLEPZ',
    );
    let newUrl = url.split('?')[0] + queryString;
    window.open(newUrl);
  }

  /**
   * Calls the tune on tuneClientService and passes a payload.
   *
   * @private
   * @param neriticLinkData is the neritic link to take action for
   * @memberof NeriticLinkService
   */
  public tuneNeriticAction(
    neriticLinkData: INeriticLinkData,
    tile?: ITile,
    options?: NeriticLinkOptions,
  ): void {
    NeriticLinkService.logger.debug(`tuneNeriticAction()`);

    let payload: TunePayload = {
      channel: this.channelLineupService.findChannelById(
        neriticLinkData.channelId,
      ),
      channelId: neriticLinkData.channelId,
      contentType: ContentTypes.LIVE_AUDIO,
      showGuid: neriticLinkData.showGuid || '',
      isPandoraPodcast:
        (tile && tile.tileAssetInfo.isPandoraPodcast) ||
        neriticLinkData.contentType === ContentTypes.PODCAST
          ? true
          : false,
      cutAssetGuid: tile && tile.cutAssetGuid ? tile.cutAssetGuid : '',
    };

    switch (neriticLinkData.contentType) {
      case ContentTypes.SHOW:
      case neriticActionConstants.LIVE_AUDIO:
      case neriticActionConstants.LIVE_VIDEO:
        payload.startTime = neriticLinkData.startTime;
        payload.contentType = ContentTypes.LIVE_AUDIO;

        if (
          this.configService.liveVideoEnabled() &&
          (neriticLinkData.contentSubType ===
            neriticActionConstants.LIVE_VIDEO ||
            neriticLinkData.contentType === neriticActionConstants.LIVE_VIDEO)
        ) {
          payload.contentType = ContentTypes.LIVE_VIDEO;
        }

        this.tuneClientService
          .tune(payload)
          .subscribe((responseCode: number) => {
            if (responseCode === ClientCodes.SUCCESS) {
              this.runPostTune(payload);

              if (
                this.navigationService.history.location.pathname !==
                appRouteConstants.NOW_PLAYING
              ) {
                this.navigationService.go([appRouteConstants.NOW_PLAYING]);
              }

              if (options && (<ICallbacks>options).onSuccess) {
                (<ICallbacks>options).onSuccess();
              }
            }
          });
        break;

      case ContentTypes.PODCAST:
      case ContentTypes.AOD:
        payload.contentType = ContentTypes.AOD;
        payload.episodeIdentifier = neriticLinkData.assetGuid;
        payload.channel = payload.isPandoraPodcast ? null : payload.channel;

        this.tuneClientService.tune(payload).subscribe(code => {
          if (code === ClientCodes.SUCCESS) {
            let openNowPlaying = true;

            if (
              options &&
              (<IAutoPlayTune>options).openNowPlaying !== undefined
            ) {
              openNowPlaying = (<IAutoPlayTune>options).openNowPlaying;
            }

            if (
              this.navigationService.history.location.pathname !==
                appRouteConstants.NOW_PLAYING &&
              openNowPlaying
            ) {
              this.navigationService.go([appRouteConstants.NOW_PLAYING]);
            }

            if (options && (<ICallbacks>options).onSuccess) {
              (<ICallbacks>options).onSuccess();
            }
          }
        });
        break;

      case ContentTypes.VOD:
        payload.contentType = ContentTypes.VOD;
        payload.episodeIdentifier = neriticLinkData.assetGuid;
        this.tuneClientService.tune(payload).subscribe(code => {
          if (code === ClientCodes.SUCCESS) {
            if (
              this.navigationService.history.location.pathname !==
              appRouteConstants.NOW_PLAYING
            ) {
              this.navigationService.go([appRouteConstants.NOW_PLAYING]);
            }

            if (options && (<ICallbacks>options).onSuccess) {
              (<ICallbacks>options).onSuccess();
            }
          }
        });
        break;

      case neriticActionConstants.ADDL_CHAN:
        payload.contentType = ContentTypes.ADDITIONAL_CHANNELS;
        this.tuneClientService.tune(payload).subscribe(code => {
          if (code === ClientCodes.SUCCESS) {
            if (
              this.navigationService.history.location.pathname !==
              appRouteConstants.NOW_PLAYING
            ) {
              this.navigationService.go([appRouteConstants.NOW_PLAYING]);
            }

            if (options && (<ICallbacks>options).onSuccess) {
              (<ICallbacks>options).onSuccess();
            }
          }
        });
        break;

      case neriticActionConstants.SEEDED_RADIO:
        payload.contentType = ContentTypes.SEEDED_RADIO;
        this.tuneClientService.tune(payload).subscribe(code => {
          if (code === ClientCodes.SUCCESS) {
            if (
              this.navigationService.history.location.pathname !==
              appRouteConstants.NOW_PLAYING
            ) {
              this.navigationService.go([appRouteConstants.NOW_PLAYING]);
            }

            if (options && (<ICallbacks>options).onSuccess) {
              (<ICallbacks>options).onSuccess();
            }
          }
        });
        break;
      case neriticActionConstants.CREATE_SEEDED_RADIO:
        /*const modalData: IModalData = this.translate.instant(
          'artistRadio.createSeededOverlay',
        );*/
        const modalData: IModalData = {} as IModalData;

        if (tile && tile.line2) {
          modalData.description = tile.line2;
        }
        if (options && (<IModalOptions>options).description) {
          modalData.description = (<IModalOptions>options).description;
        }
        modalData.logo.url += '?rand=' + Math.random() * 99999999;

        payload.contentType = ContentTypes.SEEDED_RADIO;

        payload.modelData = modalData;
        this.tuneClientService
          .tune(payload)
          .subscribe((responseCode: number) => {
            if (responseCode === ClientCodes.SUCCESS) {
              if (
                this.navigationService.history.location.pathname !==
                appRouteConstants.NOW_PLAYING
              ) {
                this.navigationService.go([appRouteConstants.NOW_PLAYING]);
              }

              if (options && (<ICallbacks>options).onSuccess) {
                (<ICallbacks>options).onSuccess();
              }
            }
          });
        break;

      default:
        this.invalidNeriticLinkSubject.next(neriticLinkData);
        NeriticLinkService.logger.error(
          `UNSUPORTED CONTENT TYPE ${neriticLinkData.contentType}`,
        );
    }
  }

  public navigateTileNeriticAction(tile: ITile): void {
    NeriticLinkService.logger.debug(`navigatTileNeriticAction()`);

    const neriticLinkData = tile.primaryNeriticLink;
    switch (neriticLinkData.contentType) {
      case neriticActionConstants.SHOW:
        const subContentType =
          neriticLinkData.contentSubType === ContentTypes.AOD
            ? appRouteConstants.ON_DEMAND.AUDIO
            : appRouteConstants.ON_DEMAND.VIDEO;
        const assetGuid = tile.tileAssetInfo.showGuid
          ? tile.tileAssetInfo.showGuid
          : neriticLinkData.assetGuid;
        this.navigateToEpisodeListing(
          neriticLinkData.channelId,
          assetGuid,
          CarouselTypeConst.EPISODE_EDP,
          subContentType,
        );
        break;

      case neriticActionConstants.CATEGORY:
        if (
          tile.primaryNeriticLink?.assetGuid ===
          neriticActionConstants.ALL_CHANNELS
        ) {
          this.navigationService.go([appRouteConstants.ALL_CHANNELS]);
          break;
        }
        if (
          tile.primaryNeriticLink?.assetGuid ===
          neriticActionConstants.ALL_VIDEOS
        ) {
          this.navigationService.go(
            [
              appRouteConstants.CATEGORY_PAGE,
              appRouteConstants.ALL_VIDEO,
              '?comingFromSearch=true',
            ],
            '',
          );
          focusManager.changeFocus(PODCASTSVIDEO.PAGE);
          break;
        }

        const currentPageName = this.navigationService.activeRoute.url.split(
          '/',
        )[2];
        const fromPodcasts = currentPageName === appRouteConstants.ALL_PODCASTS;
        const commands = [
          appRouteConstants.CATEGORY,
          tile.superCategoryInfo.key,
          tile.subCategoryInfo.key,
          fromPodcasts ? displayViewType.shows : displayViewType.channels,
        ];

        this.navigationService.go(commands);
        break;

      case neriticActionConstants.SUPER_CATEGORY:
        if (!tile.superCategoryInfo.key) return;
        this.navigationService.go(
          [
            appRouteConstants.HOME,
            tile.superCategoryInfo.key,
            '?comingFromInternal=true',
          ],
          '',
        );
        break;

      case neriticActionConstants.COLLECTION:
        this.navigationService.go(
          [appRouteConstants.COLLECTION, neriticLinkData.url],
          { queryParamsHandling: 'merge' },
        );
        break;

      case neriticActionConstants.MEGA_STARS:
        this.navigationService.go(
          [appRouteConstants.CATEGORY_PAGE, neriticLinkData.contentType],
          { queryParamsHandling: 'merge' },
        );
        break;

      case neriticActionConstants.ALL_VIDEOS:
        this.navigationService.go(
          [
            appRouteConstants.CATEGORY_PAGE,
            appRouteConstants.ALL_VIDEO,
            '?comingFromInternal=true',
          ],
          '',
        );
        break;

      case neriticActionConstants.ALL_PODCASTS:
        this.navigationService.go(
          [
            appRouteConstants.CATEGORY_PAGE,
            appRouteConstants.ALL_PODCASTS,
            '?comingFromInternal=true',
          ],
          '',
        );
        break;

      case neriticActionConstants.VIEW_ALL:
        // this.progressCursorService.startSpinning();
        this.carouselStoreService.selectViewAll(neriticLinkData.assetGuid);
        this.navigationService.go(
          [appRouteConstants.VIEW_ALL, neriticLinkData.assetGuid],
          { queryParamsHandling: 'merge' },
        );
        break;

      default:
        this.navigateNeriticAction(neriticLinkData);
    }
  }

  public navigateNeriticAction(neriticLinkData: INeriticLinkData): void {
    NeriticLinkService.logger.debug(`navigateNeriticAction()`);

    switch (neriticLinkData.contentType) {
      case neriticActionConstants.SHOW:
        const subContentType =
          neriticLinkData.contentSubType === ContentTypes.AOD
            ? appRouteConstants.ON_DEMAND.AUDIO
            : appRouteConstants.ON_DEMAND.VIDEO;
        const assetGuid = neriticLinkData.showGuid;
        this.navigateToEpisodeListing(
          neriticLinkData.channelId,
          assetGuid,
          CarouselTypeConst.EPISODE_EDP,
          subContentType,
        );
        break;

      case neriticActionConstants.CATEGORY_HOWARD_STERN:
      case neriticActionConstants.SUPER_CATEGORY_HOWARD:
      case neriticActionConstants.HOWARD_STERN:
        this.navigationService.go(
          [appRouteConstants.HOME, appRouteConstants.HOWARD_SUPERCATEGORY],
          '',
        );
        break;

      case neriticActionConstants.SUPER_CATEGORY_FOR_YOU:
      case neriticActionConstants.FOR_YOU:
        this.navigationService.go(
          [appRouteConstants.HOME, appRouteConstants.FOR_YOU],
          '',
        );
        break;

      case neriticActionConstants.SUPER_CATEGORY_MUSIC:
        this.navigationService.go(
          [appRouteConstants.HOME, appRouteConstants.MUSIC],
          '',
        );
        break;

      case neriticActionConstants.SUPER_CATEGORY_NEWS:
        this.navigationService.go(
          [appRouteConstants.HOME, appRouteConstants.NEWS],
          '',
        );
        break;

      case neriticActionConstants.SUPER_CATEGORY_SPORTS:
        this.navigationService.go(
          [appRouteConstants.HOME, appRouteConstants.SPORTS],
          '',
        );
        break;

      case neriticActionConstants.SUPER_CATEGORY_ENTERTAINMENT:
        this.navigationService.go(
          [appRouteConstants.HOME, appRouteConstants.ENTERTAINMENT],
          '',
        );
        break;

      case neriticActionConstants.RECENTS:
        this.navigationService.go([appRouteConstants.RECENTLY_PLAYED]);
        break;

      case neriticActionConstants.CHANNEL:
        this.invalidNeriticLinkSubject.next(neriticLinkData);
        NeriticLinkService.logger.warn(
          `This is still TBD according to confluence docs`,
        );
        break;

      case neriticActionConstants.COLLECTION:
        this.navigationService.go(
          [appRouteConstants.COLLECTION, neriticLinkData.url],
          { queryParamsHandling: 'merge' },
        );
        break;

      case neriticActionConstants.ALL_CHANNELS:
        this.navigationService.go([appRouteConstants.ALL_CHANNELS]);
        break;

      case neriticActionConstants.ALL_VIDEOS:
        this.navigationService.go(
          [appRouteConstants.CATEGORY_PAGE, appRouteConstants.ALL_VIDEO],
          '',
        );
        break;

      case neriticActionConstants.ALL_PODCASTS:
        this.navigationService.go(
          [appRouteConstants.CATEGORY_PAGE, appRouteConstants.ALL_PODCASTS],
          '',
        );
        break;

      case neriticActionConstants.ALL_ADDITIONAL_CHANNELS:
        this.navigationService.go(
          [appRouteConstants.COLLECTION, neriticLinkData.url],
          { queryParamsHandling: 'merge' },
        );
        break;

      case neriticActionConstants.VIEW_ALL:
        this.carouselStoreService.selectViewAll(neriticLinkData.assetGuid);
        this.navigationService.go(
          [appRouteConstants.VIEW_ALL, neriticLinkData.assetGuid],
          { queryParamsHandling: 'merge' },
        );
        break;

      case neriticActionConstants.EDP_SHOW_ENHANCED:
        this.navigationService.go([
          appRouteConstants.ENHANCED_EDP,
          neriticLinkData.url,
          { queryParamsHandling: 'merge' },
        ]);
        break;

      case neriticActionConstants.CATEGORY:
        const commands = [
          appRouteConstants.CATEGORY,
          neriticLinkData.url,
          displayViewType.ondemand,
        ];
        this.navigationService.go(commands);
        break;

      default:
        this.invalidNeriticLinkSubject.next(neriticLinkData);
        NeriticLinkService.logger.error(
          `UNSUPORTED CONTENT TYPE "${neriticLinkData.contentType}"`,
        );
    }
  }

  /**
   * Navigate the user to Category Page where the "On Demand" Tab is preselected and displays a list of shows for a
   * selected channel
   * @param {IChannel} channel
   */
  public navigateToShowsList(channel: IChannel): void {
    this.carouselStoreService.selectSubCategory(
      channel.firstSubCategory,
      channel.channelId,
    );

    this.navigationService.go([
      appRouteConstants.CATEGORY,
      channel.firstSuperCategory.key,
      channel.firstSubCategory.key,
      displayViewType.shows,
      channel.channelId,
    ]);
  }

  /**
   * Navigates you to the on demand episode listing for the show related to the selected episode
   */
  public navigateToEpisodeListing(
    channelId: string,
    showGuid: string,
    pageName: string,
    type: string,
  ): void {
    const url = `${CarouselConsts.PAGE_NAME}=${pageName}&showGuid=${showGuid}`;
    this.navigationService.go([
      appRouteConstants.ON_DEMAND.EPISODES_LIST,
      channelId,
      showGuid,
      type,
      url,
    ]);
    //update the channel in the channel list store
    const channel = this.channelLineupService.findChannelById(channelId);
    this.channelListStoreService.selectChannel(channel);
  }

  /**
   * Navigates you to the on demand episode listing for the show related to the selected episode
   */
  public navigateToEpisodeListingWithPageUrl(
    channelId: string,
    showGuid: string,
    pageUrl: string,
    type: string,
  ): void {
    const pageUrlSplit = pageUrl.split('?');
    this.navigationService.go([
      appRouteConstants.ON_DEMAND.EPISODES_LIST,
      channelId,
      showGuid,
      type,
      pageUrlSplit[1],
    ]);
    //update the channel in the channel list store
    const channel = this.channelLineupService.findChannelById(channelId);
    this.channelListStoreService.selectChannel(channel);
  }

  /**
   * Navigates you to the enhanced EDP page for the selected channel/show/episode
   */
  public navigateToEnhancedEdpPage(url: string): void {
    const urlSplit = url.split('?');
    this.navigationService.go([appRouteConstants.ENHANCED_EDP, urlSplit[1]], {
      queryParamsHandling: 'merge',
    });
  }

  /**
   * Navigates users to Manage pandora stations page
   */
  public navigateToManageStations(): void {
    this.navigationService.go([appRouteConstants.SEEDED_SETTINGS]);
  }

  /**
   * toggles favorites for selected tile
   */
  public toggleFavorite(
    isFavorite: boolean,
    tileContentType: string,
    tileContentSubType: string,
    channelId: string,
    assetGuid: string,
    title: string,
    isIrisPodcast: boolean,
  ): void {
    this.favoriteListStoreService.toggleFavorite(
      isFavorite,
      tileContentType,
      tileContentSubType,
      channelId,
      assetGuid,
      isIrisPodcast,
    );
    this.displayToastMessage(isFavorite, tileContentType, title);
  }

  private displayToastMessage(isFavorite, tileContentType, title) {
    const contentFavoriteState = isFavorite ? 'unFavorite' : 'Favorite';
    tileContentType =
      tileContentType === CarouselTypeConst.LIVE_SHOW_TILE
        ? CarouselTypeConst.SHOW_TILE
        : tileContentType;
    const toastTitle: string =
      tileContentType === CarouselTypeConst.EPISODE_TILE ? `"${title}"` : title;

    this.toastService.open({
      customMessage: toastTitle,
      messagePath: `nowPlaying.toastFavorites.${tileContentType}${contentFavoriteState}`,
      isAltColor: true,
      hasCloseButton: true,
      closeToastTime: 10000,
    });
  }

  /**
   * Sets a show reminder
   */
  public updateShowReminder(
    showReminderSet: boolean,
    tileAssetInfo?: ITileAssetInfo,
    tileContentType?: string,
  ): void {
    if (!tileAssetInfo || !tileContentType) {
      alert(
        'Set Show Reminder is currently out of scope. This is a placeholder.',
      );
    }

    showReminderSet
      ? this.alertClientService.removeAlert(
          null,
          tileAssetInfo.showGuid,
          AlertType.SHOW,
        )
      : this.alertClientService.createAlert(
          tileAssetInfo.channelId,
          tileAssetInfo.showGuid,
          AlertType.SHOW,
        );
  }

  public updateLiveVideoReminder(
    liveVideoReminderSet: boolean,
    tileAssetInfo?: ITileAssetInfo,
    tileContentType?: string,
  ): void {
    liveVideoReminderSet
      ? this.alertClientService.removeAlert(
          null,
          tileAssetInfo.showGuid,
          AlertType.LIVE_VIDEO_START,
        )
      : this.alertClientService.createAlert(
          tileAssetInfo.channelId,
          tileAssetInfo.showGuid,
          AlertType.LIVE_VIDEO_START,
        );
  }

  /**
   * Removes the selected recent tile from recents carousel
   * @param {ITile} tile
   */
  public removeRecentlyPlayedTile(tile: ITile): void {
    this.recentlyPlayedStoreService.removeRecentlyPlayedItem(tile);
  }

  /**
   * Removes the show reminder
   * @param {string} alertGuid
   * @param {string} showGuid
   * @param alertType
   */
  public removeShowReminder(
    alertGuid: string,
    showGuid: string,
    alertType: AlertType,
  ): void {
    this.alertClientService.removeAlert(alertGuid, showGuid, alertType);
  }

  public goToShowReminderDetails(showGuid: string): void {
    this.navigationService.go([
      appRouteConstants.SHOW_REMINDER_DETAILS,
      showGuid,
    ]);
  }

  /**
   * Checks to see if an optional neritic action needs to be run
   * @param optAction
   * @param showGUID
   */
  public checkOptionalNeriticActionFlag(optAction, showGUID) {
    if (this.optionalNeriticActionFlags[optAction]) {
      // we only have 1 flag so this func
      // just handles the one case.
      this.optionalNeriticActionFlags[optAction] = false;
      if (
        !this.alertClientService.getAlert(showGUID, AlertType.LIVE_VIDEO_START)
      ) {
        const clientFault = {
          faultCode: AppErrorCodes.FAUX_LIVE_VIDEO_REMINDER_MODAL,
          context: { showGUID: showGUID },
        } as ClientFault;

        this.appMonitorService.triggerFaultError(clientFault);
      }
    }
  }

  /**
   * Uses successful live video tune response to trigger optional neritic action
   * @param payload
   */
  public runPostTune(payload): void {
    if (payload.contentType === ContentTypes.LIVE_VIDEO) {
      this.showSubscription = this.nowPlayingStoreService.show$
        .pipe(
          filter((show: IMediaShow) => !!(show && show.assetGUID)),
          take(1),
        )
        .subscribe((show: IMediaShow) => {
          if (show.isLiveVideoEligible) {
            this.checkOptionalNeriticActionFlag(
              OptionalNeriticAction.CHECK_SHOW_FOR_VIDEO_REMINDER,
              show.assetGUID,
            );
          }
        });
    }
  }
}
