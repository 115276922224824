import React from 'react';
import classNames from 'classnames';
import Button from '../button/Button';
import styles from './result-item.module.scss';

type props = {
  onClick: (id: string) => void;
  item: any;
  nav: XDKNav;
  className: string;
  classNameFocused: string;
  classNameIcon: string;
  classNameText: string;
  classNameOnFocus?: string;
};

const ResultItem = ({
  onClick,
  item: { text, src, value },
  nav,
  className,
  classNameFocused,
  classNameIcon,
  classNameText,
  classNameOnFocus = '',
}: props) => {
  return (
    <Button
      nav={nav}
      className={classNames(className)}
      classNameFocused={classNameFocused}
      onClick={() => {
        onClick(value);
      }}
    >
      <div className={styles['item-centered']}>
        {typeof src === 'object' ? (
          React.createElement(src, { className: classNameIcon })
        ) : (
          <img src={src} className={classNameIcon} />
        )}
      </div>
      <div className={styles['item-centered']}>
        <span className={classNameText}>{text}</span>
      </div>
    </Button>
  );
};

export default ResultItem;
