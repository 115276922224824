import { getServiceConfig } from '../sxmservicelayer/service.config.utils';
import FrenchCa from '../../src/assets/i18n/fr.json';
import EnglishCa from '../../src/assets/i18n/en-ca.json';
import English from '../../src/assets/i18n/en.json';
import { storageManager } from '../config/storage-manager';
import { StorageKeyConstant } from '../servicelib';

declare var webapis: any;

const hashCode = (strJson, str) => {
  const arr = str.split('.');
  let result = strJson[arr[0]];
  for (let i = 1; i < arr.length; i++) {
    result = result[arr[i]];
  }
  return result;
};

export const getTvLocation = () =>
  storageManager.cloudCookieManager.get(StorageKeyConstant.APP_REGION);

export const getTvLanguage = () => {
  const deviceLanguage = navigator.language || '';
  let [language] = deviceLanguage.split('-');
  return language;
};

export const translate = keyText => {
  let text = '';
  try {
    const getLocation = getTvLocation();
    let language = getTvLanguage();
    if (getLocation === 'CA') {
      if (language === 'fr') {
        text = hashCode(FrenchCa, keyText);
      } else {
        text = hashCode(EnglishCa, keyText);
      }
    } else {
      text = hashCode(English, keyText);
    }
  } catch (e) {
    text = hashCode(English, keyText);
  }
  return text;
};
