import React, { useEffect, useState } from 'react';
import { combineLatest, timer, of } from 'rxjs';
import { useDispatch } from 'react-redux';
import { switchMap, catchError } from 'rxjs/operators';
import { useHistory } from 'react-router-dom';
import xdk, { device } from '@accedo/xdk-core';
import { ReactComponent as SiriusXMLogo } from '../../assets/images/sxm-logo-blue.svg';
import { appRouteConstants } from '../../routing/app.route.constants';
import {
  ServiceFactory,
  InitializationService,
  InitializationStatusCodes,
  ResumeService,
  AppMonitorService,
  AuthenticationService,
  ConfigService,
  SessionMonitorService,
  Logger,
  StorageService,
  StorageKeyConstant,
  ApiDelegate,
  ApiCodes,
} from '../../servicelib';
import './splash-page.component.scss';
import { CarouselTileService } from '../../sxmservicelayer/carousel-tile/carousel-tile.service';
import { SXMServiceLayerModule } from '../../sxmservicelayer/sxm.service.layer.module';
import { BrowserUtil } from '../../utils/browser.util';
import { zoneCategory } from '../../components/navigation-menu/navigation-menu-utils';
import { NeriticLinkService } from '../../sxmservicelayer/neritic-links';
import { storageManager } from '../../config/storage-manager';
import { systemManager } from '../../config/system-manager';
import axios from 'axios';
import { terminateFreeTrial } from '../../utils/free-trial-expiration.util';
import {
  showPodcastsMenuOption,
  showVideosMenuOption,
} from '../../redux/action/xdk.action';
import { getTvLanguage } from '../../utils/translate.util';

const payload = {
  moduleList: {
    modules: [
      {
        moduleType: 'PartnerDevices',
        moduleRequest: {
          deviceInfo: {
            osVersion: 'webOS',
            sxmAppVersion: process.env.REACT_APP_VERSION,
            oem: 'LG',
            clientDeviceId: '',
            deviceModel: 'xxxx',
            platform: 'Home',
            mobileCarrier: '',
            deviceMake: 'lgeverest',
          },
        },
        moduleArea: 'Routing',
      },
    ],
  },
};
const DEEPLINKING_PARAMETERS = {
  LAUNCHPOINT: 'launchpoint',
  QUERY: 'query',
  ASSET_ID: 'asstedId',
  ASSET_TYPE: 'assetType',
  ENTITY_ID: 'entityId',
  SECTION_NAME: 'sectionName',
  LIMITED_AD_TRACKING: 'lmt',
  US_PRIVACY: 'us_privacy',
};
const LAUNCHPOINT_VALUES = {
  HOME: 'home',
  DETAIL: 'detail',
  PLAYBACK: 'playback',
  SEARCH: 'search',
  SECTION: 'section',
};

declare const webOS;

const useSplashPageComponent = shouldDismissSplashScreen => {
  const history = useHistory();

  const [isStorageManagerLoaded, setIsStorageManagerLoaded] = useState(false);
  useEffect(() => {
    xdk.load().then(response => {
      const platform = response.getId();
      device.storageManager
        .getStorage(platform === 'lg-webos' ? 'local' : 'cookie')
        .then(storage => {
          systemManager.system = xdk.system;
          payload.moduleList.modules[0].moduleRequest.deviceInfo.osVersion =
            'webOS ' +
            (xdk.system.getwebosTVVersion && xdk.system.getwebosTVVersion());
          payload.moduleList.modules[0].moduleRequest.deviceInfo.deviceModel = `LG ${xdk.system.getModel()}`;

          storageManager.cloudCookieManager = storage;
          setIsStorageManagerLoaded(true);
        });
    });
  }, []);

  const [appRegion, setAppRegion] = useState('');
  useEffect(() => {
    if (isStorageManagerLoaded) {
      try {
        webOS.service.request('luna://com.webos.settingsservice', {
          method: 'getSystemSettings',
          parameters: {
            category: 'option',
            keys: ['smartServiceCountryCode2'],
          },
          onSuccess: inResponse => {
            const { settings } = inResponse;
            const { smartServiceCountryCode2 } = settings;
            storageManager.cloudCookieManager.set(
              StorageKeyConstant.APP_REGION,
              smartServiceCountryCode2 === 'CAN' ||
                smartServiceCountryCode2 === 'CA'
                ? 'CA'
                : 'US',
            );
            setAppRegion(smartServiceCountryCode2);
          },
          onFailure: inError => {
            console.error(inError);
            throw new Error();
          },
        });
      } catch (e) {
        console.error('Luna api unavailable');
        const language = navigator.language || '';
        let [, appRegionFromLanguage] = language.split('-');
        appRegionFromLanguage = (appRegionFromLanguage || '').toUpperCase();
        storageManager.cloudCookieManager.set(
          StorageKeyConstant.APP_REGION,
          appRegionFromLanguage === 'CA' ? appRegionFromLanguage : 'US',
        );
        setAppRegion(appRegionFromLanguage);
      }
    }
  }, [isStorageManagerLoaded]);

  //TODO: The default device id must be set as '' to ensure it is retrieved first once app can be tested on devices
  const [clientDeviceId, setClientDeviceId] = useState('');
  useEffect(() => {
    if (appRegion) {
      try {
        systemManager.system
          .getUniqueId()
          .then(duid => {
            storageManager.cloudCookieManager.set(
              StorageKeyConstant.CLIENT_DEVICE_ID,
              duid,
            );
            setClientDeviceId(duid);
          })
          .catch(error => {
            throw new Error();
          });
      } catch (e) {
        console.error('Unable to retrieve clientDeviceId');
        storageManager.cloudCookieManager.set(
          StorageKeyConstant.CLIENT_DEVICE_ID,
          'THSS68gopST2tWSFObl9LPGftwgvLQaUuvof8gZF9kmsie',
        );
        setClientDeviceId('THSS68gopST2tWSFObl9LPGftwgvLQaUuvof8gZF9kmsie');
      }
    }
  }, [appRegion]);

  const [isHostRoutingComplete, setIsHostRoutingComplete] = useState(false);
  useEffect(() => {
    if (clientDeviceId) {
      payload.moduleList.modules[0].moduleRequest.deviceInfo.clientDeviceId = clientDeviceId;

      axios(`${BrowserUtil.hostname}/rest/v2/experience/modules/get/partner`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(payload),
      })
        .catch(() => {
          return {
            json: () => {},
          };
        })
        .then(response => {
          let routingUrl;
          try {
            routingUrl = (response as any).data['ModuleListResponse'].moduleList
              .modules[0].moduleResponse.partnerDeviceList.partnerDevices[0]
              .routingUrl;
          } catch (error) {
            routingUrl = BrowserUtil.getApiEndpoint();
          } finally {
            BrowserUtil.hostname = routingUrl;
            SXMServiceLayerModule.injectAppConfig();
            setIsHostRoutingComplete(true);
          }
        });
    }
  }, [clientDeviceId]);

  const [freeTrialDuratonText, setFreeTrialDurationText] = useState('');
  useEffect(() => {
    if (isHostRoutingComplete) {
      const appRegion = storageManager.cloudCookieManager.get(
        StorageKeyConstant.APP_REGION,
      );
      const lang = getTvLanguage();
      fetch(
        `${BrowserUtil.hostname}/rest/v5/experience/screenInfo?screenName=iap_screens&platform=web&language=${lang}&app-region=${appRegion}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
        .catch(() => {
          return {
            json: () => {},
          };
        })
        .then(response => {
          return response.json();
        })
        .then(response => {
          let freeTrialDuration = 'Try FREE for 3 months';
          let platinumPlanFields = {
            planTitle: 'All Access (App Only)',
            planLine1:
              'Ad-free music for every genre & decade plus artist-created channels',
            planLine2:
              'SiriusXM video library of in-studio shows & performances',
            planLine3: '2 Howard Stern channels, including video',
            planAction: 'Get 3 months free',
            planPrice: 'then $9.99 a month after • cancel anytime.',
          };
          try {
            const iapWelcomeScreen = response[
              'screenInfoResponse'
            ].screenInfo[0].screens.find(screen => {
              return screen.id === 'iap_welcome_screen';
            });
            freeTrialDuration = iapWelcomeScreen.fields.find(
              field => field.name === 'button1',
            ).value;
            const iapWelcomePlanScreen = response[
              'screenInfoResponse'
            ].screenInfo[0].screens.find(screen => {
              return screen.id === 'iap_welcome_plan_screen';
            });
            const planTitle = iapWelcomePlanScreen.fields.find(
              field => field.name === 'plan-title',
            ).value;
            const planDescriptionFields = iapWelcomePlanScreen.fields.find(
              field => field.name === 'plan-description',
            ).fields;
            const planLine1 = planDescriptionFields.find(
              field => field.name === 'line1',
            ).value;
            const planLine2 =
              'SiriusXM video library of in-studio shows & performances';
            const planLine3 = planDescriptionFields.find(
              field => field.name === 'line2',
            ).value;
            const planAction = iapWelcomePlanScreen.fields.find(
              field => field.name === 'plan-action',
            ).value;
            const planPrice = iapWelcomePlanScreen.fields.find(
              field => field.name === 'plan-price',
            ).value;
            platinumPlanFields = {
              planTitle,
              planLine1,
              planLine2,
              planLine3,
              planAction,
              planPrice: `${planPrice.charAt(0).toLowerCase()}${planPrice.slice(
                1,
              )}`,
            };
          } finally {
            storageManager.cloudCookieManager.set(
              StorageKeyConstant.FREE_TRIAL_DURATION_TEXT,
              freeTrialDuration,
            );
            storageManager.cloudCookieManager.set(
              StorageKeyConstant.PLATINUM_PLAN_TEXT,
              JSON.stringify(platinumPlanFields),
            );
            setFreeTrialDurationText(freeTrialDuration);
          }
        });

      fetch(
        `${BrowserUtil.hostname}/rest/v5/experience/screenInfo?screenName=iap_rokutv_screens&platform=web&language=${lang}&app-region=${appRegion}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
        .catch(() => {
          return {
            json: () => {},
          };
        })
        .then(r => r.json())
        .then(r => {
          let readyListen = "You're ready to listen to 400+ channels!";
          let readyListenTitle = 'Welcome to SiriusXM';
          try {
            readyListen = '';
            const iapConfirmation = r[
              'screenInfoResponse'
            ].screenInfo[0].screens.find(screen => {
              return screen.id === 'iap_confirmation';
            });
            readyListenTitle = iapConfirmation.fields.find(
              field => field.name === 'header',
            ).value;
            readyListen = iapConfirmation.fields.find(
              field => field.name === 'text1',
            ).value;
          } finally {
            storageManager.cloudCookieManager.set(
              StorageKeyConstant.READY_TO_LISTEN,
              readyListen,
            );
            storageManager.cloudCookieManager.set(
              StorageKeyConstant.READY_TO_LISTEN_TITLE,
              readyListenTitle,
            );
          }
        });
    }
  }, [isHostRoutingComplete]);

  //Imports the services that are used at application boot so their instances can be created
  //and the initialization process gets triggered.
  let initializationService;
  let resumeService;
  let appMonitorService;
  let authenticationService;
  let configService;
  let sessionMonitorService;
  let carouselTileService;
  if (freeTrialDuratonText) {
    Logger.isEnabled = true;
    Logger.level = Logger.LEVEL_ERROR;
    initializationService = ServiceFactory.getInstance(
      InitializationService,
    ) as InitializationService;
    resumeService = ServiceFactory.getInstance(ResumeService) as ResumeService;
    appMonitorService = ServiceFactory.getInstance(
      AppMonitorService,
    ) as AppMonitorService;
    authenticationService = ServiceFactory.getInstance(
      AuthenticationService,
    ) as AuthenticationService;
    configService = ServiceFactory.getInstance(ConfigService) as ConfigService;
    sessionMonitorService = ServiceFactory.getInstance(
      SessionMonitorService,
    ) as SessionMonitorService;
    carouselTileService = ServiceFactory.getInstance(CarouselTileService);
  }
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      initializationService &&
      resumeService &&
      appMonitorService &&
      authenticationService &&
      configService &&
      sessionMonitorService &&
      carouselTileService
    ) {
      let sessionMessage: any = {};
      const sessionMessageSubscription = sessionMonitorService.sessionMessageSubject$.subscribe(
        value => {
          sessionMessage = value;
        },
      );

      const freeTrialStatus = { isExpired: undefined };
      const apiDelegate = ServiceFactory.getInstance(
        ApiDelegate,
      ) as ApiDelegate;
      const apiDelegateSubscription = apiDelegate.addApiCodeHandler(
        ApiCodes.FREE_TIER_EXPIRED,
        (codes, apiMessages, response) => {
          freeTrialStatus.isExpired = true;
        },
      );

      const subscription = combineLatest({
        isResumeComplete: resumeService.resumeIsComplete$,
        initState: initializationService.initState,
        delay: timer(1000),
      })
        .pipe(
          switchMap(({ isResumeComplete, initState }) => {
            if (
              isResumeComplete === true &&
              (initState === InitializationStatusCodes.UNAUTHENTICATED ||
                initState === InitializationStatusCodes.RUNNING)
            ) {
              return of(sessionMessage);
            } else if (
              isResumeComplete === false &&
              initState === InitializationStatusCodes.UNAUTHENTICATED
            ) {
              //Means resume still needs to be done for the first time to determine if OpenAccess is available or not.
              return resumeService.resume();
            }
          }),
          catchError((err, caught) => caught),
        )
        .subscribe((result: any) => {
          if (freeTrialStatus && freeTrialStatus.isExpired) {
            authenticationService.logout().subscribe({
              next: () => {
                dispatch(showPodcastsMenuOption(false));
                dispatch(showVideosMenuOption(false));
                terminateFreeTrial();
                history.replace(appRouteConstants.AUTH.OPEN_ACCESS_EXPIRED);
              },
            });
          } else {
            const launchpoint = BrowserUtil.getQueryParameterValue(
              DEEPLINKING_PARAMETERS.LAUNCHPOINT,
            );
            const entityId = BrowserUtil.getQueryParameterValue(
              DEEPLINKING_PARAMETERS.ENTITY_ID,
            );
            const assetId = BrowserUtil.getQueryParameterValue(
              DEEPLINKING_PARAMETERS.ASSET_ID,
            );
            const query = BrowserUtil.getQueryParameterValue(
              DEEPLINKING_PARAMETERS.QUERY,
            );
            const sectionName = BrowserUtil.getQueryParameterValue(
              DEEPLINKING_PARAMETERS.SECTION_NAME,
            );

            if (!launchpoint || launchpoint === LAUNCHPOINT_VALUES.HOME) {
              history.replace(appRouteConstants.FT_WELCOME);
            } else if (launchpoint === LAUNCHPOINT_VALUES.DETAIL && entityId) {
              //It is assumed that this will be an on-demand episode listing screen
              const subpath = decodeURIComponent(entityId);
              history.replace(
                `${appRouteConstants.ON_DEMAND.EPISODES_LIST}/${subpath}`,
              );
            } else if (launchpoint === LAUNCHPOINT_VALUES.PLAYBACK && assetId) {
              //It is assumed that assetId will include the encoded neritic link data
              const neriticLinkDataString = decodeURIComponent(assetId);
              let neriticLinkData: any = {};
              neriticLinkDataString.split('&').forEach(param => {
                const [key, value] = param.split('=');
                neriticLinkData = {
                  ...neriticLinkData,
                  [key]: value,
                };
              });

              history.replace(appRouteConstants.HOME_FORYOU);
              const neriticLinkService = ServiceFactory.getInstance(
                NeriticLinkService,
              ) as NeriticLinkService;

              const options: any = {
                onSuccess: () => {
                  const storageService = ServiceFactory.getInstance(
                    StorageService,
                  ) as StorageService;
                  storageService.setItem(
                    StorageKeyConstant.IS_DEEPLINKED,
                    true,
                  );
                },
              };
              neriticLinkService.tuneNeriticAction(
                neriticLinkData,
                null,
                options,
              );
            } else if (launchpoint === LAUNCHPOINT_VALUES.SEARCH && query) {
              history.replace(`${appRouteConstants.SEARCH}/${query}`);
            } else if (
              launchpoint === LAUNCHPOINT_VALUES.SECTION &&
              sectionName
            ) {
              const superCategory = zoneCategory.discoverZone.buttons.some(
                button => button.superCategory === sectionName,
              );

              if (superCategory) {
                if (
                  sectionName === 'all_podcasts' ||
                  sectionName == 'all_video'
                ) {
                  history.replace(
                    `${appRouteConstants.CATEGORY_PAGE}/${sectionName}`,
                  );
                } else {
                  history.replace(`${appRouteConstants.HOME}/${sectionName}`);
                }
              } else {
                history.replace(appRouteConstants.HOME_FORYOU);
              }
            } else {
              history.replace(appRouteConstants.FT_WELCOME);
            }
          }

          shouldDismissSplashScreen(true);
        });

      return () => {
        apiDelegateSubscription.removeCodeHandler();
        subscription.unsubscribe();
        sessionMessageSubscription.unsubscribe();
      };
    }
  }, [
    initializationService,
    resumeService,
    appMonitorService,
    authenticationService,
    configService,
    carouselTileService,
  ]);
};

export const SplashPageComponent = props => {
  useSplashPageComponent(props.shouldDismissSplashScreen);

  return (
    <div className="splash-page">
      <div className="splash-logo-container">
        <div>
          <SiriusXMLogo />
          <div className="wave-cover" />
        </div>
      </div>
      <span className="gotham-black"> </span>
      <span className="gotham-medium"> </span>
      <span className="gotham-bold"> </span>
      <span className="gotham-book"> </span>
      <span className="gotham-narrow-ssm-book"> </span>
      <span className="gotham-narrow-ssm-medium"> </span>
      <span className="gotham-narrow-ssm-black"> </span>
    </div>
  );
};
