import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectNowPlayingUpNextCarousel } from '../../redux/selector/carousel.store';
import {
  getChannel,
  getEpisode,
  getShow,
  getIsAod,
  getIsVideo,
  getCut,
  getContentType,
} from '../../redux/selector/now-playing.store';
import UpNextActionButton from './up-next-action-button.component';
import { ReactComponent as PlayIcon } from '../../assets/images/play.svg';
import {
  ServiceFactory,
  TunePayload,
  ContentTypes,
  ClientCodes,
  NAME_COLOR_CHANNEL_LOGO,
  MediaPlayerService,
  ConfigService,
} from '../../servicelib';
import { NeriticLinkService } from '../../sxmservicelayer/neritic-links';
import './up-next.component.scss';
import { focusManager } from '@accedo/vdkweb-navigation';
import { TuneClientService } from '../../sxmservicelayer/tune/tune.client.service';
import { UP_NEXT } from '../../utils/navigationMap';
import { CarouselStoreService } from '../../sxmservicelayer/carousel-store/carousel.store.service';
import { appRouteConstants } from '../../routing/app.route.constants';

const startingInTimeout = { id: undefined, DELAY: 1000 };
const nowPlayingNavId = 'now-playing';
const upNext = { tile: {} };

const UpNext = ({ setShowUpNext, showUpNext }) => {
  const nowPlayingUpNextCarousel = useSelector(selectNowPlayingUpNextCarousel);
  const { zone = [] } = nowPlayingUpNextCarousel || {};
  const [zoneData] = zone;
  const { content = [] } = zoneData || {};
  const [contentData] = content;
  const { tiles = [] } = contentData || {};
  const [upNextTile] = tiles;
  const { channelInfo, bgImageUrl, iconImageUrl, line1$, line2$ } =
    upNextTile || {};

  //Used to hold reference and avoid getting undefined inside the subscription closure
  upNext.tile = upNextTile;
  const configService = ServiceFactory.getInstance(
    ConfigService,
  ) as ConfigService;
  const upNextDelay = configService.getAutoplayNextEpisodeDelay();
  const [countdown, setCountdown] = useState(upNextDelay || 5);
  useEffect(() => {
    return () => {
      upNext.tile = {};
    };
  }, []);

  const [line1, setLine1] = useState('');
  useEffect(() => {
    if (line1$) {
      const subscription = line1$.subscribe(text => {
        setLine1(text);
      });

      return () => subscription.unsubscribe();
    }
  }, [line1$]);

  const [line2, setLine2] = useState('');
  useEffect(() => {
    if (line2$) {
      const subscription = line2$.subscribe(text => {
        setLine2(text);
      });

      return () => subscription.unsubscribe();
    }
  }, [line2$]);

  const [upNextChannelLogo, setUpNextChannelLogo] = useState('');
  useEffect(() => {
    const channelLogo =
      channelInfo &&
      channelInfo.imageList &&
      channelInfo.imageList.find(
        imageObject => imageObject.name === NAME_COLOR_CHANNEL_LOGO,
      );

    setUpNextChannelLogo(channelLogo && channelLogo.url);
  }, [channelInfo]);

  useEffect(() => {
    if (showUpNext) {
      focusManager.changeFocus(UP_NEXT.STARTING_IN_NAV.id);
    }
  }, [showUpNext]);

  const neriticLinkService = ServiceFactory.getInstance(
    NeriticLinkService,
  ) as NeriticLinkService;
  const goToNextMedia = () => {
    if (upNext.tile) {
      neriticLinkService.takePrimaryTileAction(upNext.tile as any, {
        openNowPlaying: false,
      });

      if (history.location.pathname.includes(appRouteConstants.NOW_PLAYING)) {
        focusManager.changeFocus(nowPlayingNavId);
      }
    } else {
      history.goBack();
    }

    setShowUpNext(false);
    setCountdown(upNextDelay);
  };

  const tuneClientService = ServiceFactory.getInstance(
    TuneClientService,
  ) as TuneClientService;
  const goToChannel = () => {
    clearTimeout(startingInTimeout.id);
    setCountdown(upNextDelay);

    if (upNextTile) {
      const payload: TunePayload = {
        channel: channelInfo,
        channelId: upNextTile.primaryNeriticLink.channelId,
        contentType: ContentTypes.LIVE_AUDIO,
        showGuid: upNextTile.primaryNeriticLink.showGuid || '',
        isPandoraPodcast:
          (upNextTile && upNextTile.tileAssetInfo.isPandoraPodcast) ||
          upNextTile.primaryNeriticLink.contentType === ContentTypes.PODCAST,
        cutAssetGuid:
          upNextTile && upNextTile.cutAssetGuid ? upNextTile.cutAssetGuid : '',
      };

      tuneClientService.tune(payload).subscribe((responseCode: number) => {
        if (responseCode === ClientCodes.SUCCESS) {
          focusManager.changeFocus(nowPlayingNavId);
          setShowUpNext(false);
        } else {
          setCountdown(countdown - 1);
        }
      });
    } else {
      history.goBack();
      setShowUpNext(false);
    }
  };

  useEffect(() => {
    //TODO: The countdown timer initial value needs to be taken from the app settings
    //TODO: This screen should not appear at all if autoplay is disabled in the app settings
    if (showUpNext) {
      if (countdown > 0) {
        startingInTimeout.id = setTimeout(
          () => setCountdown(countdown - 1),

          startingInTimeout.DELAY,
        );
      } else {
        goToNextMedia();
      }
    }

    return () => clearTimeout(startingInTimeout.id);
  }, [countdown, showUpNext]);

  /******* AUTOPLAY ON DEMAND LOGIC - START *******/

  const channel = useSelector(getChannel);
  const show = useSelector(getShow);
  const episode = useSelector(getEpisode);
  const cut = useSelector(getCut);
  const contentType = useSelector(getContentType);
  const isAod = useSelector(getIsAod);
  const isVideo = useSelector(getIsVideo);
  const history = useHistory();
  const mediaPlayerService = ServiceFactory.getInstance(
    MediaPlayerService,
  ) as MediaPlayerService;

  useEffect(() => {
    if (mediaPlayerService && mediaPlayerService.mediaPlayer) {
      let subscription;

      if (isAod || isVideo) {
        subscription = mediaPlayerService.mediaPlayer.playbackComplete$.subscribe(
          isPlaybackComplete => {
            if (isPlaybackComplete) {
              if (
                history.location.pathname.includes(
                  appRouteConstants.NOW_PLAYING,
                )
              ) {
                setShowUpNext(true);
              } else {
                goToNextMedia();
              }
            }
          },
        );
      } else if (!isAod && !isVideo) {
        subscription && subscription.unsubscribe();
      }

      return () => subscription && subscription.unsubscribe();
    }
  }, [mediaPlayerService && mediaPlayerService.mediaPlayer, isAod, isVideo]);

  const carouselStoreService = ServiceFactory.getInstance(
    CarouselStoreService,
  ) as CarouselStoreService;
  useEffect(() => {
    if (
      episode &&
      episode.assetGUID &&
      channel &&
      channel.channelGuid &&
      show &&
      show.assetGUID &&
      cut &&
      cut.assetGUID
    ) {
      carouselStoreService.selectNowPlayingUpNext(
        channel.channelGuid,
        show.assetGUID,
        episode.assetGUID,
        cut.assetGUID,
        contentType,
      );
    }
  }, [
    channel && channel.channelGuid,
    show && show.assetGUID,
    cut && cut.assetGUID,
    episode && episode.assetGUID,
  ]);

  /******* AUTOPLAY ON DEMAND LOGIC - END *******/

  return (
    showUpNext && (
      <div
        className="up-next-container"
        style={{ backgroundImage: `url(${bgImageUrl})` }}
      >
        <div
          className="up-next-logo"
          style={{ backgroundImage: `url(${upNextChannelLogo})` }}
        />
        <div className="up-next-data-container">
          <div className="up-next-title">Up Next:</div>
          <div className="up-next-media-title">{line2}</div>
          <div className="up-next-metadata-container">
            <div
              className="up-next-media-icon"
              style={{ backgroundImage: `url(${iconImageUrl})` }}
            ></div>
            <span className="up-next-media-emission-day">{line1}</span>
          </div>
        </div>
        <div className="up-next-action-buttons">
          <UpNextActionButton
            nav={UP_NEXT.TUNE_TO_CHANNEL_NAV}
            onClick={goToChannel}
          >
            Tune to Ch. {channelInfo && channelInfo.channelNumber}
          </UpNextActionButton>
          <UpNextActionButton
            nav={UP_NEXT.STARTING_IN_NAV}
            onClick={goToNextMedia}
            className={'starting-in-button'}
          >
            <span className="up-next-play-icon">
              <PlayIcon />
            </span>{' '}
            <span>Starting in {countdown}</span>
          </UpNextActionButton>
        </div>
      </div>
    )
  );
};

export default UpNext;
