import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withFocus } from '@accedo/vdkweb-navigation';
import roundButtonTheme from './RoundButton.module.scss';

const RoundButton = ({
  onClick,
  icon = null,
  theme = roundButtonTheme,
  className,
  id,
}) => {
  const buttonClassName = theme.button;
  return (
    <div className={className}>
      <button onClick={onClick} className={buttonClassName} id={id}>
        <div>{icon && <FontAwesomeIcon icon={icon} />}</div>
      </button>
    </div>
  );
};

const FocusRoundButton = withFocus(RoundButton);

export { RoundButton, FocusRoundButton };
