import React from 'react';
import classNames from 'classnames';

import { withFocus } from '@accedo/vdkweb-navigation';

import styles from './focusDiv.module.scss';

type Props = {
  domRef?: any;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode | string;
  dataName?: string;
};

const FocusDiv = ({
  nav: { id },
  className,
  style = {},
  domRef,
  children,
  dataName,
}: Props & WithFocusProps) => (
  <div
    ref={domRef}
    tabIndex={-1}
    id={id}
    className={classNames(className, styles.focusDiv)}
    style={style}
    data-name={dataName}
  >
    {children}
  </div>
);

export default withFocus(FocusDiv);
