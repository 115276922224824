import React from 'react';
import classNames from 'classnames';
import { withFocus } from '@accedo/vdkweb-navigation';
import styles from './button.module.scss';
import { focusManager } from '@accedo/vdkweb-navigation';

type Props = {
  onClick?: () => void;
  children?: React.ReactNode | string;
  className?: string;
  classNameFocused?: string;
  classNameActive?: string;
  isActive?: boolean;
};

const Button = ({
  nav: { id },
  onClick,
  isFocused,
  children,
  className,
  classNameFocused,
  classNameActive,
  isActive,
}: Props & WithFocusProps) => {
  const timer = React.useRef<any>(null);
  const handleMouseOver = () => {
    timer.current = setTimeout(() => focusManager.changeFocus(id), 200);
  };
  const handleMouseOut = () => {
    clearTimeout(timer.current);
  };
  return (
    <button
      id={id}
      onClick={onClick}
      className={classNames(styles.default, className, {
        [classNameFocused]: isFocused,
        [classNameActive]: isActive,
      })}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {children}
    </button>
  );
};

export default withFocus(Button);
